import { useState, ReactNode, useEffect } from 'react';
import { SpecialtyType, Specialty, Institution, initialSpecialtyValue, Professional, initialProfessionalValue, Country } from '../../store/types';
import { useStore } from 'react-redux';
import { Table, Space, Tooltip, Button, Typography, message, Card, Popconfirm, Tag, Popover } from 'antd';
import { specialties as specialtiesReducer } from '../../store/reducers';
import { specialtiesSelectors, institutionsSelectors, specialtyTypesSelectors, countriesSelectors } from '../../store/selectors';
import { ColumnProps } from 'antd/lib/table';
import { DeleteOutlined, EditOutlined, InfoCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import { FormProfessionalSpecialtyEdit } from '../../components';
import { createUpdateSpecialty, deleteSpecialty, fetchProfessional } from '../../store/api';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { colors } from '../../assets/styles';
import dayjs from 'dayjs';
import { isAuthorized } from '../../utils/util-authentication';

const key = 'saving';
const { Text } = Typography;
/**
 * Form to display a table of specialties
 */
const FormProfessionalSpecialties = ({ professionalId, professionalType, disabled = true }) => {
	const store = useStore();
	const styles = baseStyles;
	const { t } = useTranslation();
	const [originalMainSpecialtId, setOriginalMainSpecialtyId] = useState<number>(0);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [professional, setProfessional] = useState<Professional>(initialProfessionalValue);
	const [specialtyTypes, setSpecialtyTypes] = useState<SpecialtyType[]>([]);
	const [institutions, setInstitutions] = useState<Institution[]>([]);
	const [countries, setCountries] = useState<Country[]>([]);
	const [specialties, setSpecialties] = useState<Specialty[]>([]);
	const [specialty, setSpecialty] = useState<Specialty>({ ...initialSpecialtyValue, professionalId: professionalId });
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [modalTitle, setModalTitle] = useState<string>('');

	useEffect(() => {
		setInstitutions(
			institutionsSelectors
				.selectAll(store.getState() as any)
				.filter(i => !i.isDeleted)
				.sort((a, b) => a.name.localeCompare(b.name)),
		);
		setSpecialtyTypes(
			specialtyTypesSelectors
				.selectAll(store.getState() as any)
				.filter(s => !s.isDeleted)
				.sort((a, b) => a.description.localeCompare(b.description)),
		);
		setCountries(
			countriesSelectors
				.selectAll(store.getState() as any)
				.filter(s => !s.isDeleted)
				.sort((a, b) => a.description.localeCompare(b.description)),
		);
		fetchProfessional(professionalId).then(item => {
			setProfessional(item);
		});
		var specialtyItems = specialtiesSelectors.selectAll(store.getState() as any).filter(s => s.professionalId === professionalId && !s.isDeleted);
		if (specialtyItems.length > 0) setOriginalMainSpecialtyId(specialtyItems.find(s => s.isActive)?.id);
		setSelectedRowKeys(specialtyItems.filter(s => s.isActive).map(s => s.id));
		setSpecialties(specialtyItems);
	}, [store, professionalId]);

	const handleEdit = (item: Specialty) => {
		setSpecialty(item);
		setModalTitle(t('professionals.editSpecialty'));
		setIsModalVisible(true);
	};

	const handleAdd = () => {
		setSpecialty({ ...initialSpecialtyValue, professionalId: professionalId });
		setModalTitle(t('professionals.addSpecialty'));
		setIsModalVisible(true);
	};

	const handleDelete = async (item: Specialty) => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		var result = await deleteSpecialty(item);
		if (result) {
			var temp = { ...item, isDeleted: true };
			store.dispatch(specialtiesReducer.actions.specialtyCreateUpdate(temp));
			setSpecialties(specialtiesSelectors.selectAll(store.getState() as any).filter(s => s.professionalId === professionalId && !s.isDeleted));
			message.success({ content: t('messages.itemDeleted'), key, duration: 2 });
		} else message.info({ content: t('message.unableToDelete'), key, duration: 2 });
	};

	const handleSelectChange = async (newSelectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		try {
			var originalMainSpecialty = specialties.find(s => s.id === originalMainSpecialtId);
			originalMainSpecialty = { ...originalMainSpecialty, isActive: false };
			var newMainSpecialty = specialties.find(s => s.id === newSelectedRowKeys[0]);
			newMainSpecialty = { ...newMainSpecialty, isActive: true };
			var resultNew = await createUpdateSpecialty(newMainSpecialty);
			if (originalMainSpecialty !== undefined && resultNew !== undefined) {
				store.dispatch(specialtiesReducer.actions.specialtyCreateUpdate(originalMainSpecialty));
				store.dispatch(specialtiesReducer.actions.specialtyCreateUpdate(resultNew));
				message.success({ content: t('messages.dataSaved'), key, duration: 1 });
			}
		} catch (errors) {
			message.info({ content: t('messages.errorOnSave'), key, duration: 1 });
		}
	};

	const handleClose = () => {
		setIsModalVisible(false);
		setSpecialties(specialtiesSelectors.selectAll(store.getState() as any).filter(s => s.professionalId === professionalId && !s.isDeleted));
	};

	const columns: ColumnProps<Specialty>[] = [
		{
			dataIndex: 'modifiedBy',
			key: 'modifiedBy',
			width: 20,
			render: (_text: string, item: Specialty): ReactNode => (
				<Popover
					content={
						<div>
							<Text>{t('general.modifiedBy')} </Text>
							<Text type="warning">{item.modifiedBy}</Text>
						</div>
					}
				>
					<InfoCircleTwoTone />
				</Popover>
			),
		},
		{
			title: t('professionals.specialty'),
			dataIndex: 'specialty',
			key: 'specialty',
			width: '40%',
			render: (_text: string, item: Specialty): ReactNode =>
				item.isActive ? (
					<Text strong>{specialtyTypes.find(i => i.id === item.specialtyTypeId)?.description}</Text>
				) : (
					<Text>{specialtyTypes.find(i => i.id === item.specialtyTypeId)?.description}</Text>
				),
		},
		{
			dataIndex: 'isSIS',
			key: 'isSIS',
			render: (_text: string, item: Specialty): ReactNode =>
				specialtyTypes.find(i => i.id === item.specialtyTypeId)?.isSIS ? (
					<Tag style={{ backgroundColor: colors.brandColor2 }}>SIS</Tag>
				) : (
					<Tag style={{ backgroundColor: colors.brandColor2 }}>No SIS</Tag>
				),
		},
		{
			title: t('professionals.institution'),
			dataIndex: 'institution',
			key: 'institution',
			width: '20%',
			render: (_text: string, item: Specialty): ReactNode => <Text>{institutions.find(i => i.id === item.institutionId)?.name}</Text>,
		},
		{
			title: t('general.country'),
			dataIndex: 'institution',
			key: 'institution',
			width: '20%',
			render: (_text: string, item: Specialty): ReactNode => (
				<Text>{countries.find(c => c.id === institutions.find(i => i.id === item.institutionId)?.countryId).description}</Text>
			),
		},
		{
			title: t('professionals.endDate'),
			dataIndex: 'endDate',
			key: 'endDate',
			width: '20%',
			render: (_text: string, item: Specialty): ReactNode => (
				<Text>{dayjs(item.endDate).diff(dayjs(professional.birthDate)) < 0 ? '' : dayjs(item.endDate).format('YYYY')}</Text>
			),
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: Specialty): ReactNode => {
				return disabled || !isAuthorized('Component_Specialties') ? null : (
					<Space size="small">
						<Tooltip title={t('general.edit')}>
							<Button
								style={styles.button}
								type="primary"
								size="small"
								shape="round"
								onClick={() => {
									handleEdit(item);
								}}
								icon={<EditOutlined />}
							/>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDelete(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button size="small" danger shape="round" icon={<DeleteOutlined />} />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];
	return (
		<Card
			size="small"
			title={t('professionals.underGraduateSpecialties')}
			style={styles.card}
			extra={
				disabled || !isAuthorized('Component_Specialties') ? null : (
					<Button type="primary" shape="round" icon={<PlusOutlined />} style={styles.buttonCard} onClick={handleAdd}></Button>
				)
			}
		>
			<Table<Specialty>
				rowSelection={{
					type: 'radio',
					selectedRowKeys: selectedRowKeys,
					onChange: handleSelectChange,
				}}
				key={`specialties-${Date.now()}`}
				rowKey={item => item.id}
				tableLayout="auto"
				size="small"
				dataSource={specialties}
				columns={columns}
				pagination={false}
			/>
			<FormProfessionalSpecialtyEdit
				title={modalTitle}
				open={isModalVisible}
				professionalTypeId={professionalType}
				professionalId={professionalId}
				specialtyId={specialty.id}
				onClose={handleClose}
			/>
			<div>
				<Text strong>
					{t('professionals.mainSpecialty') + ' : '}
					<Tag style={styles.tagBig}>
						{specialties.filter(s => selectedRowKeys.includes(s.id)).map(s => specialtyTypes.find(st => st.id === s.specialtyTypeId).description)}
					</Tag>
				</Text>
			</div>
		</Card>
	);
};
export default FormProfessionalSpecialties;
