import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { UserAuthenticationStatus, DataSourceStatus } from '../types';

const userInitialState: UserAuthenticationStatus = {
	isAuthenticated: false,
	isAllowed: true,
	restrictedPath: '/home',
	authenticationPath: '/login',
};

export const authentication = createSlice({
	name: 'authentication',
	initialState: userInitialState,
	reducers: {
		authenticate: (state, action: PayloadAction<{ accessToken: string }>) => {
			state.isAuthenticated = true;
			state.accessToken = action.payload.accessToken;
		},
		deAuthenticate: state => {
			state.isAuthenticated = false;
		},
	},
});

export const dataSourceStatusAdapter = createEntityAdapter({
	selectId: (item: any) => item.id,
});

export const dataSourceStatuses = createSlice({
	name: 'dataSourceStatuses',
	initialState: dataSourceStatusAdapter.getInitialState({
		loaded: 0,
		total: 0,
		lastDataSourceLoaded: '',
	}),
	reducers: {
		dataSourceAdded: (state, action: PayloadAction<DataSourceStatus>) => {
			dataSourceStatusAdapter.addOne(state, action.payload);
			state.total = state.total + 1;
		},
		dataSourceUpdate: (state, action: PayloadAction<DataSourceStatus>) => {
			dataSourceStatusAdapter.upsertOne(state, { id: action.payload.id, isLoaded: action.payload.isLoaded, lastUpdate: action.payload.lastUpdate });
			state.lastDataSourceLoaded = action.payload.id;
			state.loaded = state.loaded + 1;
		},
	},
});
