import { Breadcrumb, Col, Layout, Row, Tabs } from 'antd';
import {
	FormConfigurationDomainsGeneral,
	FormConfigurationDomainsProfessional,
	FormConfigurationInstitutions,
	FormConfigurationTranslations,
} from '../../components';
import { useTranslation } from 'react-i18next';
import { BankOutlined, ControlTwoTone, DeploymentUnitOutlined, TableOutlined, UserOutlined } from '@ant-design/icons';
import { colors } from '../../assets/styles';

const { Content } = Layout;

const PageConfigurationDomains = () => {
	const { t } = useTranslation();
	const tabs = [
		{
			icon: <TableOutlined />,
			label: t('configurations.general'),
			key: 0,
			children: <FormConfigurationDomainsGeneral />,
		},
		{
			icon: <UserOutlined />,
			label: t('configurations.professional'),
			key: 1,
			children: <FormConfigurationDomainsProfessional />,
		},
		{
			icon: <DeploymentUnitOutlined />,
			label: t('configurations.translations'),
			key: 2,
			children: <FormConfigurationTranslations />,
		},
		{
			icon: <BankOutlined />,
			label: t('configurations.institutions'),
			key: 3,
			children: <FormConfigurationInstitutions />,
		},
	];

	return (
		<div>
			<Col>
				<Row>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Breadcrumb
							separator=">"
							items={[{ title: <ControlTwoTone twoToneColor={colors.brandColor1} /> }, { title: t('general.configuration') }, { title: t('general.domains') }]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col></Col>
				</Row>
				<Content className="site-layout-background">
					<Tabs
						type="card"
						defaultActiveKey="0"
						style={{ width: '100%', padding: 10 }}
						items={tabs.map(item => {
							return {
								label: item.label,
								icon: item.icon,
								key: item.key.toString(),
								children: item.children,
							};
						})}
					></Tabs>
				</Content>
			</Col>
		</div>
	);
};

export default PageConfigurationDomains;
