import { ReactNode, useCallback, useEffect, useState } from 'react';
import { AutoComplete, Avatar, Button, Col, Form, Input, message, Popconfirm, Row, Select, Space, Table, Tooltip, Typography } from 'antd';
import { Country, initialInstitutionValue, Institution, ParentType } from '../../store/types';
import { DeleteOutlined, EditOutlined, FilterFilled, PlusOutlined, ReadOutlined, UndoOutlined } from '@ant-design/icons';
import { SizedModal, UploadDragPaste } from '..';
import { countriesSelectors } from '../../store/selectors';
import { useStore } from 'react-redux';
import { createUpdateInstitution, deleteInstitution, fetchInstitutions } from '../../store/api';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { includes } from '../../utils/util-custom-validators';
import debounce from 'lodash.debounce';

const { Text } = Typography;
const { Option } = Select;

const key = 'saving';

const FormConfigurationInstitutions = () => {
	const store = useStore();
	const styles = baseStyles;
	const [formInstitutions] = Form.useForm();
	const { t } = useTranslation();
	const [institutions, setInstitutions] = useState<Institution[]>([]);
	const [selectedInstitutions, setSelectedInstitutions] = useState<Institution[]>([]);
	const [institution, setInstitution] = useState<Institution>(initialInstitutionValue);
	const [modalTitle, setModalTitle] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [isModalVisible, changeModalVisibility] = useState(false);
	const [url, setUrl] = useState<string>();
	const [countries, setCountries] = useState<Country[]>([]);

	useEffect(() => {
		setLoading(true);
		setCountries(countriesSelectors.selectAll(store.getState() as any));
		fetchInstitutions().then(items => {
			setInstitutions(items);
			setSelectedInstitutions(items);
			setLoading(false);
		});
	}, [store]);

	const handleSave = async () => {
		try {
			const values = await formInstitutions.validateFields();
			if (
				(institution.id === 0 && institutions.filter(i => i.code === values['code']).length > 0) ||
				(institution.id !== 0 && institutions.filter(i => i.code === values['code']).length > 1)
			) {
				message.error({ content: t('messages.duplicateCode'), key, duration: 2 });
				return;
			}
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			setLoading(true);
			changeModalVisibility(false);
			var temp: Institution = {
				...institution,
				name: values['name'],
				code: values['code'],
				description: values['description'],
				countryId: values['country'],
			};
			var response = await createUpdateInstitution(temp);
			if (response !== undefined) {
				var result = await fetchInstitutions();
				setInstitutions(result);
				setSelectedInstitutions(result);
				message.success({ content: t('messages.dataSaved'), key, duration: 2 });
			} else message.error({ content: t('messages.errorOnTransaction'), key, duration: 2 });
			setLoading(false);
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		}
	};

	const handleCancel = () => {
		changeModalVisibility(false);
	};

	const handleRefresh = async () => {
		setLoading(true);
		setInstitutions([]);
		var result = await fetchInstitutions();
		setInstitutions(result);
		setLoading(false);
	};

	const handleAdd = () => {
		setModalTitle(t('configurations.addInstitution'));
		formInstitutions.setFieldsValue({
			name: initialInstitutionValue.name,
			code: initialInstitutionValue.code,
			description: initialInstitutionValue.description,
			country: countries.find(c => c.code === globalThis.country).id,
		});
		setInstitution(initialInstitutionValue);
		changeModalVisibility(true);
	};

	const handleEdit = (item: Institution) => {
		setModalTitle(t('configurations.editInstitution'));
		setUrl(item.avatar);
		formInstitutions.setFieldsValue({
			name: item.name,
			code: item.code,
			description: item.description,
			country: item.countryId || countries.find(c => c.code === globalThis.country).id,
		});
		setInstitution(item);
		changeModalVisibility(true);
	};

	const handleDelete = async (item: Institution) => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		var success = await deleteInstitution(item);
		if (success) {
			var result = await fetchInstitutions();
			setInstitutions(result);
			setSelectedInstitutions(result);
			message.success({ content: t('messages.itemDeleted'), key, duration: 2 });
		} else message.error({ content: t('messages.errorOnTransaction'), key, duration: 2 });
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounceSearch = useCallback(
		debounce(value => {
			var items = institutions.filter(i => includes(i.description, value) || includes(i.name, value));
			setSelectedInstitutions(items);
		}, 500),
		[institutions],
	);

	const handleSearch = (value: string) => {
		if (!value) {
			setSelectedInstitutions(institutions);
		}
		debounceSearch(value);
	};

	const columns = [
		{
			width: 35,
			render: (_text: string, item: Institution) => {
				if (item.avatar !== '') return <Avatar size="small" src={`${item.avatar}`} />;
				else return <Avatar size="large" icon={<ReadOutlined />} />;
			},
		},
		{
			width: 100,
			title: t('configurations.code'),
			dataIndex: 'code',
			key: 'code',
		},
		{
			width: 100,
			title: t('configurations.country'),
			dataIndex: 'country',
			key: 'country',
			filterIcon: <FilterFilled style={styles.filter}></FilterFilled>,
			filters: countries.map(t => {
				return {
					text: t.description,
					value: t.id,
				};
			}),
			onFilter: (value, item) => item.countryId === value,
			render: (_text: string, item: any): ReactNode => {
				return <Text>{countries.find(c => c.id === item.countryId)?.description} </Text>;
			},
		},
		{
			title: t('configurations.name'),
			dataIndex: 'name',
			key: 'name',
			width: '30%',
		},
		{
			title: t('configurations.description'),
			dataIndex: 'description',
			key: 'description',
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: any): ReactNode => {
				return (
					<Space size="middle">
						<Button
							type="primary"
							icon={<EditOutlined />}
							shape="round"
							onClick={() => {
								handleEdit(item);
							}}
						/>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDelete(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<div>
			<Row>
				<Col key="col1" flex={3}>
					<Space direction="horizontal">
						<Text strong>{t('general.searchEngine')}</Text>
						<AutoComplete
							popupMatchSelectWidth={baseStyles.popupMatchSelectWidth}
							style={styles.search}
							placeholder={t('general.search')}
							allowClear
							onSearch={handleSearch}
						/>
					</Space>
				</Col>
				<Col key="col2" flex={4}></Col>
				<Col key="col3" flex={3}>
					<Row justify="end">
						<Space size="middle">
							<Button type="primary" shape="round" icon={<PlusOutlined />} onClick={() => handleAdd()}>
								{t('general.add')}
							</Button>
							<Button type="primary" shape="round" icon={<UndoOutlined />} onClick={() => handleRefresh()}>
								{t('general.refresh')}
							</Button>
						</Space>
					</Row>
				</Col>
			</Row>
			<Row style={{ paddingTop: 10 }}>
				<Col flex={1}>
					<Table<Institution>
						key={`institutions-table`}
						rowKey={item => item.id}
						tableLayout="auto"
						size="small"
						dataSource={selectedInstitutions}
						pagination={{ position: ['bottomRight'], showTotal: total => `${t('general.total')} ${total} ${t('general.items')}` }}
						columns={columns}
						loading={loading}
						style={{ width: '96vw' }}
					/>
				</Col>
			</Row>
			<SizedModal
				title={modalTitle}
				open={isModalVisible}
				okText={t('general.save')}
				cancelText={t('general.cancel')}
				onOk={handleSave}
				onCancel={handleCancel}
				size="small"
			>
				<Form {...baseStyles.formLayout} form={formInstitutions} name="institution">
					<Form.Item label={t('configurations.code')} name="code" rules={[{ required: true, message: t('messages.enterValue') }]}>
						<Input />
					</Form.Item>
					<Form.Item label={t('configurations.name')} name="name" rules={[{ required: true, message: t('messages.enterValue') }]}>
						<Input />
					</Form.Item>
					<Form.Item label={t('configurations.description')} name="description" rules={[{ required: true, message: t('messages.enterValue') }]}>
						<Input />
					</Form.Item>
					<Form.Item label={t('configurations.country')} name="country" rules={[{ required: true, message: t('messages.enterValue') }]}>
						<Select
							showSearch
							allowClear
							optionFilterProp="children"
							filterOption={(input, option) => includes((option!.children as unknown as string).toLowerCase(), input.toLowerCase())}
						>
							{countries.map(item => (
								<Option key={`country-${item.id}`} value={item.id}>
									{item.description}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item label="Avatar">
						<UploadDragPaste size="medium" id={institution.id} parentType={ParentType.institution} url={url} setUrl={setUrl} />
					</Form.Item>
				</Form>
			</SizedModal>
		</div>
	);
};

export default FormConfigurationInstitutions;
