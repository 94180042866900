import { ReactNode, useEffect, useState } from 'react';
import { Table, Typography } from 'antd';
import { useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Person } from '../../store/types';
import { personsSelectors } from '../../store/selectors/management';
import { ColumnProps } from 'antd/lib/table';
import { Gender } from '../../store/types/domains';
import { gendersSelectors } from '../../store/selectors';

const { Text } = Typography;

const FormPersonIndex = ({ setPersonId }) => {
	const store = useStore();
	const state = store.getState() as any;
	const { t } = useTranslation();
	const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);
	const [genders, setGender] = useState<Gender[]>([]);
	const [persons, setPersons] = useState<Person[]>([]);
	const [isloading, setIsLoading] = useState(true);

	window.onresize = () => {
		setInnerHeight(window.innerHeight);
	};

	useEffect(() => {
		setGender(gendersSelectors.selectAll(state));
		setPersons(personsSelectors.selectAll(state).filter(p => !p.isDeleted));
		setIsLoading(false);
	}, [state]);

	const rowSelection = {
		onChange: (selectedRowKeys: React.Key[]) => {
			setPersonId(selectedRowKeys);
		},
	};

	const columns: ColumnProps<Person>[] = [
		{
			title: t('general.name'),
			dataIndex: 'patientName',
			key: 'patientName',
			render: (_text: string, item: Person): ReactNode => {
				return <Text>{`${item.lastName} ${item.secondLastName}, ${item.names}`}</Text>;
			},
		},
		{
			title: t('general.socialId'),
			dataIndex: 'socialId',
			key: 'socialId',
			render: (_text: string, item: Person): ReactNode => <Text>{item.socialId}</Text>,
		},
		{
			title: t('general.gender'),
			dataIndex: 'gender',
			key: 'gender',
			render: (_text: string, item: Person): ReactNode => <Text>{genders.find(g => g.id === item.genderId)?.description}</Text>,
		},
	];

	return (
		<div>
			<Table<Person>
				rowSelection={{
					type: 'radio',
					...rowSelection,
				}}
				rowKey={item => item.id}
				loading={isloading}
				key={`activity-table`}
				tableLayout="auto"
				size="small"
				dataSource={persons}
				columns={columns}
				pagination={false}
				scroll={{
					y: innerHeight * 0.75,
				}}
			/>
		</div>
	);
};

export default FormPersonIndex;
