import { Key, ReactNode, useEffect, useState } from 'react';
import { AutoComplete, Button, Col, Form, Input, message, Popconfirm, Row, Space, Table, Tooltip, Typography } from 'antd';
import { SizedModal } from '../../components';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { initialProfileValue, Permission, Profile } from '../../store/types';
import { permissionSelectors, profileSelectors } from '../../store/selectors';
import { profiles as profileReducer } from '../../store/reducers';
import { useStore } from 'react-redux';
import { TableRowSelection } from 'antd/lib/table/interface';
import { createUpdateProfile } from '../../store/api';
import { useTranslation } from 'react-i18next';
import { baseStyles, layout } from '../../assets/styles';
import { includes } from '../../utils/util-custom-validators';

const { Text } = Typography;
const key = 'saving';

const FormConfigurationProfiles = () => {
	const [formConfigurationProfiles] = Form.useForm();
	const store = useStore();
	const state = store.getState() as any;
	const styles = baseStyles;
	const { t } = useTranslation();
	var initialProfileVal = initialProfileValue;
	const [modalTitle, setModalTitle] = useState<string>('');
	const [isModalVisible, changeModalVisibility] = useState(false);
	const [profiles, setProfiles] = useState<Profile[]>([]);
	const [permissions, setPermissions] = useState<Permission[]>([]);
	const [profile, setProfile] = useState<Profile>(initialProfileVal);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		setProfiles(profileSelectors.selectAll(state).filter(p => !p.isDeleted));
		setPermissions(permissionSelectors.selectAll(state));
	}, [state, store, t]);

	const handleOk = async () => {
		changeModalVisibility(false);
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		var result = await createUpdateProfile(profile);
		if (result !== undefined) {
			store.dispatch(profileReducer.actions.profileCreateUpdate(result));
			setProfiles(profileSelectors.selectAll(state).filter(p => !p.isDeleted));
			message.success({ content: t('messages.dataSaved'), key, duration: 2 });
		} else message.info('Error al editar el dato');
	};

	const handleCancel = () => {
		changeModalVisibility(false);
	};

	const handleEditProfile = (item: Profile) => {
		if (item === undefined) setProfile(initialProfileVal);
		else setProfile(item);
		setModalTitle('Editar Perfil');
		formConfigurationProfiles.setFieldsValue({
			code: item.code,
			description: item.description,
		});
		changeModalVisibility(true);
	};

	const handleDeleteProfile = (item: Profile) => {};

	const handleSearch = (value: string) => {
		if (value) {
			var searchResult = profileSelectors.selectAll(state).filter(p => includes(p.description, value) && !p.isDeleted);
			var searchVisualization = searchResult.map(p => {
				return {
					value: p.id,
					label: <Text strong>{p.description}</Text>,
				};
			});
			setOptions(searchVisualization);
		} else setOptions([]);
	};

	const handleSelect = (_value, option) => {
		var item = profiles.find(p => p.id === option.value);
		handleEditProfile(item);
	};

	const rowSelection: TableRowSelection<Permission> = {
		onChange: (_selectedRowKeys: Key[], selectedRows: Permission[]) => {
			setProfile({ ...profile, permissionIds: selectedRows.map(k => k.id) });
		},
		selectedRowKeys: profile?.permissionIds,
	};

	const permissionColumns = [
		{
			title: t('configurations.code'),
			dataIndex: 'code',
			key: 'code',
		},
		{
			title: t('general.description'),
			dataIndex: 'description',
			key: 'description',
		},
	];

	const profileColumns = [
		{
			title: t('configurations.code'),
			dataIndex: 'code',
			key: 'code',
		},
		{
			title: t('configurations.description'),
			dataIndex: 'description',
			key: 'description',
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: Profile): ReactNode => {
				return (
					<Space size="middle">
						<Button type="primary" icon={<EditOutlined />} shape="round" onClick={() => handleEditProfile(item)} />
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeleteProfile(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<div>
			<Row>
				<Col flex={3}>
					<Space direction="horizontal">
						<Text strong>{t('general.searchEngine')}</Text>
						<AutoComplete
							popupMatchSelectWidth={baseStyles.popupMatchSelectWidth}
							style={styles.search}
							placeholder={t('general.search')}
							allowClear
							options={options}
							onSelect={handleSelect}
							onSearch={handleSearch}
						/>
					</Space>
				</Col>
				<Col key="col2" flex={4} />
				<Col key="col3" flex={3}>
					<Row justify="end">
						<Space size="middle">
							<Button type="primary" shape="round" icon={<PlusOutlined />} onClick={() => handleEditProfile(initialProfileVal)}>
								{t('general.add')}
							</Button>
						</Space>
					</Row>
				</Col>
			</Row>
			<Row style={{ paddingTop: 10 }}>
				<Col flex={1}>
					<Table<Profile>
						rowKey={(item: { id: any }) => item.id}
						tableLayout="auto"
						size="small"
						dataSource={profiles}
						columns={profileColumns}
						pagination={false}
					/>
					<SizedModal
						title={modalTitle}
						open={isModalVisible}
						okText={t('general.save')}
						cancelText={t('general.cancel')}
						onOk={handleOk}
						onCancel={handleCancel}
						size="medium"
					>
						<Form {...layout} form={formConfigurationProfiles} name="news">
							<Form.Item
								label={t('configurations.code')}
								name="code"
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input disabled />
							</Form.Item>
							<Form.Item
								label={t('configurations.description')}
								name="description"
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input disabled />
							</Form.Item>
						</Form>
						<Table<Permission>
							rowKey={item => item.id}
							rowSelection={{ ...rowSelection }}
							key={`permissions-table-${Date.now()}`}
							tableLayout="auto"
							size="small"
							dataSource={permissions}
							pagination={{ position: ['bottomRight'], showTotal: total => `${t('general.total')} ${total} ${t('general.items')}` }}
							columns={permissionColumns}
						/>
					</SizedModal>
				</Col>
			</Row>
		</div>
	);
};
export default FormConfigurationProfiles;
