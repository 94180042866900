import { News, DocumentAttachment, ParentType, ImageAttachment } from '../types';
import { urls } from './urls';
import { parseDocumentAttachment } from './attachments';
import dayjs from 'dayjs';

/**
 * Fect news
 */
export const fetchNews = async (): Promise<{ news: News[]; newsAttachments: DocumentAttachment[]; imageAttachments: ImageAttachment[] }> => {
	var result = {
		news: [] as News[],
		newsAttachments: [] as DocumentAttachment[],
		imageAttachments: [] as ImageAttachment[],
	};
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.news, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var ni = parseNews(jsonObject[i]);
		result.news.push(ni);
		if (jsonObject[i].attachments !== undefined) {
			for (var j = 0; j < jsonObject[i].attachments.length; j++) {
				var a: DocumentAttachment = parseDocumentAttachment(jsonObject[i].attachments[j], ni.id, ParentType.newsItem);
				result.newsAttachments.push(a);
			}
		}
	}
	return result;
};

/**
 * Parse a news item
 */
export const parseNews = (data: any): News => {
	var ni: News = {
		id: data.id,
		professionalTypeId: data.professionalType === null ? 0 : data.professionalType.id,
		title: data.title,
		avatar: data.avatar,
		author: data.author,
		newsItemDate: dayjs.utc(data.newsItemDate).tz('America/Santiago').toDate().toISOString(),
		publishDate: dayjs.utc(data.publishDate).tz('America/Santiago').toDate().toISOString(),
		summary: data.summary,
		details: data.details,
		url: data.url,
		isPublished: data.isPublished,
		tags: data.tags.map((t: { description: string }) => t.description),
		isDeleted: data.isDeleted,
	};
	return ni;
};

/**
 * Post a new News Item
 */
export const createUpdateNewsItem = async (newsItem: News): Promise<News | undefined> => {
	try {
		var bearer = 'Bearer ' + globalThis.token;
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${bearer}`,
				Username: `${globalThis.user?.username}`,
			},
			body: JSON.stringify(newsItem),
		};
		var response = await fetch(urls.createUpdateNews, requestOptions);
		if (response.status === 200) {
			var data = await response.json();
			var result: News = parseNews(data);
			return result;
		}
	} catch (e) {
		return undefined;
	}
	return undefined;
};

export const deleteNewsItem = async (newsItem: News): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	const response = await fetch(urls.deleteNews + `?&id=${newsItem.id}`, requestOptions);
	if (response.status === 200) {
		return true;
	}
	return false;
};
