import { useEffect, useState } from 'react';
import { Select, DatePicker, Form, Input, Alert, message, Space } from 'antd';
import { useStore } from 'react-redux';
import { Category, CategoryType, initialCategoryValue } from '../../store/types';
import { categoryTypeSelector } from '../../store/selectors/domains';
import { dateFormat } from '../../assets/formats';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { categories } from '../../store/reducers';
import { categorySelectors } from '../../store/selectors/professional';
import { SizedModal } from '..';
import { createUpdateCategory } from '../../store/api';
import locale from 'antd/es/date-picker/locale/es_ES';
import dayjs from 'dayjs';

const { TextArea } = Input;
const { Option } = Select;

const key = 'saving';

/**
 * Form to display a form for an email
 */
const FormProfessionalCategoryEdit = (props: {
	categoryId: number;
	professionalId: number;
	professionalType: number;
	open: boolean;
	title: string;
	onClose: () => void;
}) => {
	const store = useStore();
	const state = store.getState() as any;
	const [formProfessionalCategoryEdit] = Form.useForm();
	const { t } = useTranslation();
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [isValidData, setIsValidData] = useState<boolean>(true);
	const [categoryTypes, setCategoryTypes] = useState<CategoryType[]>([]);
	const [category, setCategory] = useState<Category>(initialCategoryValue);
	const [validateCategoryType, setValidateCategoryType] = useState<'success' | 'error'>('success');
	const [duplicateCategory, setDuplicateCategory] = useState<boolean>(false);

	useEffect(() => {
		var categoryTypeItems = categoryTypeSelector.selectAll(state).filter(s => !s.isDeleted);
		setCategoryTypes(categoryTypeItems);
		var item = categorySelectors.selectById(state, props.categoryId) || { ...initialCategoryValue, professionalId: props.professionalId };
		setCategory(item);
		setIsVisible(props.open);
		formProfessionalCategoryEdit.setFieldsValue({
			categoryType: item.categoryTypeId || '',
			startDate: dayjs(item.startDate || new Date()),
			endDate: dayjs(item.endDate || new Date()),
			comments: item.comments,
		});
	}, [formProfessionalCategoryEdit, props, state]);

	const handleSave = async () => {
		if (!isValidData) return;
		try {
			const values = await formProfessionalCategoryEdit.validateFields();
			var temp: Category = {
				...category,
				startDate: values['startDate'].toDate(),
				endDate: values['endDate'].toDate(),
				comments: values['comments'],
				categoryTypeId: values['categoryType'] || 0,
			};
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			var result = await createUpdateCategory(temp, props.professionalId);
			if (result !== undefined) {
				store.dispatch(categories.actions.categoryCreateUpdate(result));
				message.success({ content: t('messages.dataSaved'), key, duration: 2 });
			} else message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
			props.onClose();
		} catch (errors) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		}
		props.onClose();
	};

	const handleCancel = () => {
		props.onClose();
	};

	const handleValuesChange = (changedValues: any) => {
		if (changedValues['type']) {
			var tCategoryType = categoryTypes.find(p => p.id === changedValues['type']);
			if (
				categorySelectors.selectAll(state).find(p => p.professionalId === props.professionalId && p.categoryTypeId === tCategoryType?.id) !== undefined &&
				category.id !== 0
			) {
				setValidateCategoryType('error');
				setIsValidData(false);
				setDuplicateCategory(true);
			} else {
				setValidateCategoryType('success');
				setIsValidData(true);
				setDuplicateCategory(false);
			}
		}
	};

	return (
		<SizedModal size="small" title={props.title} open={isVisible} onOk={handleSave} onCancel={handleCancel}>
			<Form {...baseStyles.formLayout} form={formProfessionalCategoryEdit} onValuesChange={handleValuesChange} name="category-details">
				<Form.Item label={t('general.type')} validateStatus={validateCategoryType} name="categoryType">
					<Select>
						{categoryTypes.map(p => {
							return (
								<Option key={`categoryType-${p.id}`} value={p.id}>
									{p.description}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item label={t('professionals.startDate')} name="startDate" rules={[{ required: true, message: t('messages.enterEndDate') }]}>
					<DatePicker locale={locale} format={dateFormat} />
				</Form.Item>
				<Form.Item label={t('professionals.endDate')} name="endDate" rules={[{ required: true, message: t('messages.enterEndDate') }]}>
					<DatePicker locale={locale} format={dateFormat} />
				</Form.Item>
				<Form.Item label={t('professionals.comments')} name="comments" rules={[{ required: false }]}>
					<TextArea />
				</Form.Item>
				<Space direction="vertical">
					{duplicateCategory ? <Alert message={t('messages.categoryAlreadyExists')} type="error" /> : null}
					{!isValidData ? <Alert message={t('messages.correctErrorsToContinue')} type="error" /> : null}
				</Space>
			</Form>
		</SizedModal>
	);
};
export default FormProfessionalCategoryEdit;
