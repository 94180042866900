import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { Select, Input, Form } from 'antd';
import { initialInfrastructureValue, Infrastructure, InfrastructureType } from '../../store/types';
import { infrastructuresSelectors, infrastructureTypeSelectors } from '../../store/selectors';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const FormInfrastructureEdit = (props: {
	infrastructureId: number;
	setIsValidData: (status: boolean) => void;
	setInfrastructureData: (data: Infrastructure) => void;
}) => {
	const id = props.infrastructureId;
	const store = useStore();
	const state = store.getState() as any;
	const [formInfrastructureEdit] = Form.useForm();
	const { t } = useTranslation();
	const [infrastructureTypes, setInfrastructureTypes] = useState<InfrastructureType[]>([]);

	useEffect(() => {
		var item = infrastructuresSelectors.selectById(state, id) || initialInfrastructureValue;
		formInfrastructureEdit.setFieldsValue({
			description: item.description,
			internalCode: item.internalCode,
			externalCode: item.externalCode,
			infrastructureType: item.infrastructureType,
		});
		setInfrastructureTypes(infrastructureTypeSelectors.selectAll(state).filter(c => !c.isDeleted));
	}, [formInfrastructureEdit, id, props.infrastructureId, state]);

	return (
		<Form {...baseStyles.formLayout} form={formInfrastructureEdit} name="infrastructure-details">
			<Form.Item label={t('general.description')} name="description" rules={[{ required: true, message: 'Debe ingresar un tipo' }]}>
				<Input />
			</Form.Item>
			<Form.Item label="Código Interno" name="internalCode" rules={[{ required: false }]}>
				<Input />
			</Form.Item>
			<Form.Item label="Código Externo" name="externalCode" rules={[{ required: false }]}>
				<Input />
			</Form.Item>
			<Form.Item label={t('general.type')} name="infrastructureType" rules={[{ required: true, message: 'Debe ingresar un tipo' }]}>
				<Select>
					{infrastructureTypes.map(it => {
						return (
							<Option key={`infrastructureType-${it.id}`} value={it.id}>
								{it.description}
							</Option>
						);
					})}
				</Select>
			</Form.Item>
		</Form>
	);
};
export default FormInfrastructureEdit;
