import { useEffect, useState } from 'react';
import { RcFile } from 'antd/lib/upload';
import { ClearOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Row, Space, Spin, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { createUpdateAvatar } from '../../store/api/attachments';
import Dragger, { DraggerProps } from 'antd/lib/upload/Dragger';

const UploadDragPaste = (props: {
	size?: 'small' | 'medium' | 'large' | undefined;
	id: number;
	parentType: number;
	url?: string;
	setUrl: (url: string) => void;
}) => {
	var width: number | string = '100%';
	switch (props.size) {
		case 'large':
			width = 300;
			break;
		case 'medium':
			width = 200;
			break;
		case 'small':
			width = 100;
			break;
	}
	const { t } = useTranslation();
	const [file, setFile] = useState<File | null>(null);
	const [filename, setFilename] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);

	document.addEventListener('paste', function (e) {
		// Get the data of clipboard
		const clipboardItems = e.clipboardData?.items;
		const items = [].slice.call(clipboardItems).filter((item: DataTransferItem) => {
			// Filter the image items only
			return item.type.indexOf('image') !== -1;
		});
		if (items.length === 0) {
			return;
		}
		const item: DataTransferItem = items[0];
		const blob: File | null = item.getAsFile();
		setFile(blob);
		const imageEle: HTMLImageElement | null = document.getElementById('preview') as HTMLImageElement;
		setFilename(URL.createObjectURL(blob));
		if (imageEle !== null) imageEle.src = URL.createObjectURL(blob);
	});

	useEffect(() => {
		setFilename(props.url || '');
	}, [props]);

	const handleClear = () => {
		setFilename('');
		setFile(null);
		props.setUrl('');
	};

	const draggerProps: DraggerProps = {
		name: 'file',
		multiple: false,
		showUploadList: false,
		beforeUpload: (file: RcFile) => {
			setFile(file);
			setFilename(URL.createObjectURL(file));
			return false;
		},
	};

	const handleSave = async () => {
		setLoading(true);
		var response = await createUpdateAvatar(props.id, props.parentType, file);
		if (response !== undefined) {
			props.setUrl(response);
		}
		setLoading(false);
	};

	return (
		<Row style={{ width: width, display: props.id === 0 ? 'none' : '' }} aria-disabled={props.id === 0 ? true : false}>
			<Spin spinning={loading}>
				<Dragger {...draggerProps}>
					<div>
						{filename !== '' ? (
							<Image id="preview" src={filename} alt={filename} />
						) : (
							<div>
								<p className="ant-upload-drag-icon">
									<PlusOutlined />
								</p>
								<p className="ant-upload-text">{t('messages.clickDragFile')}</p>
							</div>
						)}
					</div>
				</Dragger>
			</Spin>
			<Row style={{ display: 'flex', flexDirection: 'row', padding: 10, justifyContent: 'flex-end' }}>
				<Space>
					<Button type="primary" icon={<ClearOutlined />} onClick={handleClear} shape="round">
						{t('general.clear')}
					</Button>
					<Button type="primary" icon={<UploadOutlined />} onClick={handleSave} shape="round">
						{t('general.upload')}
					</Button>
				</Space>
			</Row>
		</Row>
	);
};

export default UploadDragPaste;
