import {
	fetchAllCountries as fetchCountries,
	fetchRegions,
	fetchGenders,
	fetchPhoneNumberTypes,
	fetchEmailTypes,
	fetchAddressTypes,
	fetchAccreditationStatuses,
	fetchAccreditationDocumentTypes,
	fetchRelationTypes,
	fetchProfessionalTypes,
	fetchSpecialtyTypes,
	fetchCategoryTypes,
	fetchEventTypes,
	fetchTags,
	fetchCenterType,
	fetchEvents,
	fetchLinkItems,
	fetchNews,
	fetchCenters,
	fetchServices,
	fetchDocuments,
	fetchInfrastructureType,
	fetchEquipmentType,
	fetchEquipmentStatus,
	fetchInfrastructure,
	fetchEquipment,
	fetchPrivilegeTypes,
	fetchPrivilegeStatuses,
	fetchInstitutions,
	fetchProfessionalDocumentTypes,
	fetchPermissions,
	fetchProfiles,
	fetchNetworks,
	fetchUsers,
	fetchActivities,
	fetchTasks,
	fetchAccreditations,
	fetchSpecialties,
	fetchActiveProfessionals,
	fetchAddresses,
} from './api';
import {
	dataSourceStatuses,
	documents,
	phones,
	emails,
	addresses,
	accreditations,
	accreditationDocuments,
	professionals,
	countries,
	regions,
	provinces,
	communes,
	genders,
	phoneNumberTypes,
	emailTypes,
	addressTypes,
	accreditationStatuses,
	accreditationDocumentTypes,
	relationTypes,
	professionalTypes,
	specialties,
	categories,
	eventTypes,
	events,
	tags,
	documentAttachments,
	linkItems,
	news,
	centers,
	services,
	centerTypes,
	imageAttachments,
	authentication,
	users,
	equipments,
	infrastructures,
	infrastructureTypes,
	equipmentStatuses,
	privilegeTypes,
	privilegeStatuses,
	specialtyTypes,
	equipmentTypes,
	accomplishments,
	privileges,
	institutions,
	professionalDocuments,
	professionalDocumentTypes,
	permissions,
	profiles,
	networks,
	categoryTypes,
	accreditationDocumentStatuses,
	activities,
	agreements,
	comments,
	tasks,
	cases,
	persons,
	accreditationComments,
	professionalMilestones,
} from './reducers';
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { DocumentAttachment, ImageAttachment, Address, Email, Phone } from './types';
import {
	fetchAccreditationDocumentStatuses,
	fetchActivityTypes,
	fetchCaseTypes,
	fetchPriorities,
	fetchProfessionalMilestoneTypes,
	fetchTaskStatus,
	fetchTaskTypes,
} from './api/domains';
import { taskStatuses, taskTypes, activityTypes, priorities, caseTypes, professionalMilestoneTypes } from './reducers/domains';
import { fetchCases, fetchPersons } from './api/management';

export const mainStore = configureStore({
	reducer: {
		countries: countries.reducer,
		regions: regions.reducer,
		provinces: provinces.reducer,
		communes: communes.reducer,
		genders: genders.reducer,
		emailTypes: emailTypes.reducer,
		phoneNumberTypes: phoneNumberTypes.reducer,
		addressTypes: addressTypes.reducer,
		accreditationStatuses: accreditationStatuses.reducer,
		accreditationDocumentTypes: accreditationDocumentTypes.reducer,
		privilegeStatuses: privilegeStatuses.reducer,
		privilegeTypes: privilegeTypes.reducer,
		relationTypes: relationTypes.reducer,
		professionalTypes: professionalTypes.reducer,
		professionalDocumentTypes: professionalDocumentTypes.reducer,
		professionalMilestoneTypes: professionalMilestoneTypes.reducer,
		accreditationDocumentStatuses: accreditationDocumentStatuses.reducer,
		specialtyTypes: specialtyTypes.reducer,
		specialties: specialties.reducer,
		categoryTypes: categoryTypes.reducer,
		caseTypes: caseTypes.reducer,
		eventTypes: eventTypes.reducer,
		centerTypes: centerTypes.reducer,
		infrastructureTypes: infrastructureTypes.reducer,
		equipmentStatuses: equipmentStatuses.reducer,
		equipmentTypes: privilegeTypes.reducer,
		documentAttachments: documentAttachments.reducer,
		documents: documents.reducer,
		news: news.reducer,
		phones: phones.reducer,
		emails: emails.reducer,
		events: events.reducer,
		linkItems: linkItems.reducer,
		imageAttachments: imageAttachments.reducer,
		addresses: addresses.reducer,
		accomplishments: accomplishments.reducer,
		privileges: privileges.reducer,
		accreditations: accreditations.reducer,
		professionalMilestones: professionalMilestones.reducer,
		accreditationComments: accreditationComments.reducer,
		accreditationDocuments: accreditationDocuments.reducer,
		professionalDocuments: professionalDocuments.reducer,
		categories: categories.reducer,
		tags: tags.reducer,
		institutions: institutions.reducer,
		professionals: professionals.reducer,
		centers: centers.reducer,
		services: services.reducer,
		infrastructures: infrastructures.reducer,
		equipments: equipments.reducer,
		dataSourceStatuses: dataSourceStatuses.reducer,
		authentication: authentication.reducer,
		users: users.reducer,
		permissions: permissions.reducer,
		profiles: profiles.reducer,
		networks: networks.reducer,
		taskStatuses: taskStatuses.reducer,
		taskTypes: taskTypes.reducer,
		activityTypes: activityTypes.reducer,
		priorities: priorities.reducer,
		activities: activities.reducer,
		tasks: tasks.reducer,
		cases: cases.reducer,
		persons: persons.reducer,
		comments: comments.reducer,
		agreements: agreements.reducer,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false,
		}),
});

export type StoreStateType = ReturnType<typeof mainStore.getState>;
export type StoreDispatchType = typeof mainStore.dispatch;
export const useAppDispatch = () => useDispatch<StoreDispatchType>();

// Fetch Domains
export const fetchDomains = async () => {
	var store = mainStore;
	//#region Datasource loading
	store.dispatch(countries.actions.countriesLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: countries.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(regions.actions.regionsLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: regions.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(provinces.actions.provincesLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: provinces.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(communes.actions.communesLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: communes.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(genders.actions.genderLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: genders.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(emailTypes.actions.emailTypesLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: emailTypes.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(phoneNumberTypes.actions.phoneNumberTypeLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: phoneNumberTypes.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(addressTypes.actions.addressTypeLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: addressTypes.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(accreditationStatuses.actions.accreditationStatusLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: accreditationStatuses.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(accreditationDocumentTypes.actions.accreditationDocumentTypeLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: accreditationDocumentTypes.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(professionalDocumentTypes.actions.professionalDocumentTypeLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: professionalDocumentTypes.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(relationTypes.actions.relationTypesLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: relationTypes.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(professionalTypes.actions.professionalTypeLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: professionalTypes.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(accreditationDocumentStatuses.actions.accreditationDocumentStatusLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: accreditationDocumentStatuses.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(specialtyTypes.actions.specialtyTypeLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: specialtyTypes.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(categoryTypes.actions.categoryTypeLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: categoryTypes.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(eventTypes.actions.eventTypeLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: eventTypes.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(centerTypes.actions.centerTypeLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: centerTypes.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(infrastructureTypes.actions.infrastructureTypeLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: infrastructureTypes.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(equipmentStatuses.actions.equipmentStatusLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: equipmentStatuses.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(privilegeTypes.actions.privilegeTypeLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: privilegeTypes.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(privilegeStatuses.actions.privilegeStatusLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: privilegeStatuses.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(taskStatuses.actions.taskStatusesLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: taskStatuses.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);

	store.dispatch(taskTypes.actions.taskTypesLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: taskTypes.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);

	store.dispatch(caseTypes.actions.caseTypesLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: caseTypes.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);

	store.dispatch(activityTypes.actions.activityTypesLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: activityTypes.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);

	store.dispatch(priorities.actions.prioritiesLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: priorities.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);

	store.dispatch(professionalMilestoneTypes.actions.professionaMilestoneTypeLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: professionalMilestones.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);

	// Fetching data
	var response: any;
	response = await fetchCountries();
	store.dispatch(countries.actions.countriesReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: countries.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Regions, provinces, communes
	response = await fetchRegions();
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: regions.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(regions.actions.regionsReceived(response.regions));
	store.dispatch(provinces.actions.provincesReceived(response.provinces));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: provinces.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(communes.actions.communesReceived(response.communes));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: communes.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Gender
	response = await fetchGenders();
	store.dispatch(genders.actions.genderReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: genders.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Email Types
	response = await fetchEmailTypes();
	store.dispatch(emailTypes.actions.emailTypeReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: emailTypes.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Phone number types
	response = await fetchPhoneNumberTypes();
	store.dispatch(phoneNumberTypes.actions.phoneNumberTypeReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: phoneNumberTypes.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Address Types
	response = await fetchAddressTypes();
	store.dispatch(addressTypes.actions.addressTypeReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: addressTypes.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Accreditation Statuses
	response = await fetchAccreditationStatuses();
	store.dispatch(accreditationStatuses.actions.accreditationStatusReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: accreditationStatuses.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Accreditation Document Types
	response = await fetchAccreditationDocumentTypes();
	store.dispatch(accreditationDocumentTypes.actions.accreditationDocumentTypeReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: accreditationDocumentTypes.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Accreditation Document Statuses
	response = await fetchAccreditationDocumentStatuses();
	store.dispatch(accreditationDocumentStatuses.actions.accreditationDocumentStatusReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: accreditationDocumentStatuses.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Professional Document Types
	response = await fetchProfessionalDocumentTypes();
	store.dispatch(professionalDocumentTypes.actions.professionalDocumentTypeReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: professionalDocumentTypes.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Professional Milestones Types
	response = await fetchProfessionalMilestoneTypes();
	store.dispatch(professionalMilestoneTypes.actions.professionaMilestoneTypeReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: professionalMilestoneTypes.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Relation Types
	response = await fetchRelationTypes();
	store.dispatch(relationTypes.actions.relationTypesReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: relationTypes.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Professional Types
	response = await fetchProfessionalTypes();
	store.dispatch(professionalTypes.actions.professionalTypeReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: professionalTypes.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Specialty Types
	response = await fetchSpecialtyTypes();
	store.dispatch(specialtyTypes.actions.specialtyTypeReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: specialtyTypes.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Categories
	response = await fetchCategoryTypes();
	store.dispatch(categoryTypes.actions.categoryTypeReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: categoryTypes.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Event Types
	response = await fetchEventTypes();
	store.dispatch(eventTypes.actions.eventTypeReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: eventTypes.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Center Types
	response = await fetchCenterType();
	store.dispatch(centerTypes.actions.centerTypeReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: centerTypes.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Infrastructure Types
	response = await fetchInfrastructureType();
	store.dispatch(infrastructureTypes.actions.infrastructureTypeReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: infrastructureTypes.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Infrastructure Statuses
	response = await fetchEquipmentStatus();
	store.dispatch(equipmentStatuses.actions.equipmentStatusReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: equipmentStatuses.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Equipment Types
	response = await fetchEquipmentType();
	store.dispatch(equipmentTypes.actions.equipmentTypeReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: equipmentTypes.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Privilege Types
	response = await fetchPrivilegeTypes();
	store.dispatch(privilegeTypes.actions.privilegeTypeReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: privilegeTypes.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Privilege Statuses
	response = await fetchPrivilegeStatuses();
	store.dispatch(privilegeStatuses.actions.privilegeStatusReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: privilegeStatuses.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Activity Types
	response = await fetchActivityTypes();
	store.dispatch(activityTypes.actions.activityTypesReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: activityTypes.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Activity Types
	response = await fetchCaseTypes();
	store.dispatch(caseTypes.actions.caseTypesReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: caseTypes.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Task Types
	response = await fetchTaskTypes();
	store.dispatch(taskTypes.actions.taskTypesReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: taskTypes.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Task Statuses
	response = await fetchTaskStatus();
	store.dispatch(taskStatuses.actions.taskStatusesReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: taskStatuses.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Priorities
	response = await fetchPriorities();
	store.dispatch(priorities.actions.prioritiesReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: priorities.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);
	//#endregion
};

// Fetch Data
export const fetchData = async () => {
	var store = mainStore;

	store.dispatch(addresses.actions.addressesLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: addresses.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);

	store.dispatch(documentAttachments.actions.attachmentLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: documentAttachments.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(imageAttachments.actions.imageAttachmentLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: imageAttachments.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);

	store.dispatch(tags.actions.tagLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: tags.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(institutions.actions.institutionLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: institutions.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(documents.actions.documentLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: documents.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(news.actions.newsItemLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: news.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(events.actions.eventsLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: events.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(linkItems.actions.linkItemLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: linkItems.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(specialties.actions.specialtyLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: specialties.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(professionals.actions.professionalsLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: professionals.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(accreditations.actions.accreditationsLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: accreditations.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(networks.actions.networkLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: networks.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);

	store.dispatch(centers.actions.centerLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: centers.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(services.actions.serviceLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: services.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(infrastructures.actions.infrastructureLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: infrastructures.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(equipments.actions.equipmentLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: equipments.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(profiles.actions.profileLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: profiles.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(permissions.actions.permissionLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: permissions.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(activities.actions.activitiesLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: activities.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(cases.actions.casesLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: cases.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(persons.actions.personsLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: persons.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(comments.actions.commmentsLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: comments.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(agreements.actions.agreementsLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: agreements.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(users.actions.userLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: users.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(tasks.actions.tasksLoading());
	store.dispatch(
		dataSourceStatuses.actions.dataSourceAdded({
			id: tasks.name,
			isLoaded: false,
			lastUpdate: new Date().toString(),
		}),
	);
	//#endregion
	var tempDocuments: DocumentAttachment[] = [] as DocumentAttachment[];
	var tempEmails: Email[] = [] as Email[];
	var tempPhones: Phone[] = [] as Phone[];
	var tempImages: ImageAttachment[] = [] as ImageAttachment[];
	var tempAddresses: Address[] = [] as Address[];
	var tempComments: Comment[] = [] as Comment[];

	// Tags
	var response: any = await fetchAddresses();
	store.dispatch(addresses.actions.addressesReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: addresses.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	response = await fetchTags();
	store.dispatch(tags.actions.tagReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: tags.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Institutions
	response = await fetchInstitutions();
	store.dispatch(institutions.actions.institutionReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: institutions.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Documents
	response = await fetchDocuments();
	tempDocuments = [...tempDocuments, ...response.documentAttachments];
	tempImages = [...tempImages, ...response.imageAttachments];
	store.dispatch(documents.actions.documentReceived(response.documents));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: documents.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// News
	response = await fetchNews();
	tempDocuments = [...tempDocuments, ...response.newsAttachments];
	tempImages = [...tempImages, ...response.imageAttachments];
	store.dispatch(news.actions.newsItemReceived(response.news));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: news.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Training
	response = await fetchEvents();
	tempDocuments = [...tempDocuments, ...response.eventAttachments];
	store.dispatch(events.actions.eventsReceived(response.events));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: events.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	response = await fetchLinkItems();
	tempImages = [...tempImages, ...response.imageAttachments];
	store.dispatch(linkItems.actions.linkItemReceived(response.linkItems));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: linkItems.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Professionals
	response = await fetchActiveProfessionals();
	store.dispatch(professionals.actions.professionalsReceived(response.professionals));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: professionals.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);
	response = await fetchAccreditations();
	store.dispatch(accreditations.actions.accreditationsReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: accreditations.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);
	response = await fetchSpecialties();
	store.dispatch(specialties.actions.specialtyReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: specialties.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);
	// Network data
	response = await fetchNetworks();
	store.dispatch(networks.actions.networkReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: networks.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	response = await fetchCenters();
	tempAddresses = [...tempAddresses, ...response.addresses];
	store.dispatch(centers.actions.centerReceived(response.centers));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: centers.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	response = await fetchServices();
	store.dispatch(services.actions.serviceReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: services.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	response = await fetchInfrastructure();
	store.dispatch(infrastructures.actions.infrastructureReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: infrastructures.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	response = await fetchEquipment();
	store.dispatch(equipments.actions.equipmentReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: equipments.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	// Added Profiles and Permissions
	response = await fetchPermissions();
	store.dispatch(permissions.actions.permissionReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: permissions.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	response = await fetchProfiles();
	store.dispatch(profiles.actions.profileReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: profiles.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	response = await fetchUsers();
	store.dispatch(users.actions.userReceived(response));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: users.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	response = await fetchActivities();
	tempComments = [...tempComments, ...response.comments];
	store.dispatch(activities.actions.activitiesReceived(response.activities));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: activities.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(agreements.actions.agreementsReceived(response.agreements));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: agreements.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	response = await fetchCases();
	tempComments = [...tempComments, ...response.comments];
	store.dispatch(cases.actions.casesReceived(response.cases));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: cases.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	response = await fetchTasks();
	tempDocuments = [...tempDocuments, ...response.attachments];
	tempComments = [...tempComments, ...response.comments];

	store.dispatch(tasks.actions.tasksReceived(response.tasks));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: tasks.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	response = await fetchPersons();
	tempEmails = [...tempEmails, ...response.emails];
	tempPhones = [...tempPhones, ...response.phones];
	store.dispatch(persons.actions.personsReceived(response.persons));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: persons.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);
	// Added attachments
	store.dispatch(documentAttachments.actions.attachmentReceived(tempDocuments));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: documentAttachments.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(imageAttachments.actions.imageAttachmentReceived(tempImages));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: imageAttachments.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);

	store.dispatch(comments.actions.commmentsReceived(tempComments));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: comments.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(emails.actions.emailsReceived(tempEmails));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: emails.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(phones.actions.phonesReceived(tempPhones));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: phones.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);
	store.dispatch(addresses.actions.addressesReceived(tempAddresses));
	store.dispatch(
		dataSourceStatuses.actions.dataSourceUpdate({
			id: addresses.name,
			isLoaded: true,
			lastUpdate: new Date().toString(),
		}),
	);
};
