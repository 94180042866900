import { initialLinkItemValue, LinkItem, ParentType } from '../../store/types';
import { FormAttachments, FormAddTag, AuthRoutes, FormLinkItem } from '../../components';
import { Col, Tabs, Layout, Breadcrumb, Row, Button, Popconfirm, Space, Tooltip, message } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, BookTwoTone, PaperClipOutlined, ProfileOutlined, SaveOutlined, TagsOutlined } from '@ant-design/icons';
import { colors } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { linkItemsSelectors } from '../../store/selectors';
import { createUpdateLinkItem } from '../../store/api';
import { linkItems } from '../../store/reducers';

const { Content } = Layout;

const key = 'saving';

const PageLinkItemDetails = () => {
	const store = useStore();
	const navigate = useNavigate();
	const location = useLocation();
	const params = location.state as any;
	const id: number = parseInt(params.id);
	const { t } = useTranslation();
	const [linkItem, setLinkItem] = useState<LinkItem>(initialLinkItemValue);
	const [changed, setChanged] = useState<boolean>(false);
	const [hasErrors, setHasErrors] = useState<boolean>(false);

	useEffect(() => {
		setLinkItem(linkItemsSelectors.selectById(store.getState() as any, id) || initialLinkItemValue);
	}, [store, id]);

	const tabs = [
		{
			icon: <ProfileOutlined />,
			label: t('general.details'),
			key: 0,
			children: <FormLinkItem linkId={linkItem.id} setLinkData={setLinkItem} setHasErrors={setHasErrors} setChanged={setChanged}></FormLinkItem>,
		},
		{
			icon: <TagsOutlined />,
			label: t('training.tags'),
			key: 1,
			disabled: id === 0 ? true : false,
			children: <FormAddTag parentId={linkItem.id} parentType={ParentType.linkItem} setParentData={setLinkItem} setChanged={setChanged}></FormAddTag>,
		},
		{
			icon: <PaperClipOutlined />,
			label: t('training.attachments'),
			key: 2,
			disabled: id === 0 ? true : false,
			children: <FormAttachments parentId={linkItem.id} parentType={ParentType.linkItem} />,
		},
	];

	const handleLinkSave = async () => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		var response = await createUpdateLinkItem(linkItem);
		if (response !== undefined) {
			store.dispatch(linkItems.actions.linkItemCreateUpdate(response));
			setLinkItem(response);
			if (id === 0) {
				navigate(location.pathname, {
					state: {
						id: response.id,
					},
				});
			}
			message.success({ content: t('messages.dataSaved'), key, duration: 2 });
			setChanged(false);
		} else message.info({ content: t('messages.errorOnSave'), key, duration: 2 });
	};

	return (
		<div>
			<Col>
				<Row>
					<Col>
						<Breadcrumb
							separator=">"
							style={{ height: 31, margin: '16px 24px 0 0' }}
							items={[
								{ title: <BookTwoTone twoToneColor={colors.brandColor1} /> },
								{ title: t('general.content') },
								{ title: <Link to={{ pathname: AuthRoutes.links + '/index' }}>{t('general.links')}</Link> },
								{ title: linkItem?.title },
							]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Space>
							{changed ? (
								<Popconfirm
									title={t('messages.confirmExit')}
									onConfirm={() => {
										navigate(AuthRoutes.links + '/index');
									}}
									okText={t('general.ok')}
									cancelText={t('general.cancel')}
								>
									<Tooltip title={t('general.back')}>
										<Button icon={<ArrowLeftOutlined />} type="primary" shape="round">
											{t('general.back')}
										</Button>
									</Tooltip>
								</Popconfirm>
							) : (
								<Tooltip title={t('general.back')}>
									<Button icon={<ArrowLeftOutlined />} type="primary" shape="round" onClick={() => navigate(AuthRoutes.links + '/index')}>
										{t('general.back')}
									</Button>
								</Tooltip>
							)}
							<Button type="primary" disabled={!changed || hasErrors} shape="round" icon={<SaveOutlined />} onClick={handleLinkSave}>
								{t('general.save')}
							</Button>
						</Space>
					</Col>
				</Row>
				<Content className="site-layout-background">
					<Tabs
						type="card"
						defaultActiveKey="0"
						style={{ width: '100%', padding: 10 }}
						items={tabs.map(item => {
							return {
								label: item.label,
								icon: item.icon,
								key: item.key.toString(),
								disabled: item.disabled,
								children: item.children,
							};
						})}
					></Tabs>
				</Content>
			</Col>
		</div>
	);
};
export default PageLinkItemDetails;
