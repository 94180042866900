import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { EventItem, LinkItem } from '../types/training';

//#region Events
export const eventsAdapter = createEntityAdapter({
	selectId: (item: EventItem) => item.id,
});

export const events = createSlice({
	name: 'events',
	initialState: eventsAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		eventAdded: eventsAdapter.addOne,
		eventsLoading: state => {
			if (!state.loading) state.loading = true;
		},
		eventsReceived: (state, action) => {
			if (state.loading) {
				eventsAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		eventCreateUpdate: eventsAdapter.upsertOne,
	},
});
//#endregion

//#region Link Items
export const linkItemAdapter = createEntityAdapter({
	selectId: (item: LinkItem) => item.id,
});

export const linkItems = createSlice({
	name: 'links',
	initialState: linkItemAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		linkItemAdded: linkItemAdapter.addOne,
		linkItemLoading: state => {
			if (!state.loading) state.loading = true;
		},
		linkItemReceived: (state, action) => {
			if (state.loading) {
				linkItemAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		linkItemCreateUpdate: linkItemAdapter.upsertOne,
	},
});
//#endregion
