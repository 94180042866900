import { ReactNode, useEffect, useState } from 'react';
import { Table, Form, Button, Space, message, Card, Tooltip, Switch, Alert, Input } from 'antd';
import { SimpleDomain, initialSimpleDomainValue } from '../../store/types';
import { baseUrl, createUpdateSimpleDomainValue } from '../../store/api';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { SizedModal } from '..';

const key = 'saving';

const FormDomainEditDescription = ({ domain, urlFetchData, urlUpdate }) => {
	const styles = baseStyles;
	const [formDomainEditDescription] = Form.useForm();
	const { t } = useTranslation();
	const [validateCode, setValidateCode] = useState<'success' | 'error'>('success');
	const [duplicateCode, setDuplicateCode] = useState<boolean>(false);
	const [invalidCode, setInvalidCode] = useState<boolean>(true);
	const [invalidDescription, setInvalidDescription] = useState<boolean>(true);
	const [domainItem, setDomainItem] = useState<SimpleDomain>(initialSimpleDomainValue);
	const [domainItems, setDomainItems] = useState<SimpleDomain[]>([]);
	const [modalTitle, setModalTitle] = useState<string>('');
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const requestOptions = {
			method: 'GET',
			headers: {
				Authorization: 'Bearer ' + globalThis.token,
			},
		};
		fetch(`${baseUrl}${urlFetchData}`, requestOptions).then(response => {
			response.json().then(items => {
				items.sort((a, b) => a.description.localeCompare(b.description));
				setDomainItems(items);
				setLoading(false);
			});
		});
	}, [urlFetchData]);

	const handleEdit = (item: SimpleDomain) => {
		setModalTitle(t('configurations.editDomainItem'));
		setDomainItem(item);
		formDomainEditDescription.setFieldsValue({
			id: item.id,
			code: item.code,
			description: item.description,
			isDeleted: item.isDeleted,
		});
		setIsModalVisible(true);
	};

	const handleAdd = () => {
		setModalTitle(t('configurations.addDomainItem'));
		setIsModalVisible(true);
		setDomainItem(initialSimpleDomainValue);
		formDomainEditDescription.setFieldsValue({
			code: '',
			description: '',
			isDeleted: false,
		});
	};

	const handleSave = async () => {
		try {
			const row = await formDomainEditDescription.validateFields();
			message.loading({ content: t('messages.saving'), key, duration: null });
			var item: SimpleDomain = {
				id: domainItem.id,
				code: row['code'],
				description: row['description'],
				isDeleted: row['isDeleted'],
			};
			var response = await createUpdateSimpleDomainValue(item, domain, urlUpdate);
			if (response !== undefined) {
				const requestOptions = {
					method: 'GET',
					headers: {
						Authorization: 'Bearer ' + globalThis.token,
					},
				};
				message.success({ content: t('messages.dataSaved'), key, duration: 2 });
				fetch(`${baseUrl}${urlFetchData}`, requestOptions).then(domainResponse => {
					domainResponse.json().then(data => {
						var items: SimpleDomain[] = [] as SimpleDomain[];
						for (var i = 0; i < data.length; i++) {
							var spd: SimpleDomain = {
								id: data[i].id,
								code: data[i].code,
								description: data[i].description,
								isDeleted: data[i].isDeleted,
							};
							items.push(spd);
						}
						items.sort((a, b) => a.description.localeCompare(b.description));
						setDomainItems(items);
					});
				});
				setLoading(false);
			} else message.error({ content: t('messages.dataNotSaved'), key, duration: 2 });
		} catch (errInfo) {
			message.error({ content: t('messages.errorOnTransaction'), key, duration: 2 });
		}
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleValuesChange = (changedValues: any) => {
		setInvalidCode(changedValues['code']?.length < 2 ? true : false);
		setInvalidDescription(changedValues['description']?.length < 3 ? true : false);
		if (changedValues['code'] !== undefined) {
			var code = changedValues['code'];
			var item = domainItems.find(i => i.code === code);
			if (item) {
				setValidateCode('error');
				setDuplicateCode(true);
			} else {
				setDomainItem({ ...domainItem, code: changedValues['code'] });
				setValidateCode('success');
				setDuplicateCode(false);
			}
		}
	};

	const columns = [
		{
			title: t('general.code'),
			dataIndex: 'code',
			key: 'code',
			editable: true,
			width: 100,
			sorter: {
				compare: (a: SimpleDomain, b: SimpleDomain) => {
					if (a.code < b.code) return -1;
					if (a.code > b.code) return 1;
					return 0;
				},
				multiple: 2,
			},
		},
		{
			title: t('general.description'),
			dataIndex: 'description',
			key: 'description',
			editable: true,
			sorter: {
				compare: (a: SimpleDomain, b: SimpleDomain) => {
					if (a.description < b.description) return -1;
					if (a.description > b.description) return 1;
					return 0;
				},
				multiple: 2,
			},
		},
		{
			title: t('general.isDeleted'),
			dataIndex: 'isDeleted',
			key: 'isDeleted',
			width: 100,
			render: (_text: string, item: SimpleDomain) => <Switch checked={item.isDeleted} disabled></Switch>,
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: SimpleDomain): ReactNode => {
				return (
					<Space size="middle">
						<Tooltip title={t('general.edit')}>
							<Button type="primary" shape="round" icon={<EditOutlined />} onClick={() => handleEdit(item)} />
						</Tooltip>
					</Space>
				);
			},
		},
	];

	return (
		<div>
			<Card
				size="small"
				title={t('general.details')}
				style={styles.card}
				extra={[<Button style={styles.buttonCard} type="primary" shape="round" icon={<PlusOutlined />} onClick={handleAdd}></Button>]}
			>
				<Table<SimpleDomain>
					loading={loading}
					rowKey={item => item.id}
					size="small"
					tableLayout="auto"
					dataSource={domainItems}
					columns={columns}
					pagination={{ position: ['bottomRight'], showTotal: total => `${t('general.total')} ${total} ${t('general.items')}` }}
					style={{ width: '96vw' }}
				/>
			</Card>
			<SizedModal
				title={modalTitle}
				open={isModalVisible}
				okText={t('general.save')}
				okButtonProps={{ disabled: duplicateCode || invalidCode || invalidDescription, shape: 'round' }}
				cancelText={t('general.cancel')}
				onCancel={handleCancel}
				onOk={handleSave}
				size="small"
			>
				<Form {...baseStyles.formLayout} form={formDomainEditDescription} name="simpleProfessionalDomain" onValuesChange={handleValuesChange}>
					<Form.Item label={t('configurations.code')} name="code" validateStatus={validateCode} rules={[{ required: true, message: t('messages.enterValue') }]}>
						<Input />
					</Form.Item>
					<Form.Item label={t('configurations.description')} name="description" rules={[{ required: true, message: t('messages.enterValue') }]}>
						<Input />
					</Form.Item>
					<Form.Item label={t('general.isDeleted')} name="isDeleted" valuePropName="checked">
						<Switch />
					</Form.Item>
					{duplicateCode ? <Alert message={t('messages.codeAlreadyExists')} type="error" /> : null}
				</Form>
			</SizedModal>
		</div>
	);
};

export default FormDomainEditDescription;
