import { DocumentAttachment, ImageAttachment } from '../types';
import { urls } from './urls';
import { ParentType } from '../../store/types/attachments';

export const parseImageAttachment = (data: any, parentId: number, parentType: ParentType): ImageAttachment => {
	var attachment: ImageAttachment = {
		id: data.id,
		url: data.url,
		isDeleted: data.isDeleted,
		parentId: parentId,
		parentType: parentType,
	};
	return attachment;
};

export const parseDocumentAttachment = (data: any, parentId: number, parentType: ParentType): DocumentAttachment => {
	var attachment: DocumentAttachment = {
		id: data.id,
		description: data.description,
		url: data.url,
		isDeleted: data.isDeleted,
		parentId: parentId,
		parentType: parentType,
	};
	return attachment;
};

export const createUpdateAvatar = async (id: number, parentType: number, file: File | null): Promise<string | undefined> => {
	const formData = new FormData();
	formData.append('Id', id.toString());
	formData.append('Type', parentType.toString());
	if (file !== null) formData.append('File', file);
	else return undefined;
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		body: formData,
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.createUpdateAvatar, requestOptions);
	if (response.status === 200) {
		var data = await response.text();
		return data;
	} else return undefined;
};

export const createUpdateDocumentAttachment = async (attachment: DocumentAttachment, file: File | undefined): Promise<DocumentAttachment | undefined> => {
	const formData = new FormData();
	formData.append('ParentId', attachment.parentId.toString());
	formData.append('Description', attachment.description);
	formData.append('Id', attachment.id.toString());
	formData.append('ParentType', attachment.parentType.valueOf().toString());
	formData.append('Url', attachment.url);
	if (file !== undefined) formData.append('File', file);
	else if (attachment.url === '' || attachment.url === undefined) return undefined;
	var temp: DocumentAttachment;
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		body: formData,
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.createUpdateDocumentAttachment, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		temp = parseDocumentAttachment(data, attachment.parentId, attachment.parentType);
		return temp;
	} else return undefined;
};

export const deleteDocumentAttachment = async (attachment: DocumentAttachment): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var url = urls.deleteDocumentAttachment + `?parentId=${attachment.parentId}&attachmentId=${attachment.id}&parentType=${attachment.parentType}`;
	const response = await fetch(url, requestOptions);
	if (response.status === 200) {
		return true;
	}
	return false;
};

export const createUpdateImageAttachment = async (imageAttachment: ImageAttachment, file: File | undefined): Promise<ImageAttachment | undefined> => {
	const formData = new FormData();
	formData.append('ParentId', imageAttachment.parentId.toString());
	formData.append('Id', imageAttachment.id.toString());
	formData.append('ParentType', imageAttachment.parentType.valueOf().toString());
	if (imageAttachment.subjectId !== undefined) formData.append('SubjectId', imageAttachment.subjectId.toString());
	if (file !== undefined) formData.append('File', file);
	else if (imageAttachment.url === '' || imageAttachment.url === undefined) return undefined;
	var temp: ImageAttachment;
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		body: formData,
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.createUpdateImageAttachment, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		temp = parseImageAttachment(data, imageAttachment.parentId, imageAttachment.parentType);
		return temp;
	} else return undefined;
};

export const deleteImageAttachment = async (attachment: ImageAttachment): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var url = urls.deleteImageAttachment + `?parentId=${attachment.parentId}&attachmentId=${attachment.id}&parentType=${attachment.parentType}`;
	const response = await fetch(url, requestOptions);
	if (response.status === 200) {
		return true;
	}
	return false;
};
