import { Button, Col, Layout, Steps, message, theme } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormRegistrationIdentification } from '../../components';

const { Content } = Layout;

const PageRegistrationProfessional = () => {
	const { t } = useTranslation();
	const { token } = theme.useToken();
	const [current, setCurrent] = useState(0);
	const steps = [
		{
			title: t('registration.identification'),
			content: <FormRegistrationIdentification />,
		},
		{
			title: t('registration.documentation'),
			content: 'Second-content',
		},
		{
			title: t('registration.accreditation'),
			content: 'Third-content',
		},
		{
			title: t('registration.enabling'),
			content: 'Last-content',
		},
	];
	const next = () => {
		setCurrent(current + 1);
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const contentStyle: React.CSSProperties = {
		lineHeight: '260px',
		textAlign: 'center',
		color: token.colorTextTertiary,
		backgroundColor: token.colorFillAlter,
		borderRadius: token.borderRadiusLG,
		border: `1px dashed ${token.colorBorder}`,
		marginTop: 16,
	};

	const items = steps.map(item => ({ key: item.title, title: item.title }));
	return (
		<Layout>
			<Content>
				<Content className="site-layout" style={{ paddingTop: '10px', paddingLeft: '50px', minHeight: '100vh' }}>
					<Col style={{ width: '98%' }}>
						<>
							<Steps current={current} items={items} />
							<div style={contentStyle}>{steps[current].content}</div>
							<div style={{ marginTop: 24 }}>
								{current > 0 && (
									<Button style={{ margin: '0 8px' }} onClick={() => prev()}>
										{t('general.previous')}
									</Button>
								)}
								{current < steps.length - 1 && (
									<Button type="primary" onClick={() => next()}>
										{t('general.next')}
									</Button>
								)}
								{current === steps.length - 1 && (
									<Button type="primary" onClick={() => message.success('Processing complete!')}>
										{t('general.done')}
									</Button>
								)}
							</div>
						</>
					</Col>
				</Content>
			</Content>
		</Layout>
	);
};

export default PageRegistrationProfessional;
