import { Avatar, Button, Col, Dropdown, Row, Space, Switch, Typography } from 'antd';
import { useState } from 'react';
import Icon, { LogoutOutlined, MoreOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NonAuthRoutes } from './auth-route';
import SizedModal from './modal-sized';
import IconSun from '../svg/icon-sun';
import IconMoon from '../svg/icon-moon';

const { Text } = Typography;

const UserMenu = () => {
	const { t } = useTranslation();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const navigate = useNavigate();

	const handleClick = () => {
		setIsModalOpen(true);
	};

	const items = [
		{
			key: '1',
			label: (
				<Col>
					<Row justify="center">
						<Avatar size={120} gap={10} src={globalThis.user.imageUrl}></Avatar>
					</Row>
					<Row justify="center">
						<Text strong>{`${globalThis.user.givenName} ${globalThis.user.familyName}`}</Text>
					</Row>
					<Row justify="center">
						<Text>{globalThis.user?.username}</Text>
					</Row>
				</Col>
			),
		},
		{
			key: '2',
			label: <Text onClick={handleClick}>{t('general.logout')}</Text>,
			icon: <LogoutOutlined onClick={handleClick} />,
		},
	];

	return (
		<div>
			<Space>
				<Text>{t('general.welcome') + ', ' + globalThis.user.name}</Text>
				<Dropdown menu={{ items }} trigger={['click']}>
					<Button key="more" type="primary" shape="round" icon={<MoreOutlined />}></Button>
				</Dropdown>
				<Switch unCheckedChildren={<Icon component={IconSun}></Icon>} checkedChildren={<Icon component={IconMoon}></Icon>}></Switch>
			</Space>
			<SizedModal
				size="xsmall"
				open={isModalOpen}
				title={t('general.logout')}
				okText={t('general.ok')}
				cancelText={t('general.cancel')}
				onOk={() => {
					navigate(NonAuthRoutes.login);
				}}
			>
				<Text>{t('messages.confirmExitMessage')}</Text>
			</SizedModal>
		</div>
	);
};

export default UserMenu;
