import dayjs from "dayjs";

export interface News {
    [key: string]: any;
    id: number;
    professionalTypeId: number;
    title: string;
    avatar: string;
    author: string;
    newsItemDate: string;
    publishDate: string;
    summary: string;
    details: string;
    url: string;
    isPublished: boolean;
    tags: string[];
    isDeleted: boolean;
}

export const initialNewsValue: News = {
    id: 0,
    professionalTypeId: 0,
    title: "",
    avatar: "",
    author: "",
    newsItemDate: dayjs().format('YYYY/MM/DD'),
    publishDate: dayjs().format('YYYY/MM/DD'),
    summary: "",
    details: "",
    url: "",
    isPublished: false,
    tags: [],
    isDeleted: false,
};
