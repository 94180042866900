import { Suspense, useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Store } from '@reduxjs/toolkit';
import { mainStore } from './store/mainStore';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider, AuthRoutes, NonAuthRoutes } from './components';
import { ConfigProvider, theme, App } from 'antd';
import { User } from './store/types';
import { colors } from './assets/styles';
import { useThemeDetector } from './utils/useThemeDetector';
import * as Pages from './pages';
import packageJson from '../package.json';
import i18next from 'i18next';
import dayjs from 'dayjs';
import en_GB from 'antd/es/locale/en_GB';
import en_US from 'antd/es/locale/en_US';
import es_ES from 'antd/es/locale/es_ES';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import './index.css';

declare global {
	var token: string;
	var country: string;
	var user: User;
	var version: string;
	var isAuthenticated: boolean;
	var networkId: number;
	var language: string;
	var isLoading: boolean;
	var systemName: string;
	var footer_height: number;
}

export const locales = {
	'en-US': en_US,
	'es-CL': es_ES,
	'en-GB': en_GB,
	es: es_ES,
	en: en_US,
};

const { PageHome, PageLogin, PageAppDownload, PageLoading, PageMain, PageViewDetails } = Pages;

const DireccionMedicoApp = () => {
	const store: Store = mainStore;
	const client_id = process.env.REACT_APP_GOOGLEWEBID || '';
	const [isDarkTheme, setIsDarkMode] = useState<boolean>(false);
	const themeDetector = useThemeDetector();

	useEffect(() => {
		globalThis.systemName = 'Sistema de Gestión de la Dirección Médica de RedSalud';
		globalThis.language = i18next.language.substring(0, 2);
		globalThis.isLoading = false;
		globalThis.networkId = 1;
		globalThis.version = packageJson.version;
		document.body.style.background = themeDetector ? 'black' : 'white';
		dayjs.locale(i18next.language.substring(0, 2));
		setIsDarkMode(themeDetector);
	}, [themeDetector]);

	return (
		<App>
			<Suspense fallback={<div></div>}>
				<ConfigProvider
					locale={locales[i18next.language]}
					theme={{
						token: { colorPrimary: colors.brandColor1, colorSuccess: colors.brandColor2 },
						algorithm: isDarkTheme ? theme.darkAlgorithm : theme.defaultAlgorithm,
					}}
				>
					<GoogleOAuthProvider clientId={client_id}>
						<ReduxProvider store={store}>
							<AuthProvider>
								<BrowserRouter>
									<Routes>
										<Route path={NonAuthRoutes.login} element={<PageLogin />} />
										<Route path={NonAuthRoutes.registration} element={<Pages.PageRegistrationProfessional />} />
										<Route path={NonAuthRoutes.downloadApp} element={<PageAppDownload />} />
										<Route path={NonAuthRoutes.professionalsPhysicians} element={<Pages.PageProfessionalsPhysicians />} />
										<Route path={NonAuthRoutes.physiciansDirectory} element={<Pages.PageProfessionalsPhysicians />} />
										<Route path={NonAuthRoutes.professionalsDentists} element={<Pages.PageProfessionalsDentists />} />
										<Route path={NonAuthRoutes.dentistsDirectory} element={<Pages.PageProfessionalsDentists />} />
										<Route path={NonAuthRoutes.documentsDirectory} element={<Pages.PageContentsDocuments />} />
										<Route path={AuthRoutes.loading} element={<PageLoading />} />
										<Route path="/" element={<PageMain />}>
											<Route path={AuthRoutes.home} element={<PageHome />} />
											<Route path={AuthRoutes.viewDetails} element={<PageViewDetails />} />
											<Route path={AuthRoutes.viewProfessionals} element={<Pages.PageViewProfessionals />} />
											<Route path={AuthRoutes.viewNews} element={<Pages.PageViewNews />} />
											<Route path={AuthRoutes.viewNewsItem} element={<Pages.PageViewNewsItem />} />
											<Route path={AuthRoutes.viewDocuments} element={<Pages.PageViewDocuments />} />
											<Route path={AuthRoutes.viewLinks} element={<Pages.PageViewLinks />} />
											<Route path={AuthRoutes.activities + '/index'} element={<Pages.PageActivityIndex />} />
											<Route path={AuthRoutes.activities + '/details'} element={<Pages.PageActivityDetails />} />
											<Route path={AuthRoutes.cases + '/index'} element={<Pages.PageCaseIndex />} />
											<Route path={AuthRoutes.cases + '/details'} element={<Pages.PageCaseDetails />} />
											<Route path={AuthRoutes.persons + '/index'} element={<Pages.PagePersonIndex />} />
											<Route path={AuthRoutes.persons + '/details'} element={<Pages.PagePersonDetails />} />
											<Route path={AuthRoutes.tasks + '/index'} element={<Pages.PageTaskIndex />} />
											<Route path={AuthRoutes.tasks + '/details'} element={<Pages.PageTaskDetails />} />
											<Route path={AuthRoutes.centers + '/index'} element={<Pages.PageCentersIndex />} />
											<Route path={AuthRoutes.centers + '/details'} element={<Pages.PageCentersDetails />} />
											<Route path={AuthRoutes.services + '/index'} element={<Pages.PageServicesIndex />} />
											<Route path={AuthRoutes.physicians + '/index'} element={<Pages.PageProfessionalsIndex />} />
											<Route path={AuthRoutes.physicians + '/details'} element={<Pages.PageProfessionalDetails />} />
											<Route path={AuthRoutes.nurses + '/index'} element={<Pages.PageProfessionalsIndex />} />
											<Route path={AuthRoutes.nurses + '/details'} element={<Pages.PageProfessionalDetails />} />
											<Route path={AuthRoutes.kinesiologists + '/index'} element={<Pages.PageProfessionalsIndex />} />
											<Route path={AuthRoutes.kinesiologists + '/details'} element={<Pages.PageProfessionalDetails />} />
											<Route path={AuthRoutes.psychologists + '/index'} element={<Pages.PageProfessionalsIndex />} />
											<Route path={AuthRoutes.psychologists + '/details'} element={<Pages.PageProfessionalDetails />} />
											<Route path={AuthRoutes.dentists + '/index'} element={<Pages.PageProfessionalsIndex />} />
											<Route path={AuthRoutes.dentists + '/details'} element={<Pages.PageProfessionalDetails />} />
											<Route path={AuthRoutes.nutritionists + '/index'} element={<Pages.PageProfessionalsIndex />} />
											<Route path={AuthRoutes.nutritionists + '/details'} element={<Pages.PageProfessionalDetails />} />
											<Route path={AuthRoutes.medicalTechnologists + '/index'} element={<Pages.PageProfessionalsIndex />} />
											<Route path={AuthRoutes.medicalTechnologists + '/details'} element={<Pages.PageProfessionalDetails />} />
											<Route path={AuthRoutes.news + '/index'} element={<Pages.PageNewsIndex />} />
											<Route path={AuthRoutes.news + '/details'} element={<Pages.PageNewsDetails />} />
											<Route path={AuthRoutes.documents + '/index'} element={<Pages.PageDocumentsIndex />} />
											<Route path={AuthRoutes.documents + '/details'} element={<Pages.PageDocumentsDetails />} />
											<Route path={AuthRoutes.events + '/index'} element={<Pages.PageEventsIndex />} />
											<Route path={AuthRoutes.events + '/details'} element={<Pages.PageEventDetails />} />
											<Route path={AuthRoutes.links + '/index'} element={<Pages.PageLinksIndex />} />
											<Route path={AuthRoutes.links + '/details'} element={<Pages.PageLinkDetails />} />
											<Route path={AuthRoutes.configuration + '/user'} element={<Pages.PageConfigurationUserEdit />} />
											<Route path={AuthRoutes.configuration + '/users'} element={<Pages.PageConfigurationUsers />} />
											<Route path={AuthRoutes.configuration + '/domains'} element={<Pages.PageConfigurationDomains />} />
											<Route path={AuthRoutes.configuration + '/description'} element={<Pages.PageConfigurationDomainDescription />} />
											<Route
												path={AuthRoutes.configuration + '/descriptionByProfessional'}
												element={<Pages.PageConfigurationDomainDescriptionByProfessional />}
											/>
										</Route>
										<Route path="*" element={<PageLogin />} />
									</Routes>
								</BrowserRouter>
							</AuthProvider>
						</ReduxProvider>
					</GoogleOAuthProvider>
				</ConfigProvider>
			</Suspense>
		</App>
	);
};

export default DireccionMedicoApp;
