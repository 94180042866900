import { Translation } from '../../store/types/systems';
import { urls } from './urls';

export const createUpdateTranslation = async (translation: Translation): Promise<Translation | undefined> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(translation),
	};
	var response = await fetch(urls.createUpdateTranslations, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		var result: Translation = {
			id: data.id,
			internalId: data.internalId,
			externalId: data.externalId,
			domain: data.domain,
			networkId: data.network.id,
		};
		return result;
	}
	return undefined;
};

export const fetchTranslations = async (): Promise<Translation[]> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
		},
	};
	var response = await fetch(urls.translations, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		return data;
	}
	return [];
};
