import { useEffect, useState } from 'react';
import { Document, initialDocumentValue, ParentType, ProfessionalType } from '../../store/types';
import { Input, Form, DatePicker, Switch, Col, Row, Card, Select } from 'antd';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { baseStyles } from '../../assets/styles';
import { UploadDragPaste } from '..';
import { useTranslation } from 'react-i18next';
import { convertHtmlToDraft, convertDraftToHtml } from '../../utils/util-drafts';
import { documentsSelectors, professionalTypeSelectors } from '../../store/selectors';
import { useStore } from 'react-redux';
import { objectsAreEquals } from '../../utils/util-custom-validators';
import { exportDateTimeFormat } from '../../assets/formats';
import dayjs from 'dayjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const { Option } = Select;

const FormDocument = ({ documentId, setDocumentData, setHasErrors, setChanged }) => {
	const { t } = useTranslation();
	const styles = baseStyles;
	const store = useStore();
	const id = documentId;
	const [formDocument] = Form.useForm();
	const [document, setDocument] = useState<Document>(initialDocumentValue);
	const [originalDocument, setOriginalDocument] = useState<Document>(initialDocumentValue);
	const [editorState, setEditorState] = useState<EditorState>(convertHtmlToDraft(''));
	const [imageUrl, setImageUrl] = useState<string>('');
	const [professionalTypes, setProfessionalTypes] = useState<ProfessionalType[]>([]);

	useEffect(() => {
		var professionalTypeItems = professionalTypeSelectors.selectAll(store.getState() as any);
		setProfessionalTypes([{ id: 0, code: 'All', description: 'Todos', isDeleted: false }, ...professionalTypeItems]);
		var item = documentsSelectors.selectById(store.getState() as any, id) || initialDocumentValue;
		setDocument(item);
		setOriginalDocument(item);
		setEditorState(convertHtmlToDraft(item.summary));
		if (item.avatar !== '') setImageUrl(item.avatar);
		formDocument.setFieldsValue({
			professionalType: item.professionalTypeId || 0,
			title: item.title,
			author: item.author,
			documentDate: dayjs(item.documentDate || new Date()),
			publishDate: dayjs(item.publishDate || new Date()),
			isPublished: item.isPublished,
		});
	}, [formDocument, id, store]);

	const setAvatarUrl = (url: string) => {
		setImageUrl(url);
		setDocument({ ...document, avatar: url });
		setDocumentData({ ...document, avatar: url });
	};

	const handleEditorChange = (editorState: EditorState) => {
		var item: Document = { ...document, summary: convertDraftToHtml(editorState) };
		setEditorState(editorState);
		setChanged(!objectsAreEquals(originalDocument, item));
		setDocument(item);
		setDocumentData(item);
	};

	const handleValuesChange = async (changedValues: any) => {
		var item: Document = document;
		if (changedValues['professionalType'] !== undefined) item = { ...document, professionalTypeId: changedValues['professionalType'] };
		if (changedValues['title'] !== undefined) item = { ...document, title: changedValues['title'] };
		if (changedValues['author'] !== undefined) item = { ...document, author: changedValues['author'] };
		if (changedValues['documentDate'] !== undefined) item = { ...document, documentDate: changedValues['documentDate'].format(exportDateTimeFormat) };
		if (changedValues['publishDate'] !== undefined) item = { ...document, publishDate: changedValues['publishDate'].format(exportDateTimeFormat) };
		if (changedValues['isPublished'] !== undefined) item = { ...document, isPublished: changedValues['isPublished'] };
		setChanged(!objectsAreEquals(originalDocument, item));
		setDocument(item);
		setDocumentData(item);
		setTimeout(() => {
			formDocument
				.validateFields()
				.then(() => {
					setHasErrors(false);
				})
				.catch(errors => {
					if (errors.errorFields.length > 0) setHasErrors(true);
				});
		});
	};

	return (
		<div>
			<Row gutter={[16, 16]}>
				<Col lg={24} xl={24} xxl={12}>
					<Card size="small" title={t('documents.documentParameters')} style={styles.card}>
						<Form {...baseStyles.formLayout} form={formDocument} name="documents-details" onValuesChange={handleValuesChange}>
							<Form.Item label={t('general.professionalType')} name="professionalType" rules={[{ required: true }]}>
								<Select>
									{professionalTypes.map(p => {
										return (
											<Option key={`options-${p.id}`} value={p.id}>
												{p.description}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
							<Form.Item label={t('documents.title')} name="title" rules={[{ required: true }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('documents.author')} name="author" rules={[{ required: true }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('documents.documentDate')} name="documentDate" rules={[{ required: true }]}>
								<DatePicker format="DD-MM-YYYY" showTime={false} />
							</Form.Item>
							<Form.Item label={t('documents.publishDate')} name="publishDate" rules={[{ required: true }]}>
								<DatePicker format="DD-MM-YYYY" showTime={false} />
							</Form.Item>
							<Form.Item label={t('documents.isPublished')} name="isPublished" valuePropName="checked">
								<Switch />
							</Form.Item>
							<Form.Item label={t('documents.avatar')} valuePropName="fileList">
								<UploadDragPaste id={id} parentType={ParentType.documentItem} url={imageUrl} size="medium" setUrl={setAvatarUrl} />
							</Form.Item>
						</Form>
					</Card>
				</Col>
				<Col lg={24} xl={24} xxl={12}>
					<Card size="small" title={t('documents.documentDetails')} style={styles.card}>
						<Editor
							editorStyle={{ height: 500 }}
							editorState={editorState}
							toolbarClassName="toolbarClassName"
							wrapperClassName="wrapperClassName"
							editorClassName="editorClassName"
							onEditorStateChange={handleEditorChange}
						/>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default FormDocument;
