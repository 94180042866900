import {
	emailAdapter,
	phoneAdapter,
	addressAdapter,
	accreditationAdapter,
	accreditationDocumentAdapter,
	professionalsAdapter,
	specialtyAdapter,
	accomplishmentAdapter,
	privilegeAdapter,
	professionalDocumentAdapter,
	categoryAdapter,
	accreditationCommentAdapter,
	professionalMilestoneAdapter,
} from '../reducers/professional';
import { StoreStateType } from '../mainStore';

export const professionalsSelectors = professionalsAdapter.getSelectors((state: StoreStateType) => state.professionals);
export const emailsSelectors = emailAdapter.getSelectors((state: StoreStateType) => state.emails);
export const phonesSelectors = phoneAdapter.getSelectors((state: StoreStateType) => state.phones);
export const addressesSelectors = addressAdapter.getSelectors((state: StoreStateType) => state.addresses);
export const accreditationsSelectors = accreditationAdapter.getSelectors((state: StoreStateType) => state.accreditations);
export const accreditationCommentSelectors = accreditationCommentAdapter.getSelectors((state: StoreStateType) => state.accreditationComments);
export const specialtySelectors = specialtyAdapter.getSelectors((state: StoreStateType) => state.specialties);
export const professionalMilestoneSelectors = professionalMilestoneAdapter.getSelectors((state: StoreStateType) => state.professionalMilestones);
export const accomplishmentSelectors = accomplishmentAdapter.getSelectors((state: StoreStateType) => state.accomplishments);
export const accreditationDocumentsSelectors = accreditationDocumentAdapter.getSelectors((state: StoreStateType) => state.accreditationDocuments);
export const professionalDocumentsSelectors = professionalDocumentAdapter.getSelectors((state: StoreStateType) => state.professionalDocuments);
export const privilegeSelectors = privilegeAdapter.getSelectors((state: StoreStateType) => state.privileges);
export const categorySelectors = categoryAdapter.getSelectors((state: StoreStateType) => state.categories);
