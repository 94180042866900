import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { News } from '../types/news';

//#region News Items
export const newsAdapter = createEntityAdapter({
	selectId: (item: News) => item.id,
});

export const news = createSlice({
	name: 'news',
	initialState: newsAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		newsItemAdded: newsAdapter.addOne,
		newsItemLoading: state => {
			if (!state.loading) state.loading = true;
		},
		newsItemReceived: (state, action) => {
			if (state.loading) {
				newsAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		newsItemCreateUpdate: newsAdapter.upsertOne,
	},
});
//#endregion
