import { AuthRoutes, FormDomainEditDescription } from '../../components';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, ControlTwoTone } from '@ant-design/icons';
import { Col, Row, Breadcrumb, Button, Space, Tooltip } from 'antd';
import { colors } from '../../assets/styles';

const PageConfigurationDomainDescription = activeKey => {
	const location = useLocation();
	const state: any = location.state;
	const domain = state.domain;
	const urlFetchData = state.urlFetchData;
	const urlUpdate = state.urlUpdate;
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div>
			<Col>
				<Row>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Breadcrumb
							separator=">"
							items={[
								{ title: <ControlTwoTone twoToneColor={colors.brandColor1} /> },
								{ title: t('general.configuration') },
								{ title: <Link to={{ pathname: AuthRoutes.configuration + '/domains' }}>{t('general.domains')}</Link> },
								{ title: domain },
							]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Space>
							<Tooltip title={t('general.back')}>
								<Button
									icon={<ArrowLeftOutlined />}
									type="primary"
									shape="round"
									onClick={() => navigate(AuthRoutes.configuration + '/domains', { state: { activeKey: activeKey } })}
								>
									{t('general.back')}
								</Button>
							</Tooltip>
						</Space>
					</Col>
				</Row>
				<FormDomainEditDescription domain={domain} urlFetchData={urlFetchData} urlUpdate={urlUpdate}></FormDomainEditDescription>
			</Col>
		</div>
	);
};

export default PageConfigurationDomainDescription;
