import { useState, ReactNode, Key, useEffect } from 'react';
import { useStore } from 'react-redux';
import { Row, Col, Typography, Table } from 'antd';
import { Professional, initialProfessionalValue, Center, CenterType } from '../../store/types';
import { centersSelectors, centerTypesSelectors } from '../../store/selectors';
import { TableRowSelection } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import { objectsAreEquals } from '../../utils/util-custom-validators';
import { fetchProfessional } from '../../store/api';

const { Text } = Typography;

const FormProfessionalCenters = ({ professionalId, setProfessionalData, setIsChanged }) => {
	const store = useStore();
	const { t } = useTranslation();
	const [originalProfessional, setOriginalProfessional] = useState<Professional>(initialProfessionalValue);
	const [professional, setProfessional] = useState<Professional>(initialProfessionalValue);
	const [centerTypes, setCenterTypes] = useState<CenterType[]>([]);
	const [centers, setCenters] = useState<Center[]>([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>();

	useEffect(() => {
		fetchProfessional(professionalId).then(item => {
			setProfessional(item);
			setOriginalProfessional(item);
			const initialSelectedKeys = item.centers;
			setSelectedRowKeys(initialSelectedKeys);
		});
		setCenterTypes(centerTypesSelectors.selectAll(store.getState() as any));
		setCenters(centersSelectors.selectAll(store.getState() as any).filter(c => c.isVisible));
	}, [professionalId, store]);

	const columns: ColumnProps<Center>[] = [
		{
			title: t('professionals.centerName'),
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: t('professionals.centerDescription'),
			dataIndex: 'description',
			key: 'description',
		},
		{
			title: t('professionals.centerType'),
			dataIndex: 'centerTypeId',
			key: 'centerTypeId',
			render: (_text: string, record: Center): ReactNode => {
				return <Text>{centerTypes.find(c => c.id === record.centerTypeId)?.description}</Text>;
			},
		},
	];

	const handleRowSelect = (selectedRowKeys: Key[], selectedRows: Center[]) => {
		setSelectedRowKeys(selectedRowKeys);
		setIsChanged(!objectsAreEquals(originalProfessional, { ...professional, centers: selectedRows.map(c => c.id) }));
		setProfessional({ ...professional, centers: selectedRows.map(c => c.id) });
		setProfessionalData({ ...professional, centers: selectedRows.map(c => c.id) });
	};

	const rowSelection: TableRowSelection<Center> = {
		selectedRowKeys: selectedRowKeys,
		onChange: handleRowSelect,
	};

	return (
		<div>
			<Row style={{ padding: 10 }}>
				<Col flex={1}>
					<Table<Center>
						rowKey={record => record.id}
						tableLayout="auto"
						size="small"
						rowSelection={rowSelection}
						dataSource={centers}
						pagination={false}
						columns={columns}
					/>
				</Col>
			</Row>
		</div>
	);
};
export default FormProfessionalCenters;
