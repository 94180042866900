import { ReactNode, useEffect, useState } from 'react';
import { Space, Button, Row, Col, message, Popconfirm, Tooltip, AutoComplete, Typography, Layout, Breadcrumb, Table } from 'antd';
import { DeleteOutlined, EditOutlined, GoldTwoTone, PlusOutlined } from '@ant-design/icons';
import { Service, initialServiceValue, ServiceNode } from '../../store/types';
import { servicesSelectors } from '../../store/selectors';
import { services as servicesReducer } from '../../store/reducers';
import { useStore } from 'react-redux';
import { SizedModal, FormServiceEdit } from '../../components';
import { createUpdateService, createServiceTree } from '../../store/api';
import { useTranslation } from 'react-i18next';
import { baseStyles, colors } from '../../assets/styles';
import { ColumnProps } from 'antd/lib/table';

const key = 'saving';
const { Text } = Typography;
const { Content } = Layout;

const PageServicesIndex = () => {
	const store = useStore();
	const state = store.getState() as any;
	const { t } = useTranslation();
	const styles = baseStyles;
	const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);
	const [service, setService] = useState<Service>(initialServiceValue);
	const [services, setServices] = useState<Service[]>([]);
	const [modalTitle, setModalTitle] = useState<string>('');
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [isloading, setIsLoading] = useState(true);
	const [serviceNodes, setServiceNodes] = useState<ServiceNode[]>([]);
	const [searchValue, setSearchValue] = useState<string>('');
	const [options, setOptions] = useState([]);

	window.onresize = () => {
		setInnerHeight(window.innerHeight);
	};

	useEffect(() => {
		var items = servicesSelectors.selectAll(state).filter(c => !c.isDeleted);
		setServices(items);
		setServiceNodes(createServiceTree(items));
		setIsLoading(false);
	}, [state]);

	const handleEdit = (item: ServiceNode) => {
		setService(services.find(s => s.id === item.key) || initialServiceValue);
		setModalTitle(t('network.editService'));
		setIsModalVisible(true);
	};

	const handleAdd = () => {
		setModalTitle(t('network.addService'));
		setIsModalVisible(true);
	};

	const handleSelect = (_value, option) => {
		setService(services.find(s => s.id === option.value) || initialServiceValue);
		setModalTitle(t('network.editService'));
		setIsModalVisible(true);
	};

	const handleSearch = (value: string) => {
		setSearchValue(value);
		if (value) {
			var searchResult = servicesSelectors.selectAll(state).filter(ns => !ns.isDeleted && ns.description.toUpperCase().includes(value.toUpperCase()));
			var searchVisualization = searchResult.map(item => {
				return {
					value: item.id,
					label: (
						<div>
							<Text>{item.description}</Text>
						</div>
					),
				};
			});
			setOptions(searchVisualization);
		} else setOptions([]);
	};

	const handleOk = async () => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		setIsModalVisible(false);
		var result = await createUpdateService(service);
		if (result !== undefined) {
			store.dispatch(servicesReducer.actions.serviceCreateUpdate(result));
			setServices((servicesSelectors.selectAll(state) as Service[]).filter(c => !c.isDeleted));
			setServiceNodes(createServiceTree((servicesSelectors.selectAll(state) as Service[]).filter(c => !c.isDeleted)));
			message.success({ content: t('messages.dataSaved'), key, duration: 2 });
		} else message.error({ content: t('messages.errorOnTransaction'), key, duration: 2 });
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const columns: ColumnProps<ServiceNode>[] = [
		{
			title: t('network.description'),
			dataIndex: 'title',
			key: 'title',
			sorter: {
				compare: (a: ServiceNode, b) => {
					if (a.title < b.title) return -1;
					if (a.title > b.title) return 1;
					return 0;
				},
				multiple: 1,
			},
		},
		{
			title: t('general.actions'),
			dataIndex: 'edit',
			key: 'edit',
			width: 100,
			render: (_text: string, item: ServiceNode): ReactNode => {
				return (
					<Space size="middle">
						<Tooltip title={t('general.edit')}>
							<Button
								type="primary"
								shape="round"
								icon={<EditOutlined />}
								onClick={() => {
									handleEdit(item);
								}}
							/>
						</Tooltip>
						<Popconfirm title={t('messages.confirmDeleteItem')} onConfirm={() => {}} okText={t('general.ok')} cancelText={t('general.cancel')}>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<div>
			<Col>
				<Row>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Breadcrumb
							separator=">"
							items={[{ title: <GoldTwoTone twoToneColor={colors.brandColor1} /> }, { title: t('general.network') }, { title: t('network.services') }]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Space>
							<Button type="primary" shape="round" icon={<PlusOutlined />} onClick={handleAdd}>
								{t('general.add')}
							</Button>
						</Space>
					</Col>
				</Row>
				<Content className="site-layout-background">
					<Row>
						<Col key="col1" flex={3}>
							<Space direction="horizontal">
								<Text strong>{t('general.searchEngine')}</Text>
								<AutoComplete
									value={searchValue}
									popupMatchSelectWidth={baseStyles.popupMatchSelectWidth}
									style={styles.search}
									placeholder={t('general.search')}
									allowClear
									options={options}
									onSelect={handleSelect}
									onSearch={handleSearch}
								/>
							</Space>
						</Col>
						<Col flex={4}></Col>
						<Col flex={3}></Col>
					</Row>
					<Row style={{ paddingTop: 10 }}>
						<Col flex={1}>
							<Table<ServiceNode>
								tableLayout="auto"
								size="small"
								dataSource={serviceNodes}
								columns={columns}
								loading={isloading}
								pagination={false}
								scroll={{
									y: innerHeight * 0.75,
								}}
							/>
						</Col>
					</Row>
					<SizedModal
						size="small"
						title={modalTitle}
						open={isModalVisible}
						okText={t('general.save')}
						cancelText={t('general.cancel')}
						onOk={handleOk}
						onCancel={handleCancel}
					>
						<FormServiceEdit key={`editService-${Date.now()}`} serviceId={service.id} setServiceData={setService} />
					</SizedModal>
				</Content>
			</Col>
		</div>
	);
};

export default PageServicesIndex;
