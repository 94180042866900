import { Input, Form, InputNumber, Switch } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
	const { t } = useTranslation();
	var inputNode: ReactNode;
	if (inputType === 'number') inputNode = <InputNumber />;
	else if (inputType === 'boolean') inputNode = <Switch />;
	else {
		inputNode = <Input />;
	}
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					key={`item-${index}`}
					name={dataIndex}
					style={{
						margin: 0,
					}}
					valuePropName={inputType === 'boolean' ? 'defaultChecked' : 'checked'}
					rules={[
						{
							required: true,
							message: `${t('messages.pleaseInput')}${title}!`,
						},
					]}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};

export default EditableCell;
