export const FormatRut = (text: string) => {
	var lastDigit = text.charAt(text.length - 1);
	var rest = text.substring(0, text.length - 1);
	if (rest.length === 7) return `${rest.charAt(0)}.${rest.substr(1, 3)}.${rest.substr(4, 3)}-${lastDigit.toUpperCase()}`;
	else if (rest.length === 8) return `${rest.substr(0, 2)}.${rest.substr(2, 3)}.${rest.substr(5, 3)}-${lastDigit.toUpperCase()}`;
	else return '';
};

export const NormalizeName = (text: string) => {
	var words = text.split(' ');
	var normalized = [];
	words.forEach((w: string) => {
		if (w.length > 2) {
			var replaced = w.charAt(0).toUpperCase() + w.substring(1, w.length);
			normalized.push(replaced);
		} else normalized.push(w);
	});
	return normalized.join(' ');
};

export const rgbToHex = (text: string) => {
	return text.replace(/rgb\((.+?)\)/gi, (_, rgb) => {
		return (
			'#' +
			rgb
				.split(',')
				.map((part: string) => parseInt(part, 10).toString(16).padStart(2, '0'))
				.join('')
		);
	});
};
