import { ReactNode, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import {
	Space,
	DatePicker,
	Input,
	Card,
	Button,
	message,
	Form,
	Select,
	Row,
	Col,
	Popconfirm,
	Tooltip,
	Table,
	Typography,
	AutoComplete,
	Layout,
	Breadcrumb,
} from 'antd';
import { AuthRoutes, EditableCell, FormTaskDetails, SizedModal } from '../../components';
import {
	initialActivityValue,
	Activity,
	Priority,
	ActivityType,
	Center,
	Task,
	Agreement,
	User,
	Comment,
	initialAgreementValue,
	initialCommentValue,
	TaskStatus,
	TaskType,
	initialTaskValue,
} from '../../store/types';
import { activityTypesSelectors, prioritiesSelectors, taskStatusesSelectors, taskTypesSelectors } from '../../store/selectors/domains';
import { createUpdateActivity, createUpdateAgreement } from '../../store/api';
import { activitiesSelectors, agreementsSelectors, centersSelectors, commentsSelectors, tasksSelectors, usersSelectors } from '../../store/selectors';
import { activities as activitiesReducer, agreements as agreementsReducer, comments as commentsReducer, tasks as tasksReducer } from '../../store/reducers';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	ApartmentOutlined,
	ArrowLeftOutlined,
	DeleteOutlined,
	EditOutlined,
	FilterFilled,
	PlusOutlined,
	RollbackOutlined,
	SaveOutlined,
} from '@ant-design/icons';
import { dateFormat } from '../../assets/formats';
import { baseStyles, colors } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import { createUpdateActivityComment, createUpdateTask, deleteActivityComment, deleteAgreement, deleteTask } from '../../store/api/management';
import { removeItem } from '../../utils/util-generics';
import { includes } from '../../utils/util-custom-validators';
import locale from 'antd/es/date-picker/locale/es_ES';
import dayjs from 'dayjs';

const key = 'saving';

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;
const { Content } = Layout;

const PageActivityDetails = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const params: any = location.state;
	var id: number = parseInt(params.id);
	const store = useStore();
	const styles = baseStyles;
	const { t } = useTranslation();
	const [formActivityDetails] = Form.useForm();
	const [formAgreement] = Form.useForm();
	const [formComment] = Form.useForm();
	const [title, setTitle] = useState<string>('');
	const [changed, setChanged] = useState<boolean>(false);
	const [activity, setActivity] = useState<Activity>(initialActivityValue);
	const [activityTypes, setActivityTypes] = useState<ActivityType[]>([]);
	const [centers, setCenters] = useState<Center[]>([]);
	const [priorities, setPriorities] = useState<Priority[]>([]);
	const [users, setUsers] = useState<User[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
	const [selectedTask, setSelectedTask] = useState<Task>(initialTaskValue);
	const [tasks, setTasks] = useState<Task[]>([]);
	const [taskStatuses, setTaskStatuses] = useState<TaskStatus[]>([]);
	const [taskTypes, setTaskTypes] = useState<TaskType[]>([]);
	const [agreements, setAgreements] = useState<Agreement[]>([]);
	const [comments, setComments] = useState<Comment[]>([]);
	const [editingCommentKey, setEditingCommentKey] = useState<number>(0);
	const [editingAgreementKey, setEditingAgreeementKey] = useState<number>(0);
	const [isloadingComments, setIsLoadingComments] = useState(true);
	const [isloadingAgreements, setIsLoadingAgreements] = useState(true);
	const [isloadingUsers, setIsLoadingUsers] = useState(true);
	const [isloadingTasks, setIsLoadingTasks] = useState(true);
	const [searchText, setSearchText] = useState<string>('');
	const [options, setOptions] = useState([]);
	const [isModelVisible, setIsModalVisible] = useState<boolean>(false);
	const [span, setSpan] = useState<number>(24);

	window.onresize = () => {
		window.innerWidth > 1600 ? setSpan(12) : setSpan(24);
	};

	useEffect(() => {
		window.innerWidth > 1600 ? setSpan(12) : setSpan(24);
		setTaskStatuses(taskStatusesSelectors.selectAll(store.getState() as any));
		setTaskTypes(taskTypesSelectors.selectAll(store.getState() as any));
		setActivityTypes(activityTypesSelectors.selectAll(store.getState() as any));
		setPriorities(prioritiesSelectors.selectAll(store.getState() as any));
		setCenters(centersSelectors.selectAll(store.getState() as any).filter(c => !c.isDeleted && c.isVisible));
		var item: Activity = activitiesSelectors.selectById(store.getState() as any, id) || initialActivityValue;
		setActivity(item);
		setTasks(tasksSelectors.selectAll(store.getState() as any).filter(t => item.tasksIds.includes(t.id) && !t.isDeleted));
		var userItems = usersSelectors.selectAll(store.getState() as any).filter(u => !u.isDeleted);
		setUsers(userItems);
		setSelectedUsers(userItems.filter(u => item.userIds.includes(u.id)));
		setAgreements(agreementsSelectors.selectAll(store.getState() as any).filter(a => id !== 0 && a.activityId === id && !a.isDeleted));
		setComments(commentsSelectors.selectAll(store.getState() as any).filter(c => id !== 0 && c.activityId === id && !c.isDeleted));
		setTitle(item.description || t('management.newActivity'));
		formActivityDetails.setFieldsValue({
			description: item.description || t('management.newActivity'),
			objectives: item.objectives || '',
			activityNumber: item.activityNumber.toUpperCase(),
			activityType: item.activityTypeId || 1,
			activity: item.activityDate,
			activityDate: dayjs(item.activityDate || Date.now()),
			priority: item.priorityId || 1,
			origin: item.originId || 1,
		});
		setIsLoadingComments(false);
		setIsLoadingAgreements(false);
		setIsLoadingUsers(false);
		setIsLoadingTasks(false);
	}, [id, formActivityDetails, store, t]);

	const handleAddAgreement = () => {
		const newData: Agreement = initialAgreementValue;
		setAgreements([...agreements, newData]);
	};

	const isEditingAgreement = (item: Agreement) => item.id === editingAgreementKey;

	const handleEditAgreement = (item: Agreement) => {
		formAgreement.setFieldsValue({
			...item,
		});
		setEditingAgreeementKey(item.id);
	};

	const handleAgreementCancel = () => {
		setAgreements(agreements.slice(0, agreements.length - 1));
		setEditingAgreeementKey(0);
	};

	const handleDeleteAgreement = async (item: Agreement) => {
		setIsLoadingAgreements(true);
		try {
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			var ok = await deleteAgreement(item);
			if (ok) {
				var temp = { ...item, isDeleted: true };
				store.dispatch(agreementsReducer.actions.agreementsCreateUpdate(temp));
				setAgreements(agreementsSelectors.selectAll(store.getState() as any).filter(a => a.activityId === id && !a.isDeleted));
				message.success({ content: t('messages.dataSaved'), key, duration: 2 });
			} else message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		}
		setIsLoadingAgreements(false);
	};

	// Comments handle
	const isEditingComment = (item: Comment) => item.id === editingCommentKey;

	const handleEditComment = (item: Comment) => {
		formComment.setFieldsValue({
			...item,
		});
		setEditingCommentKey(item.id);
	};

	const handleAddComment = () => {
		const newData: Comment = initialCommentValue;
		setComments([...comments, newData]);
	};

	const handleDeleteComment = async (item: Comment) => {
		setIsLoadingComments(true);
		try {
			var ok = await deleteActivityComment(item);
			if (ok) {
				var temp = { ...item, isDeleted: true };
				store.dispatch(commentsReducer.actions.commmentsCreateUpdate(temp));
				setComments(commentsSelectors.selectAll(store.getState() as any).filter(c => c.activityId === id && !c.isDeleted));
			} else message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		}
		setIsLoadingComments(false);
	};

	const handleCommentCancel = () => {
		setComments(comments.slice(0, comments.length - 1));
		setEditingCommentKey(0);
	};

	const handleAddTask = () => {
		setIsModalVisible(true);
	};

	const handleDeleteTask = async item => {
		var temp: Activity = {
			...activity,
			tasksIds: removeItem(activity.tasksIds, item.id),
		};
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		var deleteTaskResponse = await deleteTask(item);
		if (deleteTaskResponse) {
			store.dispatch(tasksReducer.actions.tasksCreateUpdate({ ...item, isDeleted: true }));
			var updateActivityResponse = await createUpdateActivity(temp);
			if (updateActivityResponse !== undefined) {
				store.dispatch(activitiesReducer.actions.activitiesCreateUpdate(updateActivityResponse));
				message.success({ content: t('messages.dataSaved'), key, duration: 2 });
				setActivity(activitiesSelectors.selectById(store.getState() as any, id) || initialActivityValue);
				setTasks(tasksSelectors.selectAll(store.getState() as any).filter(t => updateActivityResponse.tasksIds.includes(t.id) && !t.isDeleted));
				setChanged(false);
			} else message.error(t('message.unableToDelete'));
		} else message.info({ content: t('messages.errorOnSave'), key, duration: 2 });
	};

	const handleActivitySave = async () => {
		try {
			const values = await formActivityDetails.validateFields();
			if (values.errorField === undefined) {
				var temp: Activity = {
					...activity,
					description: values.description,
					activityTypeId: values.activityType,
					objectives: values.objectives,
					priorityId: values.priority,
					originId: values.origin,
					activityDate: values.activityDate.toDate(),
					userIds: selectedUsers.map(u => {
						return u.id;
					}),
				};
				message.loading({ content: t('messages.saving'), key, duration: 0 });
				var response = await createUpdateActivity(temp);
				if (response !== undefined) {
					store.dispatch(activitiesReducer.actions.activitiesCreateUpdate(response));
					message.success({ content: t('messages.dataSaved'), key, duration: 2 });
					setActivity(activitiesSelectors.selectById(store.getState() as any, id) || initialActivityValue);
					setChanged(false);
				} else message.info({ content: t('messages.errorOnSave'), key, duration: 2 });
			}
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		}
	};

	const handleTaskSave = async () => {
		setIsModalVisible(false);
		try {
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			if (activity.id === 0) {
				var responseActivity = await createUpdateActivity(activity);
				if (responseActivity !== undefined) {
					store.dispatch(activitiesReducer.actions.activitiesCreateUpdate(responseActivity));
					setActivity(activitiesSelectors.selectById(store.getState() as any, id) || initialActivityValue);
				} else {
					message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
					return;
				}
			}
			var responseTask = await createUpdateTask(selectedTask, id, 0);
			if (responseTask !== undefined) {
				var tempActivity: Activity = {
					...activity,
				};
				if (!tempActivity.tasksIds.includes(responseTask.id))
					tempActivity = {
						...activity,
						tasksIds: activity.tasksIds.concat(responseTask.id),
					};
				responseActivity = await createUpdateActivity(tempActivity);
				if (responseActivity !== undefined) {
					store.dispatch(activitiesReducer.actions.activitiesCreateUpdate(responseActivity));
					setActivity(responseActivity);
					store.dispatch(tasksReducer.actions.tasksCreateUpdate(responseTask));
					setTasks(tasksSelectors.selectAll(store.getState() as any).filter(t => responseActivity.tasksIds.includes(t.id) && !t.isDeleted));
					message.success({ content: t('messages.dataSaved'), key, duration: 2 });
					setChanged(false);
				}
			} else message.info({ content: t('messages.errorOnSave'), key, duration: 2 });
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		}
	};

	const handleAgreementSave = async (item: Agreement) => {
		setIsLoadingAgreements(true);
		try {
			const values = await formAgreement.validateFields();
			if (values.errorField === undefined) {
				var temp: Agreement = {
					...item,
					id: item.id,
					activityId: id,
					description: values.description,
				};
				message.loading({ content: t('messages.saving'), key, duration: 0 });
				var response = await createUpdateAgreement(temp, id);
				if (response !== undefined) {
					store.dispatch(agreementsReducer.actions.agreementsCreateUpdate(response));
					setAgreements(agreementsSelectors.selectAll(store.getState() as any).filter(a => a.activityId === id && !a.isDeleted));
					message.success({ content: t('messages.dataSaved'), key, duration: 2 });
				} else message.info({ content: t('messages.errorOnSave'), key, duration: 2 });
				setEditingAgreeementKey(0);
			}
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
			setEditingAgreeementKey(0);
		}
		setIsLoadingAgreements(false);
	};

	const handleCommentSave = async (item: Comment) => {
		setIsLoadingComments(true);
		try {
			const values = await formComment.validateFields();
			if (values.errorField === undefined) {
				var temp: Comment = {
					...item,
					id: item.id,
					activityId: id,
					caseId: 0,
					description: values['description'],
				};
				message.loading({ content: t('messages.saving'), key, duration: 0 });
				var response = await createUpdateActivityComment(temp, id);
				if (response !== undefined) {
					store.dispatch(commentsReducer.actions.commmentsCreateUpdate(response));
					setComments(commentsSelectors.selectAll(store.getState() as any).filter(c => c.activityId === id && !c.isDeleted));
					message.success({ content: t('messages.dataSaved'), key, duration: 2 });
				} else message.info({ content: t('messages.errorOnSave'), key, duration: 2 });
				setEditingCommentKey(0);
			}
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
			setEditingCommentKey(0);
		}
		setIsLoadingComments(false);
	};

	const handleSearchUser = (searchText: string) => {
		setSearchText(searchText);
		if (searchText !== '') {
			var seleted = users
				.filter(u => u.username.includes(searchText))
				.map(u => {
					return { value: u.username };
				});
			setOptions(!searchText ? [] : seleted);
		} else setOptions([]);
	};

	const handleSelectUser = (seletedData: string) => {
		setSearchText('');
		setChanged(true);
		var user = users.find(u => u.username === seletedData);
		if (user && !activity.userIds.includes(user.id)) {
			setChanged(true);
			setSelectedUsers([...selectedUsers, user]);
		}
		setOptions([]);
	};

	const handleDeleteUser = (item: User) => {
		setChanged(true);
		setSelectedUsers(selectedUsers.filter(u => u.id !== item.id));
	};

	const handleValuesChange = (changedValues: any) => {
		setChanged(true);
		if (changedValues['description']) setTitle(changedValues['description']);
	};

	const columns: ColumnProps<User>[] = [
		{
			title: t('management.description'),
			dataIndex: 'username',
			key: 'username',
			width: '50%',
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: User): ReactNode => {
				return (
					<Space size="middle">
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeleteUser(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	const columnsTask: ColumnProps<Task>[] = [
		{
			title: t('management.description'),
			dataIndex: 'description',
			key: 'description',
			width: '50%',
			render: (_text: string, item: Task): ReactNode => <Text>{item.description}</Text>,
		},
		{
			title: t('management.creationDate'),
			dataIndex: 'creationDate',
			key: 'creationDate',
			width: 150,
			sorter: (a, b) => dayjs(a.creationDate).diff(dayjs(b.creationDate), 'seconds'),
			render: (_text: string, item: Task): ReactNode => <Text>{item.creationDate ? dayjs(item.creationDate).format(dateFormat) : ''}</Text>,
		},
		{
			title: t('management.taskType'),
			dataIndex: 'taskTypeId',
			key: 'taskTypeId',
			filterIcon: <FilterFilled style={styles.filter}></FilterFilled>,
			filters: taskTypes.map(t => {
				return {
					text: t.description,
					value: t.id,
				};
			}),
			onFilter: (value, item) => item.taskTypeId === value,
			render: (_text: string, item: Task): ReactNode => <Text>{taskTypes.find(t => t.id === item.taskTypeId)?.description}</Text>,
		},
		{
			title: t('management.taskStatus'),
			dataIndex: 'taskStatusId',
			key: 'taskStatusId',
			filterIcon: <FilterFilled style={styles.filter}></FilterFilled>,
			filters: taskStatuses.map(t => {
				return {
					text: t.description,
					value: t.id,
				};
			}),
			onFilter: (value, item) => item.taskStatusId === value,
			render: (_text: string, item: Task): ReactNode => <Text>{taskStatuses.find(t => t.id === item.taskStatusId)?.description}</Text>,
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: Task): ReactNode => {
				return (
					<Space size="middle">
						<Tooltip title={t('general.edit')}>
							<Button
								type="primary"
								shape="round"
								onClick={() => {
									setSelectedTask(item);
									setIsModalVisible(true);
								}}
								icon={<EditOutlined />}
							/>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeleteTask(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	const columnsComment = [
		{
			title: t('management.description'),
			dataIndex: 'description',
			key: 'description',
			editable: true,
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: Comment): ReactNode => {
				const editable = isEditingComment(item);
				return editable ? (
					<Space>
						<Button type="primary" shape="round" onClick={() => handleCommentSave(item)} icon={<SaveOutlined />} />
						<Popconfirm title={t('messages.confirmCancel')} onConfirm={handleCommentCancel} okText={t('general.ok')} cancelText={t('general.cancel')}>
							<Button type="primary" shape="round" icon={<RollbackOutlined />} />
						</Popconfirm>
					</Space>
				) : (
					<Space size="middle">
						<Tooltip title={t('general.edit')}>
							<Button
								type="primary"
								shape="round"
								onClick={() => {
									handleEditComment(item);
								}}
								icon={<EditOutlined />}
							/>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeleteComment(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];
	const columnsAgreement = [
		{
			title: t('management.description'),
			dataIndex: 'description',
			key: 'description',
			editable: true,
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: Agreement): ReactNode => {
				const editable = isEditingAgreement(item);
				return editable ? (
					<Space>
						<Button type="primary" shape="round" onClick={() => handleAgreementSave(item)} icon={<SaveOutlined />} />
						<Popconfirm title={t('messages.confirmCancel')} onConfirm={handleAgreementCancel} okText={t('general.ok')} cancelText={t('general.cancel')}>
							<Button type="primary" shape="round" icon={<RollbackOutlined />} />
						</Popconfirm>
					</Space>
				) : (
					<Space size="middle">
						<Tooltip title={t('general.edit')}>
							<Button
								type="primary"
								shape="round"
								onClick={() => {
									handleEditAgreement(item);
								}}
								icon={<EditOutlined />}
							/>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeleteAgreement(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	const columnsMergedComment = columnsComment.map(col => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (item: Comment) => ({
				item,
				inputType: 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditingComment(item),
			}),
		};
	});

	const columnsMergedAgreement = columnsAgreement.map(col => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (item: Agreement) => ({
				item,
				inputType: 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditingAgreement(item),
			}),
		};
	});

	return (
		<div>
			<Col>
				<Row>
					<Col>
						<Breadcrumb
							separator=">"
							style={{ height: 31, margin: '16px 24px 0 0' }}
							items={[
								{ title: <ApartmentOutlined twoToneColor={colors.brandColor1} /> },
								{ title: t('management.management') },
								{ title: t('management.activities') },
								{ title: title },
							]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Space>
							{changed ? (
								<Popconfirm
									title={t('messages.confirmExit')}
									onConfirm={() => {
										navigate(AuthRoutes.activities + '/index');
									}}
									okText={t('general.ok')}
									cancelText={t('general.cancel')}
								>
									<Tooltip title={t('general.back')}>
										<Button icon={<ArrowLeftOutlined />} type="primary" shape="round">
											{t('general.back')}
										</Button>
									</Tooltip>
								</Popconfirm>
							) : (
								<Tooltip title={t('general.back')}>
									<Button icon={<ArrowLeftOutlined />} type="primary" shape="round" onClick={() => navigate(AuthRoutes.activities + '/index')}>
										{t('general.back')}
									</Button>
								</Tooltip>
							)}
							,
							<Button type="primary" disabled={!changed} shape="round" icon={<SaveOutlined />} onClick={handleActivitySave}>
								{t('general.save')}
							</Button>
						</Space>
					</Col>
				</Row>
				<Content className="site-layout-background">
					<Row>
						<Col span={span}>
							<Card size="small" title={t('management.details')} style={styles.card}>
								<Form {...baseStyles.formLayout} form={formActivityDetails} name="activity-details" onValuesChange={handleValuesChange}>
									<Form.Item label={t('management.activityNumber')} name="activityNumber">
										<Input disabled />
									</Form.Item>
									<Form.Item label={t('management.activityType')} name="activityType" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<Select
											showSearch
											allowClear
											optionFilterProp="children"
											filterOption={(input, option) => includes((option!.children as unknown as string).toLowerCase(), input.toLowerCase())}
										>
											{activityTypes.map(p => {
												return (
													<Option key={`activityType-${p.id}`} value={p.id}>
														{p.description}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
									<Form.Item label={t('management.description')} name="description">
										<Input />
									</Form.Item>
									<Form.Item label={t('management.objectives')} name="objectives">
										<TextArea />
									</Form.Item>
									<Form.Item label={t('management.activityDate')} name="activityDate" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<DatePicker locale={locale} format={dateFormat} />
									</Form.Item>
									<Form.Item label={t('management.origin')} name="origin" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<Select>
											{centers.map(p => {
												return (
													<Option key={`center-${p.id}`} value={p.id}>
														{p.name}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
									<Form.Item label={t('management.priority')} name="priority" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<Select>
											{priorities.map(p => {
												return (
													<Option key={`priority-${p.id}`} value={p.id}>
														{p.description}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
								</Form>
							</Card>
						</Col>
						<Col span={span}>
							<Card size="small" title={t('management.users')} style={styles.card}>
								<div className="body">
									<AutoComplete
										style={{ width: '100%' }}
										value={searchText}
										options={options}
										onSelect={handleSelectUser}
										onSearch={handleSearchUser}
										placeholder={t('management.searchUser')}
									/>
								</div>
								<Table<User>
									rowKey={item => item.id}
									loading={isloadingUsers}
									key={`news-table`}
									tableLayout="auto"
									size="small"
									dataSource={selectedUsers}
									columns={columns}
									pagination={false}
								/>
							</Card>
						</Col>
						<Col span={span}>
							<Card
								size="small"
								title={t('management.tasks')}
								style={styles.card}
								extra={<Button type="primary" shape="round" style={styles.buttonCard} icon={<PlusOutlined />} onClick={handleAddTask}></Button>}
							>
								<Table<Task>
									rowKey={item => item.id}
									loading={isloadingTasks}
									key={`tasks-table`}
									tableLayout="auto"
									size="small"
									dataSource={tasks}
									columns={columnsTask}
									pagination={false}
								/>
							</Card>
						</Col>
						<Col span={span}>
							<Card
								size="small"
								title={t('management.comments')}
								style={styles.card}
								extra={<Button style={styles.buttonCard} type="primary" shape="round" icon={<PlusOutlined />} onClick={handleAddComment}></Button>}
							>
								<Form form={formComment} component={false}>
									<Table<Comment>
										components={{
											body: {
												cell: EditableCell,
											},
										}}
										rowKey={item => item.id}
										loading={isloadingComments}
										key={`comments-table`}
										tableLayout="auto"
										size="small"
										dataSource={comments}
										columns={columnsMergedComment}
										pagination={false}
									/>
								</Form>
							</Card>
						</Col>
						<Col span={span}>
							<Card
								size="small"
								title={t('management.agreements')}
								style={styles.card}
								extra={<Button type="primary" style={styles.buttonCard} shape="round" icon={<PlusOutlined />} onClick={handleAddAgreement}></Button>}
							>
								<Form form={formAgreement} component={false}>
									<Table<Agreement>
										components={{
											body: {
												cell: EditableCell,
											},
										}}
										rowKey={item => item.id}
										loading={isloadingAgreements}
										key={`agreement-table`}
										tableLayout="auto"
										size="small"
										dataSource={agreements}
										columns={columnsMergedAgreement}
										pagination={false}
									/>
								</Form>
							</Card>
						</Col>
					</Row>
				</Content>
				<SizedModal
					size="medium"
					title={t('management.newTask')}
					open={isModelVisible}
					onCancel={() => {
						setIsModalVisible(false);
					}}
					onOk={() => {
						handleTaskSave();
					}}
				>
					<FormTaskDetails taskId={selectedTask.id} setSelectedTask={setSelectedTask}></FormTaskDetails>
				</SizedModal>
			</Col>
		</div>
	);
};

export default PageActivityDetails;
