import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { Select, Input, Form, Switch, Card, Col, Row, Descriptions, Empty } from 'antd';
import { Address, Center, CenterType, Commune, Country, initialAddressValue, initialCenterValue, Province, Region } from '../../store/types';
import {
	addressesSelectors,
	centersSelectors,
	centerTypesSelectors,
	communesSelectors,
	countriesSelectors,
	provincesSelectors,
	regionsSelectors,
} from '../../store/selectors';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { convertDraftToHtml, convertHtmlToDraft } from '../../utils/util-drafts';
import { Markup } from 'interweave';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FormCenterAddressEdit } from '..';

const { TextArea } = Input;
const { Option } = Select;

const FormCenterDetails = ({
	centerId,
	setCenterData = undefined,
	setAddressData = undefined,
	setHasErrors = undefined,
	setIsChanged = undefined,
	disabled = false,
}) => {
	const { t } = useTranslation();
	const store = useStore();
	const styles = baseStyles;
	const [formCenterDetails] = Form.useForm();
	const [centerTypes, setCenterTypes] = useState<CenterType[]>([]);
	const [center, setCenter] = useState<Center>(initialCenterValue);
	const [address, setAddress] = useState<Address>(initialAddressValue);
	const [communes, setCommunes] = useState<Commune[]>([]);
	const [provinces, setProvinces] = useState<Province[]>([]);
	const [regions, setRegions] = useState<Region[]>([]);
	const [countries, setCountries] = useState<Country[]>([]);
	const [editorState, setEditorState] = useState<EditorState>(undefined);
	const [span, setSpan] = useState<number>(24);

	window.onresize = () => {
		window.innerWidth > 1600 ? setSpan(12) : setSpan(24);
	};

	useEffect(() => {
		window.innerWidth > 1600 ? setSpan(12) : setSpan(24);
		setCommunes(communesSelectors.selectAll(store.getState() as any));
		setProvinces(provincesSelectors.selectAll(store.getState() as any));
		setRegions(regionsSelectors.selectAll(store.getState() as any));
		setCountries(countriesSelectors.selectAll(store.getState() as any));
		setCenterTypes(centerTypesSelectors.selectAll(store.getState() as any).filter(c => !c.isDeleted));
		var item = centersSelectors.selectById(store.getState() as any, centerId) || initialCenterValue;
		setCenter(item);
		setAddress(addressesSelectors.selectById(store.getState() as any, item.addressId) || initialAddressValue);
		setEditorState(convertHtmlToDraft(item.details || ''));
		formCenterDetails.setFieldsValue({
			name: item.name,
			description: item.description,
			internalCode: item.internalCode,
			externalCode: item.externalCode,
			centerTypeId: item.centerTypeId,
			isVisible: item.isVisible,
		});
	}, [centerId, formCenterDetails, store]);

	const handleDetailsChange = (editorState: EditorState) => {
		setEditorState(editorState);
		var item = { ...center, details: convertDraftToHtml(editorState) };
		setCenter(item);
		setCenterData(item);
	};

	const handleValuesChange = (changedValues: any) => {
		setIsChanged(true);
		var item: Center = center;
		if (changedValues['name']) item = { ...center, name: changedValues['name'] };
		if (changedValues['description']) item = { ...center, description: changedValues['description'] };
		if (changedValues['externalCode']) item = { ...center, externalCode: changedValues['externalCode'] };
		if (changedValues['centerTypeId']) item = { ...center, centerTypeId: changedValues['centerTypeId'] };
		if (changedValues['isVisible'] !== undefined) item = { ...center, isVisible: changedValues['isVisible'] };
		setCenter(item);
		setCenterData(item);
		setTimeout(() => {
			formCenterDetails
				.validateFields()
				.then(() => {
					setHasErrors(false);
				})
				.catch(errors => {
					if (errors.errorFields.length > 0) setHasErrors(true);
				});
		});
	};
	if (disabled)
		return (
			<div>
				<Col>
					<Row>
						<Col span={span}>
							<Card size="small" title={center.name} style={styles.card}>
								<Descriptions title={t('general.details')} column={2} bordered>
									<Descriptions.Item label={t('network.name')}>{center.name}</Descriptions.Item>
									<Descriptions.Item label={t('network.description')}>{center.description ? center.description : ' '}</Descriptions.Item>
									<Descriptions.Item label={t('network.internalCode')}>{center.internalCode.toUpperCase()}</Descriptions.Item>
									<Descriptions.Item label={t('network.externalCode')}>{center.externalCode}</Descriptions.Item>
									<Descriptions.Item label={t('network.centerType')}>{centerTypes.find(ct => ct.id === center.centerTypeId)?.description}</Descriptions.Item>
								</Descriptions>
								<br />
								<br />
								<Descriptions title={t('view.address')} column={2} bordered>
									<Descriptions.Item label={t('general.street')}>{address.street}</Descriptions.Item>
									<Descriptions.Item label={t('general.number')}>{address.number ? address.number : ' '}</Descriptions.Item>
									<Descriptions.Item label={t('general.other')}>{address.other ? address.other : ' '}</Descriptions.Item>
									<Descriptions.Item label={t('general.commune')}>{communes.find(c => c.id === address.communeId)?.description}</Descriptions.Item>
									<Descriptions.Item label={t('general.province')}>
										{provinces.find(ct => ct.id === communes.find(c => c.id === address.communeId).id)?.description}
									</Descriptions.Item>
									<Descriptions.Item label={t('general.region')}>
										{regions.find(ct => ct.id === provinces.find(p => p.id === communes.find(c => c.id === address.communeId).id).id)?.description}
									</Descriptions.Item>
									<Descriptions.Item label={t('general.country')}>{countries.find(ct => ct.id === address.countryId)?.description}</Descriptions.Item>
								</Descriptions>
								<br />
								<br />
								<Descriptions title={t('view.review')} bordered>
									<Descriptions.Item>{center.details ? <Markup content={center.details}></Markup> : <Empty />}</Descriptions.Item>
								</Descriptions>
							</Card>
						</Col>
					</Row>
				</Col>
			</div>
		);
	else
		return (
			<div>
				<Col>
					<Row>
						<Col span={span}>
							<Card size="small" title={t('view.details')} style={styles.card}>
								<Form {...baseStyles.formLayout} form={formCenterDetails} name="center-details" onValuesChange={handleValuesChange}>
									<Form.Item label={t('network.name')} name="name" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<Input />
									</Form.Item>
									<Form.Item label={t('network.description')} name="description" rules={[{ required: false }]}>
										<TextArea rows={4} autoSize />
									</Form.Item>
									<Form.Item label={t('network.internalCode')} name="internalCode" rules={[{ required: false }]}>
										<Input />
									</Form.Item>
									<Form.Item label={t('network.externalCode')} name="externalCode" rules={[{ required: false }]}>
										<Input />
									</Form.Item>
									<Form.Item label={t('network.centerType')} name="centerTypeId" rules={[{ required: true }]}>
										<Select>
											{centerTypes.map(ct => {
												return (
													<Option key={`centerType-${ct.id}`} value={ct.id}>
														{ct.description}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
									<Form.Item label={t('network.isVisible')} name="isVisible" valuePropName="checked">
										<Switch disabled={disabled} />
									</Form.Item>
								</Form>
							</Card>
						</Col>
						<Col span={span}>
							<Card size="small" title={t('view.review')} style={{ ...styles.card, height: 350 }}>
								<Editor
									readOnly={disabled}
									editorState={editorState}
									toolbarClassName="toolbarClassName"
									wrapperClassName="wrapperClassName"
									editorClassName="editorClassName"
									onEditorStateChange={handleDetailsChange}
								/>
							</Card>
						</Col>
						<Col span={span}>
							<Card size="small" title={t('view.address')} style={styles.card}>
								<FormCenterAddressEdit
									addressId={center.addressId}
									centerId={center.id}
									disabled={disabled}
									setAddressData={setAddressData}
									setIsChanged={setIsChanged}
								/>
							</Card>
						</Col>
					</Row>
				</Col>
			</div>
		);
};
export default FormCenterDetails;
