import { Row, Col } from 'antd';
import { FormProfessionalEmails, FormProfessionalPhones, FormProfessionalAddresses } from '../../components';

const FormProfessionalContactData = ({ professionalId }) => {
	const id = professionalId;

	return (
		<div>
			<Row gutter={[16, 16]}>
				<Col className="gutter-row" lg={24} xl={24} xxl={12}>
					<FormProfessionalAddresses professionalId={id} />
				</Col>
				<Col className="gutter-row" lg={24} xl={24} xxl={12}>
					<FormProfessionalPhones professionalId={id} />
				</Col>
				<Col className="gutter-row" lg={24} xl={24} xxl={12}>
					<FormProfessionalEmails professionalId={id} />
				</Col>
			</Row>
		</div>
	);
};

export default FormProfessionalContactData;
