import { useEffect, useState } from 'react';
import { Input, Form } from 'antd';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { initialServiceValue, Service } from '../../store/types';
import { servicesSelectors } from '../../store/selectors';
import { useStore } from 'react-redux';

const FormServiceEdit = (props: { serviceId: number; setServiceData: (data: Service) => void }) => {
	const { t } = useTranslation();
	const store = useStore();
	const state = store.getState() as any;
	const [formServiceEdit] = Form.useForm();
	const [service, setService] = useState<Service>(initialServiceValue);

	useEffect(() => {
		var item = servicesSelectors.selectById(state, props.serviceId) || initialServiceValue;
		formServiceEdit.setFieldsValue({
			description: item.description,
			internalCode: item.internalCode,
			externalCode: item.externalCode,
		});
	}, [formServiceEdit, state, props]);

	const handleValuesChange = (changedValues: any) => {
		if (changedValues['description']) {
			setService({ ...service, description: changedValues['description'] });
			props.setServiceData({ ...service, description: changedValues['description'] });
		} else if (changedValues['internalCode']) {
			setService({ ...service, internalCode: changedValues['internalCode'] });
			props.setServiceData({ ...service, internalCode: changedValues['internalCode'] });
		} else if (changedValues['externalCode']) {
			setService({ ...service, externalCode: changedValues['externalCode'] });
			props.setServiceData({ ...service, externalCode: changedValues['externalCode'] });
		}
	};

	return (
		<Form {...baseStyles.formLayout} form={formServiceEdit} onValuesChange={handleValuesChange} name="service-details">
			<Form.Item label={t('network.description')} name="description" rules={[{ required: true }]}>
				<Input />
			</Form.Item>
			<Form.Item label={t('network.internalCode')} name="internalCode" rules={[{ required: false }]}>
				<Input />
			</Form.Item>
			<Form.Item label={t('network.externalCode')} name="externalCode" rules={[{ required: false }]}>
				<Input />
			</Form.Item>
		</Form>
	);
};
export default FormServiceEdit;
