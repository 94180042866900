import { useEffect } from 'react';
import { useStore } from 'react-redux';
import PageProfessionalsPhysicians from './page-professionals-physicians';

const PageHome = () => {
	const store = useStore();

	useEffect(() => {}, [store]);

	return <PageProfessionalsPhysicians />;
};

export default PageHome;
