import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { DocumentAttachment, ImageAttachment } from '../types';

export const documentAttachmentAdapter = createEntityAdapter({
	selectId: (item: DocumentAttachment) => item.id,
});

export const documentAttachments = createSlice({
	name: 'documentAttachments',
	initialState: documentAttachmentAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		attachmentAdded: documentAttachmentAdapter.addOne,
		attachmentLoading: state => {
			if (!state.loading) state.loading = true;
		},
		attachmentReceived: (state, action) => {
			if (state.loading) {
				documentAttachmentAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		attachmentCreateUpdate: documentAttachmentAdapter.upsertOne,
	},
});

export const imageAttachmentAdapter = createEntityAdapter({
	selectId: (item: ImageAttachment) => item.id,
});

export const imageAttachments = createSlice({
	name: 'imageAttachments',
	initialState: imageAttachmentAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		imageAttachmentAdded: imageAttachmentAdapter.addOne,
		imageAttachmentLoading: state => {
			if (!state.loading) state.loading = true;
		},
		imageAttachmentReceived: (state, action) => {
			if (state.loading) {
				imageAttachmentAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		imageAttachmentCreateUpdate: imageAttachmentAdapter.upsertOne,
	},
});
