import dayjs from 'dayjs';

export interface Activity {
	[key: string]: any;
	id: number;
	activityNumber: string;
	activityTypeId: number;
	activityDate: string;
	priorityId: number;
	objectives: string;
	description: string;
	originId: number;
	userIds: number[];
	tasksIds: number[];
	agreementIds: number[];
	commentIds: number[];
	isDeleted: boolean;
}

export const initialActivityValue: Activity = {
	id: 0,
	activityNumber: '',
	activityTypeId: 0,
	activityDate: dayjs().format('YYYY/MM/DD'),
	priorityId: 0,
	objectives: '',
	description: '',
	originId: 0,
	userIds: [],
	tasksIds: [],
	agreementIds: [],
	commentIds: [],
	isDeleted: false,
};

export interface Case {
	[key: string]: any;
	id: number;
	patientId: number;
	contactId: number;
	caseNumber: string;
	caseTypeId: number;
	priorityId: number;
	reason: string;
	subject: string;
	description: string;
	originId: number;
	participantsId: number[];
	tasksIds: number[];
	incidentsId: number[];
	solutionsId: number[];
	commentIds: number[];
	isClosed: boolean;
	closedDate: string;
	creationDate: string;
	isDeleted: boolean;
}

export const initialCaseValue: Case = {
	id: 0,
	patientId: 0,
	contactId: 0,
	caseNumber: '',
	caseTypeId: 0,
	priorityId: 0,
	reason: '',
	subject: '',
	description: '',
	originId: 0,
	participantsId: [],
	tasksIds: [],
	incidentsId: [],
	solutionsId: [],
	commentIds: [],
	isClosed: false,
	closedDate: dayjs().format('YYYY/MM/DD'),
	creationDate: dayjs().format('YYYY/MM/DD'),
	isDeleted: false,
};

export interface Person {
	[key: string]: any;
	id: number;
	avatar: string;
	names: string;
	lastName: string;
	secondLastName: string;
	birthDate: string;
	genderId: number;
	socialId: string;
	nationalityId?: number;
	isDeleted: boolean;
}

export const initialPersonValue: Person = {
	id: 0,
	avatar: '',
	names: '',
	lastName: '',
	secondLastName: '',
	birthDate: dayjs().format('YYYY/MM/DD'),
	genderId: 1,
	socialId: '',
	isDeleted: true,
};

export interface Task {
	id: number;
	description: string;
	completionDate: string;
	expirationDate: string;
	taskOwnerId: number;
	taskStatusId: number;
	taskTypeId: number;
	participantIds: number[];
	documentAttachmentIds: number[];
	commentIds: number[];
	creationDate: string;
	isDeleted: boolean;
}

export const initialTaskValue: Task = {
	id: 0,
	description: '',
	completionDate: dayjs().format('YYYY/MM/DD'),
	expirationDate: dayjs().format('YYYY/MM/DD'),
	taskOwnerId: 0,
	taskStatusId: 0,
	taskTypeId: 0,
	participantIds: [],
	documentAttachmentIds: [],
	commentIds: [],
	creationDate: dayjs().format('YYYY/MM/DD'),
	isDeleted: false,
};

export interface Agreement {
	[key: string]: any;
	id: number;
	activityId: number;
	description: string;
	dateOfCreation: string;
	dateOfModification: string;
	createdBy: string;
	modifiedBy: string;
	isDeleted: boolean;
}

export const initialAgreementValue: Agreement = {
	id: 0,
	activityId: 0,
	description: '',
	dateOfCreation: dayjs().format('YYYY/MM/DD'),
	dateOfModification: dayjs().format('YYYY/MM/DD'),
	createdBy: '',
	modifiedBy: '',
	isDeleted: false,
};

export interface Comment {
	[key: string]: any;
	id: number;
	description: string;
	activityId: number;
	caseId: number;
	taskId: number;
	dateOfCreation: string;
	dateOfModification: string;
	createdBy: string;
	modifiedBy: string;
	isDeleted: boolean;
}

export const initialCommentValue: Comment = {
	id: 0,
	activityId: 0,
	taskId: 0,
	caseId: 0,
	description: '',
	dateOfCreation: dayjs().format('YYYY/MM/DD'),
	dateOfModification: dayjs().format('YYYY/MM/DD'),
	createdBy: '',
	modifiedBy: '',
	isDeleted: false,
};

export interface TaskStatus {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface TaskType {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface ActivityType {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}

export interface Priority {
	id: number;
	code: string;
	description: string;
	isDeleted: boolean;
}
