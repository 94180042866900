import { ReactNode, useState } from 'react';
import {
	Specialty,
	Accomplishment,
	ProfessionalDocument,
	AccreditationDocument,
	Institution,
	SpecialtyType,
	ProfessionalDocumentType,
	AccreditationDocumentType,
	Accreditation,
	AccreditationStatus,
} from '../../store/types';
import { useStore } from 'react-redux';
import { Card, Timeline, Typography } from 'antd';
import {
	accomplishmentSelectors,
	accreditationDocumentsSelectors,
	professionalDocumentsSelectors,
	specialtySelectors,
	professionalDocumentTypeSelectors,
	accreditationDocumentTypeSelectors,
	accreditationsSelectors,
	accreditationStatusesSelectors,
} from '../../store/selectors';
import { useEffect } from 'react';
import { dateFormat } from '../../assets/formats';
import { institutionsSelectors, specialtyTypesSelectors } from '../../store/selectors';
import dayjs from 'dayjs';
import { AimOutlined, CheckOutlined, FileOutlined, LoginOutlined, LogoutOutlined, StopOutlined } from '@ant-design/icons';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

/**
 * Form to display a table of specialties
 */
const FormProfessionalTimeline = (props: { professionalId: number; professionalType: number }) => {
	const store = useStore();
	const state = store.getState() as any;
	const styles = baseStyles;
	const id = props.professionalId;
	const { t } = useTranslation();
	const [milestones, setMilestones] = useState<{ dateTime: Date; description: string; icon: ReactNode; color: string }[]>([]);
	const [specialties] = useState<Specialty[]>(specialtySelectors.selectAll(state).filter(s => s.professionalId === id));
	const [institutions] = useState<Institution[]>(institutionsSelectors.selectAll(state));
	const [specialtyTypes] = useState<SpecialtyType[]>(specialtyTypesSelectors.selectAll(state));
	const [professionalDocumentTypes] = useState<ProfessionalDocumentType[]>(professionalDocumentTypeSelectors.selectAll(state));
	const [accreditations] = useState<Accreditation[]>(accreditationsSelectors.selectAll(state).filter(s => s.professionalId === id));
	const [accreditationStatuses] = useState<AccreditationStatus[]>(accreditationStatusesSelectors.selectAll(state));
	const [accreditationDocumentTypes] = useState<AccreditationDocumentType[]>(accreditationDocumentTypeSelectors.selectAll(state));
	const [accomplishments] = useState<Accomplishment[]>(accomplishmentSelectors.selectAll(state).filter(a => a.professionalId === id));
	const [professionalDocuments] = useState<ProfessionalDocument[]>(professionalDocumentsSelectors.selectAll(state).filter(a => a.professionalId === id));
	const [accreditationDocuments] = useState<AccreditationDocument[]>(accreditationDocumentsSelectors.selectAll(state).filter(a => a.professionalId === id));

	useEffect(() => {
		var temp: { dateTime: Date; description: string; icon: ReactNode; color: string }[] = [];
		temp.push({
			dateTime: new Date(),
			description: `Hoy`,
			icon: <AimOutlined />,
			color: 'gray',
		});
		specialties.forEach(s => {
			temp.push({
				dateTime: new Date(s.startDate),
				description: `Inicio de ${specialtyTypes.find(sp => sp.id === s.specialtyTypeId)?.description} en ${
					institutions.find(i => i.id === s.institutionId)?.description
				}`,
				icon: <LoginOutlined />,
				color: 'blue',
			});
			temp.push({
				dateTime: new Date(s.endDate),
				description: `Fin de ${specialtyTypes.find(sp => sp.id === s.specialtyTypeId)?.description} en ${
					institutions.find(i => i.id === s.institutionId)?.description
				}`,
				icon: <LogoutOutlined />,
				color: 'blue',
			});
		});
		accomplishments.forEach(a => {
			temp.push({
				dateTime: new Date(a.startDate),
				description: `Inicio de ${a.description} en ${institutions.find(i => i.id === a.institutionId)?.description}`,
				icon: <LoginOutlined />,
				color: 'blue',
			});
			temp.push({
				dateTime: new Date(a.endDate),
				description: `Fin de ${a.description} en ${institutions.find(i => i.id === a.institutionId)?.description}`,
				icon: <LogoutOutlined />,
				color: 'blue',
			});
		});
		professionalDocuments.forEach(pd => {
			temp.push({
				dateTime: new Date(pd.documentDate),
				description: `${professionalDocumentTypes.find(d => d.id === pd.documentTypeId)?.description}`,
				icon: <FileOutlined />,
				color: 'green',
			});
		});
		accreditationDocuments.forEach(ad => {
			temp.push({
				dateTime: new Date(ad.documentDate),
				description: `${accreditationDocumentTypes.find(d => d.id === ad.documentTypeId)?.description}`,
				icon: <FileOutlined />,
				color: 'green',
			});
		});
		accreditations.forEach(a => {
			temp.push({
				dateTime: new Date(a.startDate),
				description: `Inicio de ${accreditationStatuses.find(as => as.id === a.accreditationStatusId)?.description}`,
				icon: <CheckOutlined />,
				color: 'green',
			});
			temp.push({
				dateTime: new Date(a.endDate),
				description: `Fin de ${accreditationStatuses.find(as => as.id === a.accreditationStatusId)?.description}`,
				icon: <StopOutlined />,
				color: 'red',
			});
		});

		temp.sort(
			(
				a: {
					dateTime: Date;
					description: string;
				},
				b: {
					dateTime: Date;
					description: string;
				},
			) => {
				return dayjs(a.dateTime).diff(b.dateTime);
			},
		);
		setMilestones(temp);
	}, [
		specialties,
		accomplishments,
		professionalDocuments,
		accreditationDocumentTypes,
		professionalDocumentTypes,
		institutions,
		specialtyTypes,
		accreditationDocuments,
		accreditations,
		accreditationStatuses,
	]);

	return (
		<Card size="small" title={t('professionals.timeline')} style={{ ...styles.card, width: '95%' }}>
			<Timeline
				mode="alternate"
				items={milestones.map((m, index: number) => {
					return {
						dot: m.icon,
						color: m.color,
						children: m.description,
						label: (
							<Text style={{ fontWeight: 'bold' }}>{m.dateTime.toLocaleString() === '1/1/1, 12:00:00 AM' ? '' : dayjs(m.dateTime).format(dateFormat)}</Text>
						),
					};
				})}
			/>
		</Card>
	);
};
export default FormProfessionalTimeline;
