import { Professional, Accreditation, AccreditationDocument, Email, Phone, Address } from '../types';
import { urls } from './urls';
import {
	Accomplishment,
	Privilege,
	Specialty,
	ProfessionalDocument,
	Category,
	AccreditationComment,
	ProfessionalMilestone,
	initialProfessionalValue,
} from '../../store/types/professional';
import dayjs from 'dayjs';

export const professionalExists = async (socialId: string, id: number): Promise<boolean> => {
	var exists = false;
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.externalId,
		},
	};
	var response = await fetch(urls.professionalExists + '?socialId=' + socialId + '&id=' + id, requestOptions);
	var data = await response.text();
	if (data === 'true') exists = true;
	return exists;
};

export const fetchProfessional = async (id: number): Promise<Professional> => {
	if (id === 0) return initialProfessionalValue;
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.externalId,
		},
	};
	var response = await fetch(urls.professional + '?professionalId=' + id, requestOptions);
	var data = await response.json();
	return parseProfessional(data);
};

export const fetchActiveProfessionals = async (): Promise<any> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.externalId,
		},
	};
	var response = await fetch(urls.activeProfessionals, requestOptions);
	var data = await response.json();
	return parseProfessionals(data);
};

export const fetchProfessionals = async (): Promise<any> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.externalId,
		},
	};
	var response = await fetch(urls.professionals, requestOptions);
	var data = await response.json();
	return parseProfessionals(data);
};

export const parseProfessionals = (data: any) => {
	var result = {
		professionals: [] as Professional[],
		accreditations: [] as Accreditation[],
		specialties: [] as Specialty[],
	};
	var i,
		j = 0;
	var jsonObject: any = data;
	for (i = 0; i < jsonObject.length; i++) {
		var professionalId = jsonObject[i].id;
		if (jsonObject[i].specialties !== undefined)
			for (j = 0; j < jsonObject[i].specialties.length; j++) {
				var specialty = parseSpecialty(jsonObject[i].specialties[j], professionalId);
				result.specialties.push(specialty);
			}
		if (jsonObject[i].accreditations !== undefined)
			for (j = 0; j < jsonObject[i].accreditations.length; j++) {
				var accreditation = parseAccreditation(jsonObject[i].accreditations[j], professionalId);
				result.accreditations.push(accreditation);
			}
		var p = parseProfessional(jsonObject[i]);
		result.professionals.push(p);
	}
	return result;
};

export const fetchEmails = async (): Promise<Email[]> => {
	var result: Email[] = [] as Email[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.emails, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseEmail(jsonObject[i], jsonObject[i].professionalId, jsonObject[i].personId);
		result.push(temp);
	}
	return result;
};

export const fetchProfessionalEmailsById = async (professionalId: number): Promise<Email[]> => {
	var result: Email[] = [] as Email[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.professionalEmailsById + '?id=' + professionalId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseEmail(jsonObject[i], jsonObject[i].professionalId, jsonObject[i].personId);
		result.push(temp);
	}
	return result;
};

export const fetchPersonEmailsById = async (personId: number): Promise<Email[]> => {
	var result: Email[] = [] as Email[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.personEmailsById + '?id=' + personId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseEmail(jsonObject[i], jsonObject[i].professionalId, jsonObject[i].personId);
		result.push(temp);
	}
	return result;
};

export const fetchPhones = async (): Promise<Phone[]> => {
	var result: Phone[] = [] as Phone[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.phones, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parsePhone(jsonObject[i], jsonObject[i].professionalId, jsonObject[i].personId);
		result.push(temp);
	}
	return result;
};

export const fetchProfessionalPhonesById = async (professionalId: number): Promise<Phone[]> => {
	var result: Phone[] = [] as Phone[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.professionalPhonesById + '?id=' + professionalId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parsePhone(jsonObject[i], jsonObject[i].professionalId, jsonObject[i].personId);
		result.push(temp);
	}
	return result;
};

export const fetchPersonPhonesById = async (personId: number): Promise<Phone[]> => {
	var result: Phone[] = [] as Phone[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.personPhonesById + '?id=' + personId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parsePhone(jsonObject[i], jsonObject[i].professionalId, jsonObject[i].personId);
		result.push(temp);
	}
	return result;
};

export const fetchAddresses = async (): Promise<Address[]> => {
	var result: Address[] = [] as Address[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.addresses, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseAddress(jsonObject[i], jsonObject[i].professionalId, jsonObject[i].personId);
		result.push(temp);
	}
	return result;
};

export const fetchProfessionalAddressesById = async (professionalId: number): Promise<Address[]> => {
	var result: Address[] = [] as Address[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.professionalAddressesById + '?id=' + professionalId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseAddress(jsonObject[i], jsonObject[i].professionalId, jsonObject[i].personId);
		result.push(temp);
	}
	return result;
};

export const fetchPersonAddressesById = async (personId: number): Promise<Address[]> => {
	var result: Address[] = [] as Address[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.personAddressesById + '?id=' + personId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseAddress(jsonObject[i], jsonObject[i].professionalId, jsonObject[i].personId);
		result.push(temp);
	}
	return result;
};

export const fetchCategories = async (): Promise<Category[]> => {
	var result: Category[] = [] as Category[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.categories, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseCategory(jsonObject[i], jsonObject[i].professionalId);
		result.push(temp);
	}
	return result;
};

export const fetchCategoriesById = async (professionalId: number): Promise<Category[]> => {
	var result: Category[] = [] as Category[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.categoriesById + '?id=' + professionalId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseCategory(jsonObject[i], professionalId);
		result.push(temp);
	}
	return result;
};

export const fetchAcomplishments = async (): Promise<Accomplishment[]> => {
	var result: Accomplishment[] = [] as Accomplishment[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.accomplishments, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseAccomplishment(jsonObject[i], jsonObject[i].professionalId);
		result.push(temp);
	}
	return result;
};

export const fetchAcomplishmentsById = async (professionalId: number): Promise<Accomplishment[]> => {
	var result: Accomplishment[] = [] as Accomplishment[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.accomplishmentsById + '?id=' + professionalId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseAccomplishment(jsonObject[i], professionalId);
		result.push(temp);
	}
	return result;
};

export const fetchAccreditations = async (): Promise<Accreditation[]> => {
	var result: Accreditation[] = [] as Accreditation[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.accreditations, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseAccreditation(jsonObject[i], jsonObject[i].professionalId);
		result.push(temp);
	}
	return result;
};

export const fetchAccreditationsById = async (professionalId: number): Promise<Accreditation[]> => {
	var result: Accreditation[] = [] as Accreditation[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.accreditationsById + '?id=' + professionalId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseAccreditation(jsonObject[i], jsonObject[i].professionalId);
		result.push(temp);
	}
	return result;
};

export const fetchAccreditacionComments = async (): Promise<AccreditationComment[]> => {
	var result: AccreditationComment[] = [] as AccreditationComment[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.accreditationComments, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseAccreditationComment(jsonObject[i], jsonObject[i].professionalId);
		result.push(temp);
	}
	return result;
};

export const fetchAccreditacionCommentsById = async (professionalId: number): Promise<AccreditationComment[]> => {
	var result: AccreditationComment[] = [] as AccreditationComment[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.accreditationCommentsById + '?id=' + professionalId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseAccreditationComment(jsonObject[i], jsonObject[i].professionalId);
		result.push(temp);
	}
	return result;
};

export const fetchAccreditacionDocuments = async (): Promise<AccreditationDocument[]> => {
	var result: AccreditationDocument[] = [] as AccreditationDocument[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.accreditationDocuments, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseAccreditationDocument(jsonObject[i], jsonObject[i].professionalId);
		result.push(temp);
	}
	return result;
};

export const fetchAccreditacionDocumentsById = async (professionalId: number): Promise<AccreditationDocument[]> => {
	var result: AccreditationDocument[] = [] as AccreditationDocument[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.accreditationDocumentsById + '?id=' + professionalId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseAccreditationDocument(jsonObject[i], professionalId);
		result.push(temp);
	}
	return result;
};

export const fetchProfessionalDocumentsById = async (professionalId: number): Promise<ProfessionalDocument[]> => {
	var result: ProfessionalDocument[] = [] as ProfessionalDocument[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.professionalDocumentsById + '?id=' + professionalId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseProfessionalDocument(jsonObject[i], professionalId);
		result.push(temp);
	}
	return result;
};

export const fetchProfessionalMilestones = async (): Promise<ProfessionalMilestone[]> => {
	var result: ProfessionalMilestone[] = [] as ProfessionalMilestone[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.professionalMilestones, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseProfessionalMilestone(jsonObject[i], jsonObject[i].professionalId);
		result.push(temp);
	}
	return result;
};

export const fetchProfessionalMilestonesById = async (professionalId: number): Promise<ProfessionalMilestone[]> => {
	var result: ProfessionalMilestone[] = [] as ProfessionalMilestone[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.professionalMilestonesById + '?id=' + professionalId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseProfessionalMilestone(jsonObject[i], professionalId);
		result.push(temp);
	}
	return result;
};

export const fetchSpecialties = async (): Promise<Specialty[]> => {
	var result: Specialty[] = [] as Specialty[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.specialties, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parseSpecialty(jsonObject[i], jsonObject[i].professionalId);
		result.push(temp);
	}
	return result;
};

export const fetchPrivileges = async (): Promise<Privilege[]> => {
	var result: Privilege[] = [] as Privilege[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.privileges, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parsePrivilege(jsonObject[i], jsonObject[i].professionalId);
		result.push(temp);
	}
	return result;
};

export const fetchPrivilegesById = async (professionalId: number): Promise<Privilege[]> => {
	var result: Privilege[] = [] as Privilege[];
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: globalThis.user?.username,
		},
	};
	var response = await fetch(urls.privilegesById + '?id=' + professionalId, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var temp = parsePrivilege(jsonObject[i], professionalId);
		result.push(temp);
	}
	return result;
};

//#region Parsers
export const parseProfessional = (data: any): Professional => {
	var p: Professional = {
		id: data.id,
		avatar: data.avatar,
		names: data.names,
		genderId: data.gender === null ? 1 : data.gender.id,
		lastName: data.lastName,
		secondLastName: data.secondLastName,
		professionalTypeId: data.professionalType === null ? 1 : data.professionalType.id,
		nationalityId: data.nationality === null ? 1 : data.nationality.id,
		hours: data.hours,
		birthDate: data.birthDate,
		sisNumber: data.sisNumber,
		eunacomResult: data.eunacomResult ? data.eunacomResult : '',
		relationTypeId: data.relationType === null ? 1 : data.relationType.id,
		centers: [] as number[],
		socialId: data.socialId,
		categoryId: data.category ? data.category.id : 1,
		isDeleted: data.isDeleted,
		isActive: data.isActive,
		review: data.review,
		createdBy: data.createdBy ? data.createdBy : '',
		modifiedBy: data.modifiedBy ? data.modifiedBy : '',
	};
	for (var i = 0; i < data.centers.length; i++) {
		p.centers.push(data.centers[i].id);
	}
	return p;
};

export const parseAccomplishment = (data: any, professionalId: number) => {
	var a: Accomplishment = {
		id: data.id,
		professionalId: professionalId,
		description: data.description,
		details: data.details,
		startDate: data.startDate.toString(),
		endDate: data.endDate.toString(),
		institutionId: data.institution ? data.institution.id : 1,
		isDeleted: data.isDeleted,
		createdBy: data.createdBy ? data.createdBy : '',
		modifiedBy: data.modifiedBy ? data.modifiedBy : '',
	};
	return a;
};

export const parseSpecialty = (data: any, professionalId: number) => {
	var s: Specialty = {
		id: data.id,
		professionalId: professionalId,
		description: data.description,
		specialtyTypeId: data.specialtyType !== null ? data.specialtyType.id : 1,
		details: data.details,
		startDate: data.startDate,
		isActive: data.isActive,
		endDate: data.endDate,
		institutionId: data.institution ? data.institution.id : 0,
		isDeleted: data.isDeleted,
		createdBy: data.createdBy ? data.createdBy : '',
		modifiedBy: data.modifiedBy ? data.modifiedBy : '',
	};
	return s;
};

export const parseCategory = (data: any, professionalId: number) => {
	var category: Category = {
		id: data.id,
		categoryTypeId: data.categoryType !== null ? data.categoryType.id : 1,
		comments: data.comments,
		startDate: dayjs.utc(data.startDate).tz('America/Santiago').toDate().toISOString(),
		endDate: dayjs.utc(data.endDate).tz('America/Santiago').toDate().toISOString(),
		professionalId: professionalId,
		isDeleted: data.isDeleted,
		createdBy: data.createdBy ? data.createdBy : '',
		modifiedBy: data.modifiedBy ? data.modifiedBy : '',
	};
	return category;
};

export const parsePrivilege = (data: any, professionalId: number) => {
	var privilige: Privilege = {
		id: data.id,
		privilegeTypeId: data.privilegeType !== null ? data.privilegeType.id : 1,
		privilegeStatusId: data.privilegeStatus !== null ? data.privilegeStatus.id : 1,
		comments: data.comments,
		privilegeDateTime: dayjs.utc(data.dateOfModification).tz('America/Santiago').toDate().toISOString(),
		professionalId: professionalId,
		isDeleted: data.isDeleted,
		realHours: data.realHours,
		requestedHours: data.requestedHours,
		createdBy: data.createdBy ? data.createdBy : '',
		modifiedBy: data.modifiedBy ? data.modifiedBy : '',
	};
	return privilige;
};

export const parseAccreditationComment = (data: any, professionalId: number) => {
	var accreditationComment: AccreditationComment = {
		id: data.id,
		professionalId: professionalId,
		externalCode: data.externalCode,
		comment: data.comment,
		commentDateTime: dayjs.utc(data.commentDateTime).tz('America/Santiago').toDate().toISOString(),
		isPrivate: data.isPrivate,
		isDeleted: data.isDeleted,
		createdBy: data.createdBy ? data.createdBy : '',
		modifiedBy: data.modifiedBy ? data.modifiedBy : '',
	};
	return accreditationComment;
};

export const parseAccreditationDocument = (data: any, professionalId: number) => {
	var accreditationDocument: AccreditationDocument = {
		id: data.id,
		documentTypeId: data.accreditationDocumentType !== null ? data.accreditationDocumentType.id : 1,
		documentStatusId: data.accreditationDocumentStatus !== null ? data.accreditationDocumentStatus.id : 1,
		documentDate: dayjs(data.documentDate).toDate().toISOString(),
		documentUploadDateTime: dayjs.utc(data.documentUploadDateTime).tz('America/Santiago').toDate().toISOString(),
		documentExpirationDate: data.documentExpirationDate ? dayjs(data.documentExpirationDate).toDate().toISOString() : undefined,
		url: data.url,
		professionalId: professionalId,
		isDeleted: data.isDeleted,
		createdBy: data.createdBy ? data.createdBy : '',
		modifiedBy: data.modifiedBy ? data.modifiedBy : '',
	};
	return accreditationDocument;
};

export const parseProfessionalDocument = (data: any, professionalId: number) => {
	var professionalDocument: ProfessionalDocument = {
		id: data.id,
		details: data.details || '',
		documentTypeId: data.professionalDocumentType !== null ? data.professionalDocumentType.id : 1,
		documentDate: dayjs.utc(data.documentDate).tz('America/Santiago').toDate().toISOString(),
		documentUploadDateTime: dayjs.utc(data.documentUploadDateTime).tz('America/Santiago').toDate().toISOString(),
		url: data.url || '',
		professionalId: professionalId,
		isPrivate: data.isPrivate,
		isDeleted: data.isDeleted,
		createdBy: data.createdBy ? data.createdBy : '',
		modifiedBy: data.modifiedBy ? data.modifiedBy : '',
	};
	return professionalDocument;
};

export const parseEmail = (data: any, professionalId: number, personId: number) => {
	var email: Email = {
		id: data.id,
		url: data.url ? data.url : '',
		emailTypeId: data.emailType !== null ? data.emailType.id : 1,
		professionalId: professionalId,
		personId: personId,
		isDeleted: data.isDeleted,
		createdBy: data.createdBy ? data.createdBy : '',
		modifiedBy: data.modifiedBy ? data.modifiedBy : '',
	};
	return email;
};

export const parsePhone = (data: any, professionalId: number, personId: number) => {
	var phone: Phone = {
		id: data.id,
		phoneNumber: data.phoneNumber,
		phoneNumberTypeId: data.phoneNumberType ? data.phoneNumberType.id : 1,
		professionalId: professionalId,
		personId: personId,
		isDeleted: data.isDeleted,
		createdBy: data.createdBy ? data.createdBy : '',
		modifiedBy: data.modifiedBy ? data.modifiedBy : '',
	};
	return phone;
};

export const parseAccreditation = (data: any, professionalId: number) => {
	var accreditation: Accreditation = {
		id: data.id,
		accreditationStatusId: data.accreditationStatus !== null ? data.accreditationStatus.id : 1,
		startDate: dayjs.utc(data.startDate).tz('America/Santiago').toDate().toISOString(),
		endDate: dayjs.utc(data.endDate).tz('America/Santiago').toDate().toISOString(),
		details: data.details,
		professionalId: professionalId,
		isDeleted: data.isDeleted,
		createdBy: data.createdBy ? data.createdBy : '',
		modifiedBy: data.modifiedBy ? data.modifiedBy : '',
	};
	return accreditation;
};

export const parseProfessionalMilestone = (data: any, professionalId: number) => {
	var professionalMilestone: ProfessionalMilestone = {
		id: data.id,
		professionalMilestoneTypeId: data.professionalMilestoneType !== null ? data.professionalMilestoneType.id : 1,
		startDateTime: dayjs.utc(data.startDateTime).tz('America/Santiago').toDate().toISOString(),
		endDateTime: dayjs.utc(data.endDateTime).tz('America/Santiago').toDate().toISOString(),
		comments: data.comment,
		professionalId: professionalId,
		isDeleted: data.isDeleted,
		createdBy: data.createdBy ? data.createdBy : '',
		modifiedBy: data.modifiedBy ? data.modifiedBy : '',
	};
	return professionalMilestone;
};

export const parseAddress = (data: any, ownerId: number, personId: number) => {
	var address: Address = {
		id: data.id,
		street: data.street ? data.street : '',
		other: data.other,
		number: data.number,
		latitude: data.latitude !== 0 ? data.latitude : -33.416889,
		longitude: data.longitude !== 0 ? data.longitude : -70.606705,
		addressTypeId: data.addressType ? data.addressType.id : 1,
		communeId: data.commune !== null ? data.commune.id : 1,
		countryId: data.country !== null ? data.country.id : 1,
		ownerId: ownerId,
		personId: personId,
		isDeleted: data.isDeleted,
		createdBy: data.createdBy ? data.createdBy : '',
		modifiedBy: data.modifiedBy ? data.modifiedBy : '',
	};
	return address;
};
//#endregion

//#region Create and Update Functions
export const createUpdateProfessional = async (professional: Professional): Promise<Professional | undefined> => {
	var temp: Professional;
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(professional),
	};
	const request = `${urls.createUpdateProfessional}?networkId=${globalThis.networkId}`;
	var response = await fetch(request, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		temp = parseProfessional(data);
		return temp;
	}
	return undefined;
};

export const createUpdateAccreditation = async (accreditation: Accreditation, id: number): Promise<Accreditation | undefined> => {
	var temp: Accreditation;
	var bearer = 'Bearer ' + globalThis.token;
	const accreditationRequestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(accreditation),
	};
	const accreditationRequest = `${urls.createUpdateAccreditation}`;
	var response = await fetch(accreditationRequest, accreditationRequestOptions);
	if (response.status === 200) {
		var data = await response.json();
		temp = parseAccreditation(data, id);
		return temp;
	}
	return undefined;
};

export const createUpdateAccreditationComment = async (accreditationComment: AccreditationComment, id: number): Promise<AccreditationComment | undefined> => {
	var temp: AccreditationComment;
	var bearer = 'Bearer ' + globalThis.token;
	const accreditationRequestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(accreditationComment),
	};
	const accreditationCommentRequest = `${urls.createUpdateAccreditationComment}`;
	var response = await fetch(accreditationCommentRequest, accreditationRequestOptions);
	if (response.status === 200) {
		var data = await response.json();
		temp = parseAccreditationComment(data, id);
		return temp;
	}
	return undefined;
};

export const createUpdateCategory = async (category: Category, id: number): Promise<Category | undefined> => {
	var temp: Category;
	var bearer = 'Bearer ' + globalThis.token;
	const categoryRequestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(category),
	};
	const categoryRequest = `${urls.createUpdateCategory}`;
	var response = await fetch(categoryRequest, categoryRequestOptions);
	if (response.status === 200) {
		var data = await response.json();
		temp = parseCategory(data, id);
		return temp;
	}
	return undefined;
};

export const createUpdatePrivilege = async (privilege: Privilege, id: number): Promise<Privilege | undefined> => {
	var temp: Privilege;
	var bearer = 'Bearer ' + globalThis.token;
	const privilegeRequestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(privilege),
	};
	const privilegeRequest = `${urls.createUpdatePrivilege}`;
	var response = await fetch(privilegeRequest, privilegeRequestOptions);
	if (response.status === 200) {
		var data = await response.json();
		temp = parsePrivilege(data, id);
		return temp;
	}
	return undefined;
};

export const createUpdateProfessionalMilestone = async (
	professionalMilestone: ProfessionalMilestone,
	professionalId: number,
): Promise<ProfessionalMilestone | undefined> => {
	var temp: ProfessionalMilestone;
	var bearer = 'Bearer ' + globalThis.token;
	const privilegeRequestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(professionalMilestone),
	};
	const professionalMilestoneRequest = `${urls.createUpdateProfessionalMilestone}`;
	var response = await fetch(professionalMilestoneRequest, privilegeRequestOptions);
	if (response.status === 200) {
		var data = await response.json();
		temp = parseProfessionalMilestone(data, professionalId);
		return temp;
	}
	return undefined;
};

export const createUpdateAccomplishment = async (accomplishment: Accomplishment): Promise<Accomplishment | undefined> => {
	var temp: Accomplishment;
	var bearer = 'Bearer ' + globalThis.token;
	const accomplishmentRequestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(accomplishment),
	};
	const privilegeRequest = `${urls.createUpdateAccomplishment}`;
	var response = await fetch(privilegeRequest, accomplishmentRequestOptions);
	if (response.status === 200) {
		var data = await response.json();
		temp = parseAccomplishment(data, accomplishment.professionalId);
		return temp;
	}
	return undefined;
};

export const createUpdateSpecialty = async (specialty: Specialty): Promise<Specialty | undefined> => {
	var temp: Specialty;
	var bearer = 'Bearer ' + globalThis.token;
	const specialtyRequestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(specialty),
	};
	const privilegeRequest = `${urls.createUpdateSpecialty}`;
	var response = await fetch(privilegeRequest, specialtyRequestOptions);
	if (response.status === 200) {
		var data = await response.json();
		temp = parseSpecialty(data, specialty.professionalId);
		return temp;
	}
	return undefined;
};

export const createUpdateProfessionalAddress = async (address: Address): Promise<Address | undefined> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(address),
	};
	const request = `${urls.createUpdateProfessionalAddress}`;
	var response = await fetch(request, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		var temp = parseAddress(data, address.ownerId, 0);
		return temp;
	}
	return undefined;
};

export const createUpdateEmail = async (email: Email): Promise<Email | undefined> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(email),
	};
	const request = `${urls.createUpdateEmail}?professionalId=${email.professionalId}`;
	var response = await fetch(request, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		var temp = parseEmail(data, email.professionalId, 0);
		return temp;
	}
	return undefined;
};

export const createUpdatePhone = async (phone: Phone): Promise<Phone | undefined> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
		body: JSON.stringify(phone),
	};
	const request = `${urls.createUpdatePhone}?professionalId=${phone.professionalId}`;
	var response = await fetch(request, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		var temp = parsePhone(data, phone.professionalId, 0);
		return temp;
	}
	return undefined;
};
//#endregion

//#region Delete Functions
export const deleteProfessional = async (professional: Professional): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	const response = await fetch(urls.deleteProfessional + `?professionalId=${professional.id}`, requestOptions);
	if (response.status === 200) {
		return true;
	}
	return false;
};

export const deleteAddress = async (address: Address): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	const response = await fetch(urls.deleteAddress + `?professionalId=${address.ownerId}&addressId=${address.id}`, requestOptions);
	if (response.status === 200) {
		return true;
	}
	return false;
};

export const deleteEmail = async (email: Email): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	const response = await fetch(urls.deleteEmail + `?professionalId=${email.professionalId}&emailId=${email.id}`, requestOptions);
	if (response.status === 200) {
		email = { ...email, isDeleted: true };
		return true;
	}
	return false;
};

export const deletePhone = async (phone: Phone): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	const response = await fetch(urls.deletePhone + `?professionalId=${phone.professionalId}&phoneId=${phone.id}`, requestOptions);
	if (response.status === 200) {
		return true;
	}
	return false;
};

export const deleteAccreditation = async (accreditation: Accreditation): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	const response = await fetch(
		urls.deleteAccreditation + `?professionalId=${accreditation.professionalId}&accreditationId=${accreditation.id}`,
		requestOptions,
	);
	if (response.status === 200) {
		accreditation = { ...accreditation, isDeleted: true };
		return true;
	}
	return false;
};

export const deleteAccreditationComment = async (accreditationComment: AccreditationComment): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	const response = await fetch(
		urls.deleteAccreditationComment + `?professionalId=${accreditationComment.professionalId}&accreditationCommentId=${accreditationComment.id}`,
		requestOptions,
	);
	if (response.status === 200) {
		accreditationComment = { ...accreditationComment, isDeleted: true };
		return true;
	}
	return false;
};

export const deleteCategory = async (category: Category): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	const response = await fetch(urls.deleteCategory + `?professionalId=${category.professionalId}&categoryId=${category.id}`, requestOptions);
	if (response.status === 200) {
		category = { ...category, isDeleted: true };
		return true;
	}
	return false;
};

export const deletePrivilege = async (privilege: Privilege): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	const response = await fetch(urls.deletePrivilege + `?professionalId=${privilege.professionalId}&privilegeId=${privilege.id}`, requestOptions);
	if (response.status === 200) {
		privilege = { ...privilege, isDeleted: true };
		return true;
	}
	return false;
};

export const deleteAccomplishment = async (accomplishment: Accomplishment): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	const response = await fetch(
		urls.deleteAccomplishment + `?professionalId=${accomplishment.professionalId}&accomplishmentId=${accomplishment.id}`,
		requestOptions,
	);
	if (response.status === 200) {
		accomplishment = { ...accomplishment, isDeleted: true };
		return true;
	}
	return false;
};

export const deleteSpecialty = async (specialty: Specialty): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	const response = await fetch(urls.deleteSpecialty + `?professionalId=${specialty.professionalId}&specialtyId=${specialty.id}`, requestOptions);
	if (response.status === 200) {
		specialty = { ...specialty, isDeleted: true };
		return true;
	}
	return false;
};

export const deleteAccreditationDocument = async (accreditationDocument: AccreditationDocument): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var url = urls.deleteAccreditationDocument + `?professionalId=${accreditationDocument.professionalId}&accreditationDocumentId=${accreditationDocument.id}`;
	const response = await fetch(url, requestOptions);
	if (response.status === 200) {
		accreditationDocument = { ...accreditationDocument, isDeleted: true };
		return true;
	}
	return false;
};

export const deleteProfessionalDocument = async (professionalDocument: ProfessionalDocument): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var url = urls.deleteProfessionalDocument + `?professionalId=${professionalDocument.professionalId}&professionalDocumentId=${professionalDocument.id}`;
	const response = await fetch(url, requestOptions);
	if (response.status === 200) {
		professionalDocument = { ...professionalDocument, isDeleted: true };
		return true;
	}
	return false;
};

export const deleteProfessionalMilestone = async (professionalMilestone: ProfessionalMilestone): Promise<boolean> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var url = urls.deleteProfessionalMilestone + `?professionalId=${professionalMilestone.professionalId}&professionalMilestoneId=${professionalMilestone.id}`;
	const response = await fetch(url, requestOptions);
	if (response.status === 200) {
		professionalMilestone = { ...professionalMilestone, isDeleted: true };
		return true;
	}
	return false;
};
//#endregion

//#region Upload Documents
export const uploadAccreditationDocument = async (attachment: AccreditationDocument, file: File | undefined): Promise<AccreditationDocument | undefined> => {
	const formData = new FormData();
	formData.append('ProfessionalId', attachment.professionalId.toString());
	formData.append('AccreditationDocumentTypeId', attachment.documentTypeId.toString());
	formData.append('AccreditationDocumentStatusId', attachment.documentStatusId.toString());
	formData.append('Id', attachment.id.toString());
	formData.append('DocumentDate', new Date(attachment.documentDate).toDateString() || '');
	formData.append('DocumentUploadDateTime', new Date(attachment.documentUploadDateTime).toDateString() || '');
	formData.append('DocumentExpirationDate', new Date(attachment.documentExpirationDate).toDateString() || '');
	formData.append('Url', attachment.url);
	formData.append('Modified', attachment.modified ? 'true' : 'false');
	if (file !== undefined) formData.append('File', file);
	else if (attachment.url === '' || attachment.url === undefined) return undefined;
	var temp: AccreditationDocument;
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		body: formData,
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.uploadAccreditationDocument, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		temp = parseAccreditationDocument(data, attachment.professionalId);
		return temp;
	} else return undefined;
};

export const uploadProfessionalDocument = async (attachment: ProfessionalDocument, file: File | undefined): Promise<ProfessionalDocument | undefined> => {
	const formData = new FormData();
	formData.append('ProfessionalId', attachment.professionalId.toString());
	formData.append('Details', attachment.details);
	formData.append('ProfessionalDocumentTypeId', attachment.documentTypeId.toString());
	formData.append('Id', attachment.id.toString());
	formData.append('IsPrivate', attachment.isPrivate ? 'true' : 'false');
	formData.append('DocumentDate', new Date(attachment.documentDate).toDateString() || '');
	formData.append('Modified', attachment.modified ? 'true' : 'false');
	formData.append('Url', attachment.url);
	if (file !== undefined) formData.append('File', file);
	else if (attachment.url === '' || attachment.url === undefined) return undefined;
	var temp: ProfessionalDocument;
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'POST',
		body: formData,
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var response = await fetch(urls.uploadProfessionalDocument, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		temp = parseProfessionalDocument(data, attachment.professionalId);
		return temp;
	} else return undefined;
};
//#endregion

export const getProfessionalData = async (socialId: string): Promise<any | undefined> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var url = urls.getProfessionalData + `?rut=${socialId}`;
	const response = await fetch(url, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		return data;
	}
	return undefined;
};

export const getProfessionalCertificate = async (socialId: string): Promise<any | undefined> => {
	var bearer = 'Bearer ' + globalThis.token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${globalThis.user?.username}`,
		},
	};
	var url = urls.getProfessionalCertificate + `?rut=${socialId}`;
	const response = await fetch(url, requestOptions);
	if (response.status === 200) {
		var data = await response.text();
		return data;
	}
	return undefined;
};
