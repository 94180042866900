import { useState, ReactNode, useEffect } from 'react';
import { Accomplishment, Institution, initialAccomplishmentValue } from '../../store/types';
import { accomplishments as accomplishmentsReducer } from '../../store/reducers';
import { useStore } from 'react-redux';
import { Table, Space, Tooltip, Button, Typography, message, Popconfirm, Card, Popover } from 'antd';
import { accomplishmentSelectors, institutionsSelectors } from '../../store/selectors';
import { ColumnProps } from 'antd/lib/table';
import { DeleteOutlined, EditOutlined, InfoCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import { deleteAccomplishment, fetchAcomplishmentsById } from '../../store/api';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { FormProfessionalAccomplishmentEdit } from '..';
import dayjs from 'dayjs';
import { dateFormat } from '../../assets/formats';

const { Text } = Typography;
const key = 'saving';

/**
 * Form to display a table of specialties
 */
const FormProfessionalAccomplishments = ({ professionalId, disabled = false }) => {
	const store = useStore();
	const state = store.getState() as any;
	const styles = baseStyles;
	const { t } = useTranslation();
	const [accomplishments, setAccomplishments] = useState<Accomplishment[]>([]);
	const [accomplishment, setAccomplishment] = useState<Accomplishment>({ ...initialAccomplishmentValue, professionalId: professionalId });
	const [institutions] = useState<Institution[]>(institutionsSelectors.selectAll(state).filter(i => !i.isDeleted));
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setIsLoading(true);
		fetchAcomplishmentsById(professionalId).then(items => {
			store.dispatch(accomplishmentsReducer.actions.accomplishmentLoading());
			store.dispatch(accomplishmentsReducer.actions.accomplishmentReceived(items));
			setAccomplishments(items.filter(s => !s.isDeleted));
			setIsLoading(false);
		});
	}, [professionalId, store]);

	const handleEdit = (item: Accomplishment) => {
		setModalTitle(t('professionals.editAccomplishment'));
		setAccomplishment(item);
		setIsModalVisible(true);
	};

	const handleAdd = () => {
		setModalTitle(t('professionals.addAccomplishment'));
		setAccomplishment({ ...initialAccomplishmentValue, professionalId: professionalId });
		setIsModalVisible(true);
	};

	const handleDelete = async (item: Accomplishment) => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		var response = await deleteAccomplishment(item);
		if (response) {
			setIsLoading(true);
			fetchAcomplishmentsById(professionalId).then(items => {
				store.dispatch(accomplishmentsReducer.actions.accomplishmentLoading());
				store.dispatch(accomplishmentsReducer.actions.accomplishmentReceived(items));
				setAccomplishments(items.filter(s => !s.isDeleted));
				setIsLoading(false);
				message.success({ content: t('messages.itemDeleted'), key, duration: 2 });
			});
		} else message.info({ content: t('message.unableToDelete'), key, duration: 2 });
	};

	const handleClose = () => {
		setIsModalVisible(false);
		setAccomplishments(accomplishmentSelectors.selectAll(store.getState() as any).filter(a => a.professionalId === professionalId && !a.isDeleted));
	};

	const columns: ColumnProps<Accomplishment>[] = [
		{
			dataIndex: 'modifiedBy',
			key: 'modifiedBy',
			width: 20,
			render: (_text: string, item: Accomplishment): ReactNode => (
				<Popover
					content={
						<div>
							<Text>{t('general.modifiedBy')} </Text>
							<Text type="warning">{item.modifiedBy}</Text>
						</div>
					}
				>
					<InfoCircleTwoTone />
				</Popover>
			),
		},
		{
			title: t('general.description'),
			dataIndex: 'description',
			key: 'description',
			width: '40%',
		},
		{
			title: t('professionals.institution'),
			dataIndex: 'institution',
			key: 'institution',
			width: '20%',
			render: (_text: string, item: Accomplishment): ReactNode => <Text>{institutions.find(i => i.id === item.institutionId)?.name}</Text>,
		},
		{
			title: t('professionals.endDate'),
			dataIndex: 'endDate',
			key: 'endDate',
			width: '20%',
			render: (_text: string, item: Accomplishment): ReactNode => <Text>{dayjs(item.endDate).format(dateFormat)}</Text>,
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: Accomplishment): ReactNode => {
				return disabled ? null : (
					<Space size="small">
						<Tooltip title={t('general.edit')}>
							<Button
								style={styles.button}
								type="primary"
								shape="round"
								onClick={() => {
									handleEdit(item);
								}}
								icon={<EditOutlined />}
							/>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDelete(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button danger shape="round">
									<DeleteOutlined />
								</Button>
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<Card
			size="small"
			title={t('professionals.training')}
			style={styles.card}
			extra={disabled ? null : <Button type="primary" shape="round" icon={<PlusOutlined />} style={styles.buttonCard} onClick={handleAdd}></Button>}
		>
			<Table<Accomplishment>
				key={`accomplishments-${Date.now()}`}
				rowKey={item => item.id}
				tableLayout="auto"
				size="small"
				dataSource={accomplishments}
				columns={columns}
				pagination={false}
				loading={isLoading}
			/>
			<FormProfessionalAccomplishmentEdit
				title={modalTitle}
				open={isModalVisible}
				accomplishmentId={accomplishment.id}
				professionalId={professionalId}
				onClose={handleClose}
			/>
		</Card>
	);
};
export default FormProfessionalAccomplishments;
