import { mainStore } from '../store/mainStore';
import { permissionSelectors, profileSelectors } from '../store/selectors';
import { Permission, Profile } from '../store/types';
import { urls } from '../store/api/urls';

export const validateUser = async (googleToken: string): Promise<any> => {
	var requestBody: any = {};
	requestBody.GoogleToken = googleToken;
	requestBody.TokenType = 'accessToken';
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(requestBody),
	};
	try {
		const response = await fetch(urls.validateUser, requestOptions);
		if (response.status === 200) {
			const data = await response.json();
			return data;
		}
	} catch (e) {
		return undefined;
	}
	return undefined;
};

export const isAuthorized = (code: string): boolean => {
	const state = mainStore.getState() as any;
	var all: Permission | undefined = permissionSelectors.selectAll(state).find(p => p.code === 'All');
	var permission: Permission | undefined = permissionSelectors.selectAll(state).find(p => p.code === code);
	var profiles: Profile[] | undefined = profileSelectors.selectAll(state).filter(p => globalThis.user.profileIds?.includes(p.id));
	var profilesPermissions: number[] = [] as number[];
	profiles.forEach(p => profilesPermissions.push(...p.permissionIds));
	if (permission === undefined || profilesPermissions === undefined) return false;
	return (
		globalThis.user.permissionIds?.includes(permission.id) ||
		globalThis.user.permissionIds?.includes(all?.id || 0) ||
		profilesPermissions.includes(permission.id) ||
		profilesPermissions.includes(all?.id || 0)
	);
};

export const getGoogleWebClientId = async (): Promise<string> => {
	var requestBody: any = {};
	const requestOptions: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(requestBody),
	};
	try {
		const response = await fetch(urls.googleWebClientId, requestOptions);
		if (response.status === 200) {
			const data = await response.text();
			return data;
		}
	} catch (e) {
		return '';
	}
	return '';
};
