import { useEffect, useState } from 'react';
import { initialLinkItemValue, LinkItem, ParentType, ProfessionalType } from '../../store/types';
import { Input, Form, DatePicker, Switch, Row, Col, Card, Select } from 'antd';
import { UploadDragPaste } from '..';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertHtmlToDraft, convertDraftToHtml } from '../../utils/util-drafts';
import { linkItemsSelectors, professionalTypeSelectors } from '../../store/selectors';
import { useStore } from 'react-redux';
import { objectsAreEquals } from '../../utils/util-custom-validators';
import dayjs from 'dayjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { exportDateTimeFormat } from '../../assets/formats';

const { Option } = Select;

const FormLinkItem = ({ linkId, setLinkData, setHasErrors, setChanged }) => {
	const { t } = useTranslation();
	const store = useStore();
	const state = store.getState() as any;
	const id = linkId;
	const styles = baseStyles;
	const [formLinkItem] = Form.useForm();
	const [linkItem, setLinkItem] = useState<LinkItem>(initialLinkItemValue);
	const [imageUrl, setImageUrl] = useState<string>('');
	const [originalLink, setOriginalLink] = useState<LinkItem>(initialLinkItemValue);
	const [editorState, setEditorState] = useState<EditorState>(convertHtmlToDraft(''));
	const [professionalTypes, setProfessionalTypes] = useState<ProfessionalType[]>([]);
	const [selectedProfessionalType, setSelectedProfessionalType] = useState<number>(0);

	useEffect(() => {
		setProfessionalTypes([{ id: 0, code: 'All', description: 'Todos', isDeleted: false }, ...professionalTypeSelectors.selectAll(state)]);
		var item = linkItemsSelectors.selectById(state, id) || initialLinkItemValue;
		setLinkItem(item);
		setOriginalLink(item);
		setImageUrl(item.avatar);
		setEditorState(convertHtmlToDraft(item.summary));
		formLinkItem.setFieldsValue({
			title: item.title,
			url: item.url,
			publishDate: dayjs(item.publishDate || new Date()),
			isPublished: item.isPublished,
		});
		setSelectedProfessionalType(item.professionalTypeId);
	}, [state, formLinkItem, id]);

	const setAvatarUrl = (url: string) => {
		setImageUrl(url);
		setLinkItem({ ...linkItem, avatar: url });
		setLinkData({ ...linkItem, avatar: url });
		setChanged(!objectsAreEquals(originalLink, { ...linkItem, avatar: url }));
	};

	const handleEditorChange = (editorState: EditorState) => {
		var item: LinkItem = { ...linkItem, summary: convertDraftToHtml(editorState) };
		setEditorState(editorState);
		setChanged(!objectsAreEquals(originalLink, item));
		setLinkItem(item);
		setLinkData(item);
	};

	const handleValuesChange = (changedValues: any) => {
		var item: LinkItem = linkItem;
		if (changedValues['professionalType'] !== undefined) item = { ...linkItem, professionalTypeId: changedValues['professionalType'] };
		if (changedValues['title'] !== undefined) item = { ...linkItem, title: changedValues['title'] };
		if (changedValues['url'] !== undefined) item = { ...linkItem, url: changedValues['url'] };
		if (changedValues['publishDate'] !== undefined) item = { ...linkItem, publishDate: changedValues['publishDate'].format(exportDateTimeFormat) };
		if (changedValues['isPublished'] !== undefined) item = { ...linkItem, isPublished: changedValues['isPublished'] };
		setChanged(!objectsAreEquals(originalLink, item));
		setLinkItem(item);
		setLinkData(item);
		setTimeout(() => {
			formLinkItem
				.validateFields()
				.then(() => {
					setHasErrors(false);
				})
				.catch(errors => {
					if (errors.errorFields.length > 0) setHasErrors(true);
				});
		});
	};

	return (
		<div>
			<Row gutter={[16, 16]}>
				<Col lg={24} xl={24} xxl={12}>
					<Card size="small" title={t('training.linkTags')} style={{ ...styles.card, width: '95%' }}>
						<Form {...baseStyles.formLayout} form={formLinkItem} name="link-details" onValuesChange={handleValuesChange}>
							<Form.Item label={t('general.professionalType')} rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Select
									value={selectedProfessionalType}
									onChange={(value: number) => {
										setSelectedProfessionalType(value);
									}}
								>
									{professionalTypes.map(p => {
										return (
											<Option key={`options-${p.id}`} value={p.id}>
												{p.description}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
							<Form.Item label={t('training.title')} name="title" rules={[{ required: true }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('training.url')} name="url" rules={[{ required: true }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('training.publishDate')} name="publishDate" rules={[{ required: true, message: 'Debe ingresar una fecha de publicación' }]}>
								<DatePicker format="DD-MM-YYYY" showTime={false} />
							</Form.Item>
							<Form.Item label={t('training.isPublished')} name="isPublished" valuePropName="checked">
								<Switch />
							</Form.Item>
							<Form.Item label={t('training.avatar')} valuePropName="fileList">
								<UploadDragPaste id={id} size="medium" parentType={ParentType.linkItem} url={imageUrl} setUrl={setAvatarUrl} />
							</Form.Item>
						</Form>
					</Card>
				</Col>
				<Col lg={24} xl={24} xxl={12}>
					<Card size="small" title={t('training.linkSummary')} style={styles.card}>
						<Editor
							editorStyle={{ height: 500 }}
							editorState={editorState}
							toolbarClassName="toolbarClassName"
							wrapperClassName="wrapperClassName"
							editorClassName="editorClassName"
							onEditorStateChange={handleEditorChange}
						/>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
export default FormLinkItem;
