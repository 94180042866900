import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { Document } from '../types/documents';

//#region Documents
export const documentAdapter = createEntityAdapter({
	selectId: (item: Document) => item.id,
});

export const documents = createSlice({
	name: 'documentTypes',
	initialState: documentAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		documentAdded: documentAdapter.addOne,
		documentLoading: state => {
			if (!state.loading) state.loading = true;
		},
		documentReceived: (state, action) => {
			if (state.loading) {
				documentAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		documentCreateUpdate: documentAdapter.upsertOne,
	},
});
//#endregion
