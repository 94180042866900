import { useState, ReactNode } from 'react';
import { useStore } from 'react-redux';
import { Table, Button, Space, Tooltip, Typography, Popconfirm, message, Card, Popover } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormProfessionalDocumentEdit, CheckUncheck } from '../../components';
import { professionalDocumentsSelectors, professionalDocumentTypeSelectors } from '../../store/selectors';
import { ProfessionalDocumentType, ProfessionalDocument, Professional, initialProfessionalDocumentValue } from '../../store/types';
import { EditOutlined, DeleteOutlined, PlusOutlined, ZoomInOutlined, DownloadOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { deleteProfessionalDocument, fetchProfessional, fetchProfessionalDocumentsById, urls } from '../../store/api';
import { professionalDocuments as professionalDocumentsReducer } from '../../store/reducers';
import { dateFormat } from '../../assets/formats';
import { isAuthorized } from '../../utils/util-authentication';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';

const { Text } = Typography;
const key = 'saving';

const FormProfessionalDocuments = ({ professionalId }) => {
	const store = useStore();
	const styles = baseStyles;
	const { t } = useTranslation();
	const [professional, setProfessional] = useState<Professional>();
	const [professionalDocumentTypes, setProfessionalDocumentTypes] = useState<ProfessionalDocumentType[]>([]);
	const [professionalDocuments, setProfessionalDocuments] = useState<ProfessionalDocument[]>([]);
	const [professionalDocument, setProfessionalDocument] = useState<ProfessionalDocument>({
		...initialProfessionalDocumentValue,
		professionalId: professionalId,
	});
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [showProperties, setShowProperties] = useState(false);
	const [modalTitle, setModalTitle] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setIsLoading(true);
		fetchProfessional(professionalId).then(item => {
			setProfessional(item);
		});
		setProfessionalDocumentTypes(professionalDocumentTypeSelectors.selectAll(store.getState() as any));
		fetchProfessionalDocumentsById(professionalId).then(items => {
			store.dispatch(professionalDocumentsReducer.actions.professionalDocumentLoading());
			store.dispatch(professionalDocumentsReducer.actions.professionalDocumentReceived(items));
			setProfessionalDocuments(
				items
					.filter(pd => !pd.isDeleted && (pd.isPrivate === isAuthorized('Access_Reserved_Data') || !pd.isPrivate))
					.sort((a, b) => dayjs(b.documentDate).diff(dayjs(a.documentDate))),
			);
			setIsLoading(false);
		});
	}, [professionalId, store]);

	const handleEditDocument = (item: ProfessionalDocument) => {
		setModalTitle(t('professionals.editDocument'));
		setProfessionalDocument(item);
		setIsModalVisible(true);
	};

	const handleAddDocument = () => {
		setModalTitle(t('professionals.newDocument'));
		setProfessionalDocument({
			...initialProfessionalDocumentValue,
			professionalId: professionalId,
		});
		setIsModalVisible(true);
	};

	const handleDeleteDocument = async (item: ProfessionalDocument) => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		var response = await deleteProfessionalDocument(item);
		if (response) {
			setIsLoading(true);
			fetchProfessionalDocumentsById(professionalId).then(items => {
				store.dispatch(professionalDocumentsReducer.actions.professionalDocumentLoading());
				store.dispatch(professionalDocumentsReducer.actions.professionalDocumentReceived(items));
				setProfessionalDocuments(
					items
						.filter(pd => !pd.isDeleted && (pd.isPrivate === isAuthorized('Access_Reserved_Data') || !pd.isPrivate))
						.sort((a, b) => dayjs(b.documentDate).diff(dayjs(a.documentDate))),
				);
				setIsLoading(false);
				message.success({ content: t('messages.itemDeleted'), key, duration: 2 });
			});
		} else message.error({ content: t('message.unableToDelete'), key, duration: 2 });
	};

	const handleClose = () => {
		setIsModalVisible(false);
		setProfessionalDocuments(
			professionalDocumentsSelectors
				.selectAll(store.getState() as any)
				.filter(pd => !pd.isDeleted && pd.professionalId === professionalId && (pd.isPrivate === isAuthorized('Access_Reserved_Data') || !pd.isPrivate)),
		);
		setProfessionalDocument(undefined);
	};

	const columns: ColumnProps<ProfessionalDocument>[] = [
		{
			dataIndex: 'modifiedBy',
			key: 'modifiedBy',
			width: 20,
			render: (_text: string, item: ProfessionalDocument): ReactNode => (
				<Popover
					content={
						<div>
							<Text>{t('general.modifiedBy')} </Text>
							<Text type="warning">{item.modifiedBy}</Text>
						</div>
					}
				>
					<InfoCircleTwoTone />
				</Popover>
			),
		},
		{
			title: t('professionals.documentType'),
			dataIndex: 'documentType',
			key: 'documentType',
			render: (_text: string, item: ProfessionalDocument): ReactNode => (
				<Text>{professionalDocumentTypes?.find(d => d.id === item.documentTypeId)?.description}</Text>
			),
		},
		{
			title: t('professionals.comments'),
			dataIndex: 'details',
			key: 'details',
			render: (_text: string, item: ProfessionalDocument): ReactNode => <Text>{item.details}</Text>,
		},
		{
			title: t('professionals.documentDate'),
			dataIndex: 'documentDate',
			key: 'documentDate',
			render: (_text: string, item: ProfessionalDocument): ReactNode => <Text>{dayjs(item.documentDate).format(dateFormat)}</Text>,
		},
		{
			title: t('professionals.documentUploadDate'),
			dataIndex: 'documentUploadDateTime',
			key: 'documentUploadDateTime',
			render: (_text: string, item: ProfessionalDocument): ReactNode => <Text>{dayjs(item.documentUploadDateTime).format(dateFormat)}</Text>,
		},
		{
			title: t('professionals.isPrivate'),
			dataIndex: 'isPrivate',
			key: 'isPrivate',
			render: (_text: string, item: ProfessionalDocument): ReactNode => <CheckUncheck isChecked={item.isPrivate} />,
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: ProfessionalDocument): ReactNode => {
				return !isAuthorized('Component_Documents') ? null : (
					<Space size="small">
						<Tooltip title={t('general.edit')}>
							<Button
								style={styles.button}
								type="primary"
								size="small"
								shape="round"
								onClick={() => {
									setShowProperties(true);
									handleEditDocument(item);
								}}
							>
								<EditOutlined />
							</Button>
						</Tooltip>
						<Tooltip title={t('general.view')}>
							<Button
								style={styles.button}
								type="primary"
								size="small"
								shape="round"
								onClick={() => {
									setShowProperties(false);
									handleEditDocument(item);
								}}
							>
								<ZoomInOutlined />
							</Button>
						</Tooltip>
						<Tooltip title={t('general.download')}>
							<Button
								style={styles.button}
								type="primary"
								size="small"
								shape="round"
								onClick={() => {
									FileSaver.saveAs(
										`${urls.getDocument}?uri=${item.url}`,
										`${professional.socialId} - ${professionalDocumentTypes.find(d => d.id === item.documentTypeId).description}.pdf`,
									);
								}}
							>
								<DownloadOutlined />
							</Button>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeleteDocument(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button size="small" danger shape="round" icon={<DeleteOutlined />} />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<Card
			size="small"
			title={t('professionals.documents')}
			style={styles.card}
			extra={[
				!isAuthorized('Component_Documents') ? null : (
					<Button
						type="primary"
						shape="round"
						icon={<PlusOutlined />}
						style={styles.buttonCard}
						onClick={() => {
							setShowProperties(true);
							handleAddDocument();
						}}
					></Button>
				),
			]}
		>
			<Table<ProfessionalDocument>
				key={`professionalDocuments`}
				rowKey={item => item.id}
				tableLayout="auto"
				size="small"
				dataSource={professionalDocuments}
				columns={columns}
				pagination={false}
				loading={isLoading}
			/>
			{professionalDocument ? (
				<FormProfessionalDocumentEdit
					title={modalTitle}
					open={isModalVisible}
					showProperties={showProperties}
					documentId={professionalDocument.id}
					professionalId={professionalId}
					onClose={handleClose}
				/>
			) : null}
		</Card>
	);
};
export default FormProfessionalDocuments;
