import {
	countriesAdapter,
	regionsAdapter,
	provincesAdapter,
	communesAdapter,
	genderAdapter,
	phoneNumberTypeAdapter,
	emailTypeAdapter,
	addressTypeAdapter,
	accreditationDocumentTypeAdapter,
	relationTypeAdapter,
	professionalTypeAdapter,
	specialtyAdapter,
	categoryTypeAdapter,
	eventTypeAdapter,
	centerTypeAdapter,
	infrastructureTypeAdapter,
	equipmentStatusAdapter,
	equipmentTypeAdapter,
	privilegeTypeAdapter,
	privilegeStatusAdapter,
	tagAdapter,
	specialtyTypeAdapter,
	accreditationDocumentStatusAdapter,
	activityTypesAdapter,
	caseTypesAdapter,
	institutionAdapter,
	prioritiesAdapter,
	professionalDocumentTypeAdapter,
	taskStatusesAdapter,
	taskTypesAdapter,
	professionalMilestoneTypeAdapter,
} from '../reducers';
import { StoreStateType } from '../mainStore';

export const countriesSelectors = countriesAdapter.getSelectors((state: StoreStateType) => state.countries);
export const regionsSelectors = regionsAdapter.getSelectors((state: StoreStateType) => state.regions);
export const provincesSelectors = provincesAdapter.getSelectors((state: StoreStateType) => state.provinces);
export const communesSelectors = communesAdapter.getSelectors((state: StoreStateType) => state.communes);
export const gendersSelectors = genderAdapter.getSelectors((state: StoreStateType) => state.genders);
export const phoneNumberTypesSelectors = phoneNumberTypeAdapter.getSelectors((state: StoreStateType) => state.phoneNumberTypes);
export const emailTypesSelectors = emailTypeAdapter.getSelectors((state: StoreStateType) => state.emailTypes);
export const addressTypesSelectors = addressTypeAdapter.getSelectors((state: StoreStateType) => state.addressTypes);
export const categoryTypeSelector = categoryTypeAdapter.getSelectors((state: StoreStateType) => state.categoryTypes);
export const accreditationDocumentTypeSelectors = accreditationDocumentTypeAdapter.getSelectors((state: StoreStateType) => state.accreditationDocumentTypes);
export const accreditationStatusesSelectors = accreditationDocumentTypeAdapter.getSelectors((state: StoreStateType) => state.accreditationStatuses);
export const professionalDocumentTypeSelectors = professionalDocumentTypeAdapter.getSelectors((state: StoreStateType) => state.professionalDocumentTypes);
export const professionalMilestoneTypeSelectors = professionalMilestoneTypeAdapter.getSelectors((state: StoreStateType) => state.professionalMilestoneTypes);
export const accreditationDocumentStatusSelectors = accreditationDocumentStatusAdapter.getSelectors(
	(state: StoreStateType) => state.accreditationDocumentStatuses,
);
export const relationTypesSelectors = relationTypeAdapter.getSelectors((state: StoreStateType) => state.relationTypes);
export const professionalTypeSelectors = professionalTypeAdapter.getSelectors((state: StoreStateType) => state.professionalTypes);
export const specialtiesSelectors = specialtyAdapter.getSelectors((state: StoreStateType) => state.specialties);
export const categoryTypesSelectors = categoryTypeAdapter.getSelectors((state: StoreStateType) => state.categoryTypes);
export const eventTypesSelectors = eventTypeAdapter.getSelectors((state: StoreStateType) => state.eventTypes);
export const centerTypesSelectors = centerTypeAdapter.getSelectors((state: StoreStateType) => state.centerTypes);
export const infrastructureTypeSelectors = infrastructureTypeAdapter.getSelectors((state: StoreStateType) => state.infrastructureTypes);
export const equipmentStatusSelectors = equipmentStatusAdapter.getSelectors((state: StoreStateType) => state.equipmentStatuses);
export const equipmentTypesSelectors = equipmentTypeAdapter.getSelectors((state: StoreStateType) => state.equipmentTypes);
export const privilegeTypesSelectors = privilegeTypeAdapter.getSelectors((state: StoreStateType) => state.privilegeTypes);
export const specialtyTypesSelectors = specialtyTypeAdapter.getSelectors((state: StoreStateType) => state.specialtyTypes);
export const privilegeStatusesSelectors = privilegeStatusAdapter.getSelectors((state: StoreStateType) => state.privilegeStatuses);
export const tagsSelectors = tagAdapter.getSelectors((state: StoreStateType) => state.tags);
export const institutionsSelectors = institutionAdapter.getSelectors((state: StoreStateType) => state.institutions);
export const taskStatusesSelectors = taskStatusesAdapter.getSelectors((state: StoreStateType) => state.taskStatuses);
export const taskTypesSelectors = taskTypesAdapter.getSelectors((state: StoreStateType) => state.taskTypes);
export const activityTypesSelectors = activityTypesAdapter.getSelectors((state: StoreStateType) => state.activityTypes);
export const caseTypesSelectors = caseTypesAdapter.getSelectors((state: StoreStateType) => state.caseTypes);
export const prioritiesSelectors = prioritiesAdapter.getSelectors((state: StoreStateType) => state.priorities);
