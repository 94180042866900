import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import {
	Professional,
	Privilege,
	AccreditationDocument,
	Specialty,
	Accomplishment,
	Accreditation,
	ProfessionalDocument,
	Category,
	AccreditationComment,
	ProfessionalMilestone,
} from '../types/professional';
import { Email, Phone, Address } from '../types/general';

//#region Emails
export const emailAdapter = createEntityAdapter({
	selectId: (item: Email) => item.id,
	sortComparer: (a, b) => a.url.localeCompare(b.url),
});

export const emails = createSlice({
	name: 'emails',
	initialState: emailAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		emailsLoading: state => {
			if (!state.loading) state.loading = true;
		},
		emailsReceived: (state, action) => {
			if (state.loading) {
				emailAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		emailCreateUpdate: emailAdapter.upsertOne,
	},
});

//#endregion

//#region Phones
export const phoneAdapter = createEntityAdapter({
	selectId: (item: Phone) => item.id,
	sortComparer: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
});

export const phones = createSlice({
	name: 'phones',
	initialState: phoneAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		phonesLoading: state => {
			if (!state.loading) state.loading = true;
		},
		phonesReceived: (state, action) => {
			if (state.loading) {
				phoneAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		phoneCreateUpdate: phoneAdapter.upsertOne,
	},
});
//#endregion

//#region Addresses
export const addressAdapter = createEntityAdapter({
	selectId: (item: Address) => item.id,
	sortComparer: (a, b) => a.street.localeCompare(b.street),
});

export const addresses = createSlice({
	name: 'addresses',
	initialState: addressAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		addressAdded: addressAdapter.addOne,
		addressesLoading: state => {
			if (!state.loading) state.loading = true;
		},
		addressesReceived: (state, action) => {
			if (state.loading) {
				addressAdapter.addMany(state, action.payload);
				state.loading = false;
			}
		},
		addressCreateUpdate: addressAdapter.upsertOne,
	},
});
//#endregion

//#region Accreditations
export const accreditationAdapter = createEntityAdapter({
	selectId: (item: Accreditation) => item.id,
});

export const accreditations = createSlice({
	name: 'accreditations',
	initialState: accreditationAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		accreditationAdded: accreditationAdapter.addOne,
		accreditationsLoading: state => {
			if (!state.loading) state.loading = true;
		},
		accreditationsReceived: (state, action) => {
			if (state.loading) {
				accreditationAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		accreditationCreateUpdate: accreditationAdapter.upsertOne,
	},
});
//#endregion

//#region Professional Milestone
export const professionalMilestoneAdapter = createEntityAdapter({
	selectId: (item: ProfessionalMilestone) => item.id,
});

export const professionalMilestones = createSlice({
	name: 'professionalMilestones',
	initialState: professionalMilestoneAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		professionalMilestoneAdded: professionalMilestoneAdapter.addOne,
		professionalMilestonesLoading: state => {
			if (!state.loading) state.loading = true;
		},
		professionalMilestonesReceived: (state, action) => {
			if (state.loading) {
				professionalMilestoneAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		professionalMilestoneCreateUpdate: professionalMilestoneAdapter.upsertOne,
	},
});
//#endregion

//#region Accreditation Comments
export const accreditationCommentAdapter = createEntityAdapter({
	selectId: (item: AccreditationComment) => item.id,
});

export const accreditationComments = createSlice({
	name: 'accreditationComments',
	initialState: accreditationCommentAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		accreditationCommentAdded: accreditationCommentAdapter.addOne,
		accreditationCommentsLoading: state => {
			if (!state.loading) state.loading = true;
		},
		accreditationCommentsReceived: (state, action) => {
			if (state.loading) {
				accreditationCommentAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		accreditationCommentCreateUpdate: accreditationCommentAdapter.upsertOne,
	},
});
//#endregion

//#region Specialties
export const specialtyAdapter = createEntityAdapter({
	selectId: (item: Specialty) => item.id,
});

export const specialties = createSlice({
	name: 'specialties',
	initialState: specialtyAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		specialtyAdded: specialtyAdapter.addOne,
		specialtyLoading: state => {
			if (!state.loading) state.loading = true;
		},
		specialtyReceived: (state, action) => {
			if (state.loading) {
				specialtyAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		specialtyCreateUpdate: specialtyAdapter.upsertOne,
	},
});
//#endregion

//#region categories
export const categoryAdapter = createEntityAdapter({
	selectId: (item: Category) => item.id,
});

export const categories = createSlice({
	name: 'categories',
	initialState: categoryAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		categoryAdded: categoryAdapter.addOne,
		categoryLoading: state => {
			if (!state.loading) state.loading = true;
		},
		categoryReceived: (state, action) => {
			if (state.loading) {
				categoryAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		categoryCreateUpdate: categoryAdapter.upsertOne,
	},
});
//#endregion

//#region Accomplishments
export const accomplishmentAdapter = createEntityAdapter({
	selectId: (item: Accomplishment) => item.id,
});

export const accomplishments = createSlice({
	name: 'accomplishments',
	initialState: accomplishmentAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		accomplishmentAdded: accomplishmentAdapter.addOne,
		accomplishmentLoading: state => {
			if (!state.loading) state.loading = true;
		},
		accomplishmentReceived: (state, action) => {
			if (state.loading) {
				accomplishmentAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		accomplishmentCreateUpdate: accomplishmentAdapter.upsertOne,
	},
});
//#endregion

//#region Accreditation Documents
export const accreditationDocumentAdapter = createEntityAdapter({
	selectId: (item: AccreditationDocument) => item.id,
});

export const accreditationDocuments = createSlice({
	name: 'accreditationDocuments',
	initialState: accreditationDocumentAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		accreditationDocumentAdded: accreditationDocumentAdapter.addOne,
		accreditationsDocumentLoading: state => {
			if (!state.loading) state.loading = true;
		},
		accreditationsDocumentReceived: (state, action) => {
			if (state.loading) {
				accreditationDocumentAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		accreditationDocumentCreateUpdate: accreditationDocumentAdapter.upsertOne,
	},
});
//#endregion

//#region Professional Documents
export const professionalDocumentAdapter = createEntityAdapter({
	selectId: (item: ProfessionalDocument) => item.id,
});

export const professionalDocuments = createSlice({
	name: 'professionalDocuments',
	initialState: professionalDocumentAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		professionalDocumentAdded: professionalDocumentAdapter.addOne,
		professionalDocumentLoading: state => {
			if (!state.loading) state.loading = true;
		},
		professionalDocumentReceived: (state, action) => {
			if (state.loading) {
				professionalDocumentAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		professionalDocumentCreateUpdate: professionalDocumentAdapter.upsertOne,
	},
});
//#endregion

//#region Privileges
export const privilegeAdapter = createEntityAdapter({
	selectId: (item: Privilege) => item.id,
});

export const privileges = createSlice({
	name: 'privileges',
	initialState: privilegeAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		privilegeAdded: privilegeAdapter.addOne,
		privilegeLoading: state => {
			if (!state.loading) state.loading = true;
		},
		privilegeReceived: (state, action) => {
			if (state.loading) {
				privilegeAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		privilegeCreateUpdate: privilegeAdapter.upsertOne,
	},
});
//#endregion

//#region Professionals
export const professionalsAdapter = createEntityAdapter({
	selectId: (item: Professional) => item.id,
	sortComparer: (a, b) => a.lastName.localeCompare(b.lastName),
});

export const professionals = createSlice({
	name: 'professionals',
	initialState: professionalsAdapter.getInitialState({
		loading: false,
	}),
	reducers: {
		professionalsAdded: professionalsAdapter.addOne,
		professionalsLoading: state => {
			if (!state.loading) state.loading = true;
		},
		professionalsReceived: (state, action) => {
			if (state.loading) {
				professionalsAdapter.setAll(state, action.payload);
				state.loading = false;
			}
		},
		professionalsCreateUpdate: professionalsAdapter.upsertOne,
	},
});
//#endregion
