import { useEffect, useState } from 'react';
import { Input, Form, Select, message, Alert } from 'antd';
import { useStore } from 'react-redux';
import { phoneNumberTypesSelectors, phonesSelectors } from '../../store/selectors';
import { PhoneNumberType, Phone, initialPhoneValue } from '../../store/types';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { createUpdatePhone } from '../../store/api';
import { phones as phonesReducer } from '../../store/reducers';
import { isValidPhone } from '../../utils/util-custom-validators';
import SizedModal from '../main/modal-sized';

const { Option } = Select;

const key = 'saving';

const FormProfessionalPhoneEdit = ({ phoneId, professionalId, open, title, onClose }) => {
	const store = useStore();
	const [formProfessionalPhoneEdit] = Form.useForm();
	const { t } = useTranslation();
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [phoneNumberTypes, setPhoneNumberTypes] = useState<PhoneNumberType[]>([]);
	const [isValidData, setIsValidData] = useState<boolean>(true);

	useEffect(() => {
		setPhoneNumberTypes(phoneNumberTypesSelectors.selectAll(store.getState() as any).filter(t => !t.isDeleted));
		var item = phonesSelectors.selectById(store.getState() as any, phoneId) || initialPhoneValue;
		setIsVisible(open);
		formProfessionalPhoneEdit.setFieldsValue({
			phoneNumber: item.phoneNumber,
			phoneNumberType: item.phoneNumberTypeId,
		});
	}, [formProfessionalPhoneEdit, open, phoneId, store]);

	const handleCancel = () => {
		setIsValidData(true);
		onClose();
	};

	const handleValuesChange = (changedValues: any) => {
		if (changedValues['phoneNumber']) {
			if (isValidPhone(changedValues['phoneNumber'])) setIsValidData(true);
			else setIsValidData(false);
		}
	};

	const handleOk = async () => {
		if (!isValidData) return;
		try {
			var values = await formProfessionalPhoneEdit.validateFields();
			var temp: Phone = {
				id: phoneId,
				professionalId: professionalId,
				personId: 0,
				phoneNumber: values['phoneNumber'],
				phoneNumberTypeId: values['phoneNumberType'] || 0,
				isDeleted: false,
			};
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			var result = await createUpdatePhone(temp);
			if (result !== undefined) {
				store.dispatch(phonesReducer.actions.phoneCreateUpdate(result));
				message.success({ content: t('messages.dataSaved'), key, duration: 2 });
			} else message.info({ content: t('message.errorOnSave'), key, duration: 2 });
			onClose();
		} catch (errors) {
			setIsValidData(false);
		}
	};

	return (
		<SizedModal size="small" title={title} open={isVisible} onOk={handleOk} onCancel={handleCancel}>
			<Form {...baseStyles.formLayout} form={formProfessionalPhoneEdit} name="phone-details" onValuesChange={handleValuesChange}>
				<Form.Item label={t('professionals.phoneNumberType')} name="phoneNumberType" rules={[{ required: true, message: t('messages.enterType') }]}>
					<Select>
						{phoneNumberTypes.map(e => {
							return (
								<Option key={`phone-${e.id}`} value={e.id}>
									{e.description}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item label={t('professionals.phoneNumber')} name="phoneNumber" rules={[{ required: true, message: t('messages.enterValue') }]}>
					<Input />
				</Form.Item>
			</Form>
			{!isValidData ? <Alert message={t('messages.correctErrorsToContinue')} type="error" /> : null}
		</SizedModal>
	);
};
export default FormProfessionalPhoneEdit;
