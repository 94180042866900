import { useState, ReactNode, useEffect } from 'react';
import { AuthRoutes, CheckUncheck } from '../../components';
import { Row, Col, Space, Button, Table, Tag, message, Typography, Popconfirm, AutoComplete, Tooltip, Avatar, Layout, Breadcrumb, FloatButton } from 'antd';
import { EditOutlined, DeleteOutlined, CopyOutlined, FilterFilled, PlusOutlined, BookTwoTone } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { Document, ProfessionalType } from '../../store/types';
import { useStore } from 'react-redux';
import { documents as documentsReducer } from '../../store/reducers';
import { documentsSelectors } from '../../store/selectors/documents';
import { Link, useNavigate } from 'react-router-dom';
import { deleteDocument } from '../../store/api';
import { baseStyles, colors } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { professionalTypeSelectors } from '../../store/selectors';
import { includes } from '../../utils/util-custom-validators';
import dayjs from 'dayjs';
import { dateFormat } from '../../assets/formats';

const { Text } = Typography;
const { Content } = Layout;

const PageDocumentIndex = () => {
	const navigate = useNavigate();
	const store = useStore();
	const state = store.getState() as any;
	const styles = baseStyles;
	const { t } = useTranslation();
	const [documents, setDocuments] = useState<Document[]>([]);
	const [professionalTypes, setProfessionalTypes] = useState<ProfessionalType[]>([]);
	const [isloading, setIsLoading] = useState(true);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		var items = documentsSelectors
			.selectAll(state)
			.filter(d => !d.isDeleted)
			.sort((a, b) => dayjs(a.publishDate).diff(dayjs(b.publishDate)))
			.reverse();
		setDocuments(items);
		setProfessionalTypes(professionalTypeSelectors.selectAll(state));
		setIsLoading(false);
	}, [state, documents.length]);

	const handleSelect = (_value, option) => {
		navigate(AuthRoutes.documents + `/details`, { state: { id: option.value } });
	};

	const handleSearch = (value: string) => {
		if (value) {
			var searchResult = documentsSelectors.selectAll(state).filter(d => (includes(d.title, value) || includes(d.summary, value)) && !d.isDeleted);
			var searchVisualization = searchResult.map(n => {
				return {
					value: n.id,
					label: (
						<div>
							<Row>
								<Col flex={1}>
									<Text strong>{n.title}</Text>
								</Col>
								<Col flex={1}>
									<Row justify="end">
										<Text>
											({t('news.newsDate')} : {dayjs(n.newsItemDate).format('DD-MM-YYYY')}, {t('news.publishDate')} :{' '}
											{dayjs(n.publishDate).format('DD-MM-YYYY')})
										</Text>
									</Row>
								</Col>
							</Row>
						</div>
					),
				};
			});
			setOptions(searchVisualization);
		} else setOptions([]);
	};

	const handleDeleteDocument = async (record: Document) => {
		var response = await deleteDocument(record);
		if (response) {
			store.dispatch(documentsReducer.actions.documentCreateUpdate({ ...record, isDeleted: true }));
			setDocuments((documentsSelectors.selectAll(state) as Document[]).filter(ni => !ni.isDeleted));
		} else message.error(t('message.unableToDelete'));
	};

	const columns: ColumnProps<Document>[] = [
		{
			width: 35,
			render: (_text: string, item: Document) => {
				if (item.avatar !== '') return <Avatar size="large" src={`${item.avatar}?${Date.now()}`} />;
				else return <Avatar size="large" icon={<CopyOutlined />} />;
			},
		},
		{
			title: t('documents.details'),
			render: (_text: string, item: Document): ReactNode => (
				<Space direction="vertical">
					<Text strong>{item.title}</Text>
					<Text>{`${t('documents.author')}: ${item.author}`}</Text>
				</Space>
			),
		},
		{
			title: t('documents.documentDate'),
			dataIndex: 'documentDate',
			key: 'documentDate',
			width: 130,
			render: (_text: string, item: Document): ReactNode => <Text>{dayjs(item.documentDate).format(dateFormat)}</Text>,
		},
		{
			title: t('documents.publishDate'),
			dataIndex: 'publishDate',
			key: 'publishDate',
			width: 130,
			render: (_text: string, item: Document): ReactNode => <Text>{dayjs(item.publishDate).format(dateFormat)}</Text>,
		},
		{
			title: t('documents.isPublished'),
			dataIndex: 'isPublished',
			key: 'isPublished',
			width: 100,
			ellipsis: {
				showTitle: false,
			},
			render: (_text: string, item: Document): ReactNode => <CheckUncheck isChecked={item.isPublished} />,
		},
		{
			title: t('general.professionalType'),
			dataIndex: 'professionalTypeId',
			key: 'professionalTypeId',
			width: 150,
			filterIcon: <FilterFilled style={styles.filter}></FilterFilled>,
			filters: professionalTypes.map(t => {
				return {
					text: t.description,
					value: t.id,
				};
			}),
			onFilter: (value, item) => item.professionalTypeId === value,
			render: (_text: string, item: Document): ReactNode => (
				<Tag style={styles.professionalType}>
					{item.professionalTypeId ? professionalTypes.find(p => p.id === item.professionalTypeId)?.description.toUpperCase() : t('general.all').toUpperCase()}
				</Tag>
			),
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: Document): ReactNode => {
				return (
					<Space size="small">
						<Link to={AuthRoutes.documents + `/details`} state={{ id: item.id }}>
							<Tooltip title={t('general.edit')}>
								<Button type="primary" shape="round" icon={<EditOutlined />} />
							</Tooltip>
						</Link>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeleteDocument(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<div>
			<Col>
				<Row>
					<Col>
						<Breadcrumb
							separator=">"
							style={{ height: 31, margin: '16px 24px 0 0' }}
							items={[{ title: <BookTwoTone twoToneColor={colors.brandColor1} /> }, { title: t('general.content') }, { title: t('general.documents') }]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Link to={AuthRoutes.documents + `/details`} state={{ id: 0 }}>
							<Button type="primary" icon={<PlusOutlined />} shape="round">
								{t('general.add')}
							</Button>
						</Link>
					</Col>
				</Row>
				<Content className="site-layout-background">
					<Row>
						<Col flex={3}>
							<Space direction="horizontal">
								<Text strong>{t('general.searchEngine')}</Text>
								<AutoComplete
									popupMatchSelectWidth={baseStyles.popupMatchSelectWidth}
									style={styles.search}
									placeholder={t('general.search')}
									allowClear
									options={options}
									onSelect={handleSelect}
									onSearch={handleSearch}
								/>
							</Space>
						</Col>
						<Col flex={4}></Col>
						<Col flex={3}></Col>
					</Row>
					<Row style={{ paddingTop: 10 }}>
						<FloatButton.BackTop key="back-top" />
						<Table<Document>
							rowKey={item => item.id}
							tableLayout="auto"
							size="small"
							loading={isloading}
							key={`documents-table`}
							dataSource={documents}
							columns={columns}
							pagination={false}
							style={{ width: '98vw' }}
						/>
					</Row>
				</Content>
			</Col>
		</div>
	);
};
export default PageDocumentIndex;
