import { Col, Row, Layout, Card, Typography } from 'antd';
import { LogoBig, ProgressBarLoadingData, RequiredAuth } from '../../components';
import { fetchData } from '../../store/mainStore';
import { fetchDomains } from '../../store/mainStore';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { colors } from '../../assets/styles';
import background from '../../assets/images/background-redsalud-santiago.jpg';

const { Content } = Layout;

const { Text } = Typography;

/**
 * Page to show loading status
 */
const PageLoading = () => {
	const { t } = useTranslation();
	document.title = globalThis.systemName;

	useEffect(() => {
		fetchDomains();
		fetchData();
	}, []);

	return (
		<RequiredAuth>
			<Layout style={{ backgroundImage: `url(${background})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
				<Content>
					<Col>
						<Row style={{ height: '20vh' }}></Row>
						<Row style={{ height: '30vh' }}>
							<Col flex={1}></Col>
							<Col>
								<Card
									title={t('messages.startPage') + ' (' + globalThis.version + ' - ' + process.env.REACT_APP_ENVIRONMENT + ' - ' + globalThis.language + ')'}
									style={{ width: 640, height: '100%' }}
									styles={{ header: { backgroundColor: colors.brandColor1, color: colors.backgroundColor } }}
								>
									<Row style={{ height: 20 }} />
									<Row align="middle" justify="center">
										<Col flex="auto">
											<Row style={{ alignItems: 'center', justifyContent: 'center' }}>
												<LogoBig />
											</Row>
										</Col>
									</Row>
									<Row style={{ height: 80 }} />
									<Row style={{ height: 20 }} />
									<Row style={{ width: '100%' }} justify="center">
										<ProgressBarLoadingData />
									</Row>
									<Row style={{ height: 20 }} />
									<Row style={{ height: 20 }}>
										<Text style={{ textAlign: 'center', width: '100vh' }}>{`RedSalud ©2024 ${t(
											'general.createdBy',
										)} Dr. Francisco Fukuda - Dirección de Informática Médica`}</Text>
									</Row>
								</Card>
							</Col>
							<Col flex={1}></Col>
						</Row>
						<Row style={{ height: '50vh' }}></Row>
					</Col>
				</Content>
			</Layout>
		</RequiredAuth>
	);
};

export default PageLoading;
