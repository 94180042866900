import { useEffect, useState } from 'react';
import { Row, Col, Space, Card, Form, Input, Typography } from 'antd';
import {
	Professional,
	initialProfessionalValue,
	ProfessionalType,
	RelationType,
	Country,
	Gender,
	AccreditationDocument,
	AccreditationDocumentStatus,
	AccreditationDocumentType,
} from '../../store/types';
import {
	FormProfessionalPrivileges,
	FormProfessionalAccreditations,
	FormProfessionalCategories,
	FormProfessionalAccomplishments,
	FormProfessionalSpecialties,
	FormProfessionalCentersData,
	DocumentViewer,
} from '..';
import {
	accreditationDocumentsSelectors,
	accreditationDocumentTypeSelectors,
	countriesSelectors,
	gendersSelectors,
	professionalTypeSelectors,
	relationTypesSelectors,
} from '../../store/selectors';
import { accreditationDocumentStatusSelectors } from '../../store/selectors/domains';
import { useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { baseStyles } from '../../assets/styles';
import { dateFormat } from '../../assets/formats';
import locale from 'antd/es/date-picker/locale/es_ES';
import dayjs from 'dayjs';
import DatePicker from '../main/datepicker';
import FormProfessionalAccreditationComments from './form-professional-accreditation-comments';
import { fetchProfessional } from '../../store/api';

const { Text } = Typography;

const FormProfessionalSummary = ({ professionalId, professionalType }) => {
	const [formProfessionalSummary] = Form.useForm();
	const store = useStore();
	const state = store.getState() as any;
	const styles = baseStyles;
	const { t } = useTranslation();
	const [genders, setGenders] = useState<Gender[]>([]);
	const [countries, setCountries] = useState<Country[]>([]);
	const [professionalTypes, setProfessionalTypes] = useState<ProfessionalType[]>([]);
	const [professional, setProfessional] = useState<Professional>(initialProfessionalValue);
	const [relationTypes, setRelationTypes] = useState<RelationType[]>([]);
	const [accreditationDocumentTypes, setAccreditationDocumentTypes] = useState<AccreditationDocumentType[]>([]);
	const [accreditationDocumentStatuses, setAccreditationDocumentStatuses] = useState<AccreditationDocumentStatus[]>([]);
	const [accreditationDocuments, setAccreditationDocuments] = useState<AccreditationDocument[]>([]);
	const [span, setSpan] = useState<number>(24);

	window.onresize = () => {
		window.innerWidth > 1600 ? setSpan(12) : setSpan(24);
	};

	useEffect(() => {
		window.innerWidth > 1600 ? setSpan(12) : setSpan(24);
		setGenders(gendersSelectors.selectAll(state));
		setCountries(countriesSelectors.selectAll(state));
		setProfessionalTypes(professionalTypeSelectors.selectAll(state));
		fetchProfessional(professionalId).then(item => {
			setProfessional(item || initialProfessionalValue);
		});
		setRelationTypes(relationTypesSelectors.selectAll(state));
		setAccreditationDocumentTypes(accreditationDocumentTypeSelectors.selectAll(state));
		setAccreditationDocumentStatuses(accreditationDocumentStatusSelectors.selectAll(state));
		setAccreditationDocuments(accreditationDocumentsSelectors.selectAll(state).filter(ad => !ad.isDeleted && ad.professionalId === professionalId));
	}, [professionalId, state]);

	return (
		<Row gutter={[16, 16]}>
			<Col span={span}>
				<Card size="small" title={t('professionals.details')} style={styles.card}>
					<Form {...baseStyles.formLayout} form={formProfessionalSummary} name="professional-details">
						<Form.Item label={t('general.names')}>
							<Input value={professional.names} disabled />
						</Form.Item>
						<Form.Item label={t('general.lastname')}>
							<Input value={professional.lastName} disabled />
						</Form.Item>
						<Form.Item label={t('general.secondLastname')}>
							<Input value={professional.secondLastName} disabled />
						</Form.Item>
						<Form.Item label={t('general.birthdate')}>
							<Row gutter={8}>
								<Col>
									<Form.Item>
										<DatePicker locale={locale} format={dateFormat} value={dayjs(professional.birthDate)} disabled />
									</Form.Item>
								</Col>
								<Col>
									<Space>
										<Text>{t('general.age') + ':'}</Text>
										<Input disabled value={dayjs(new Date()).diff(professional.birthDate, 'year') + ' ' + t('general.years')} />
									</Space>
								</Col>
							</Row>
						</Form.Item>
						<Form.Item label={t('general.gender')}>
							<Input value={genders.find(g => g.id === professional.genderId)?.description} disabled />
						</Form.Item>
						<Form.Item label={t('general.socialId')}>
							<Input value={professional.socialId} disabled />
						</Form.Item>
						<Form.Item label={t('professionals.countryOfNationality')}>
							<Input value={countries.find(c => c.id === professional.nationalityId)?.description} disabled />
						</Form.Item>
					</Form>
				</Card>
			</Col>
			<Col span={span}>
				<Card size="small" title={t('professionals.accreditationDetails')} style={styles.card}>
					<Form {...baseStyles.formLayout} form={formProfessionalSummary}>
						<Form.Item label={t('professionals.professionalType')}>
							<Input value={professionalTypes.find(p => p.id === professional.professionalTypeId)?.description} disabled />
						</Form.Item>
						<Form.Item label={t('professionals.idNumber')}>
							<Input value={professional.sisNumber} disabled />
						</Form.Item>
						<Form.Item label={t('professionals.eunacomResult')}>
							<Input value={professional.eunacomResult} disabled />
						</Form.Item>
						<Form.Item label={t('professionals.exclusiveHours')}>
							<Input value={professional.hours} disabled />
						</Form.Item>
						<Form.Item label={t('professionals.relationType')}>
							<Input value={relationTypes.find(r => r.id === professional.relationTypeId)?.description} disabled />
						</Form.Item>
					</Form>
				</Card>
			</Col>
			<Col span={span}>
				<FormProfessionalAccomplishments key={`accomplishments`} professionalId={professionalId} disabled />
			</Col>
			<Col span={span}>
				<FormProfessionalSpecialties key={`specialties`} professionalId={professionalId} professionalType={professionalType} />
			</Col>
			<Col span={span}>
				<FormProfessionalAccreditations key={`accreditations`} professionalId={professionalId} professionalType={professionalType} disabled />
			</Col>
			<Col span={span}>
				<FormProfessionalAccreditationComments key={`accreditationComments`} professionalId={professionalId} disabled />
			</Col>
			<Col span={span}>
				<FormProfessionalCategories key={`categories`} professionalId={professionalId} professionalType={professionalType} disabled />
			</Col>
			<Col span={span}>
				<FormProfessionalPrivileges key={`privileges`} professionalId={professionalId} professionalType={professionalType} disabled />
			</Col>
			<Col span={span}>
				<FormProfessionalCentersData key={`centers`} professionalId={professionalId} />
			</Col>
			{accreditationDocuments.map((item, index) => {
				return (
					<Col span={span}>
						<Card
							key={`card-${index}`}
							size="small"
							title={accreditationDocumentTypes.find(ad => ad.id === item.documentTypeId).description}
							style={styles.card}
							type="inner"
						>
							<Col>
								<Row justify="center" gutter={20}>
									<Space direction="horizontal">
										<Text strong>{`${t('professionals.documentDate')} : ${dayjs(item.documentDate).format(dateFormat)}`}</Text>
										<Text strong>{`${t('general.status')} : ${accreditationDocumentStatuses.find(ad => ad.id === item.documentStatusId).description}`}</Text>
									</Space>
								</Row>
								<DocumentViewer key={`viewer-${index}`} url={item.url} size="large" />
							</Col>
						</Card>
					</Col>
				);
			})}
		</Row>
	);
};

export default FormProfessionalSummary;
