import dayjs from "dayjs";

export interface Document {
  [key: string]: any;
  id: number;
  professionalTypeId: number;
  title: string;
  author: string;
  avatar: string;
  documentDate: string;
  publishDate: string;
  summary: string;
  isPublished: boolean;
  tags: string[];
  isDeleted: boolean;
}

export const initialDocumentValue: Document = {
  id: 0,
  professionalTypeId: 0,
  title: "",
  avatar: "",
  author: "",
  documentDate: dayjs().format('YYYY/MM/DD'),
  publishDate: dayjs().format('YYYY/MM/DD'),
  summary: "",
  isPublished: false,
  tags: [],
  isDeleted: false,
};
