import { useEffect, useState } from 'react';
import { Avatar, Breadcrumb, Button, Card, Col, Layout, Row, Space, Tag, Typography } from 'antd';
import { useStore } from 'react-redux';
import { newsItemsSelectors } from '../../store/selectors';
import { useTranslation } from 'react-i18next';
import { News } from '../../store/types';
import { ArrowLeftOutlined, MedicineBoxOutlined, UserOutlined } from '@ant-design/icons';
import { baseStyles, colors } from '../../assets/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Markup } from 'interweave';
import { AuthRoutes } from '../../components';
import dayjs from 'dayjs';

const { Title } = Typography;
const { Content } = Layout;

const PageViewNewsItem = () => {
	const { t } = useTranslation();
	const store = useStore();
	const state = store.getState() as any;
	const params = useLocation().state as any;
	const styles = baseStyles;
	const navigate = useNavigate();
	const [newsItem, setNewsItem] = useState<News>();
	useEffect(() => {
		setNewsItem(newsItemsSelectors.selectAll(state).find(ni => ni.id === params.id));
	}, [params, state]);

	return (
		<div>
			<Col>
				<Row>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Breadcrumb
							separator=">"
							items={[
								{ title: <MedicineBoxOutlined twoToneColor={colors.brandColor1} /> },
								{
									title: t('view.viewContent'),
								},
								{ title: <Link to={{ pathname: AuthRoutes.viewNews }}>{t('view.news')}</Link> },
								{ title: newsItem?.title },
							]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Button
							type="primary"
							style={{ backgroundColor: colors.brandColor1 }}
							shape="round"
							icon={<ArrowLeftOutlined />}
							onClick={() => {
								navigate(AuthRoutes.viewNews);
							}}
						>
							{t('general.back')}
						</Button>
					</Col>
				</Row>
				<Content className="site-layout-background">
					<Row style={{ paddingTop: 20 }}>
						<Card key={`card-news`} id={`card-news`} style={{ backgroundColor: 'white' }}>
							<Space direction="vertical" style={{ justifyContent: 'center' }}>
								<Row justify="center">
									{newsItem?.avatar !== '' ? (
										<Avatar key={`avatar-pic`} size={400} src={`${newsItem?.avatar}?${Date.now()}`} />
									) : (
										<Avatar key={`avatar-icon`} size={400} icon={<UserOutlined />} />
									)}
								</Row>
								<Row justify="center">
									<Title level={2}>{`${newsItem?.title}`}</Title>
								</Row>
								<Row justify="center">
									<Space direction="vertical">
										<Title level={2} style={{ textAlign: 'center', color: 'black' }}>{`${t('news.author')} : ${newsItem?.author}`}</Title>
										<Title level={3} style={{ textAlign: 'center', color: 'black' }}>{`${t('news.newsDate')} : ${dayjs(newsItem?.newsItemDate).format(
											'DD-MM-YYYY',
										)}`}</Title>
									</Space>
								</Row>
								<Row justify="center">
									<Col>
										{newsItem?.tags.map(t => (
											<Tag style={styles.tag} key={`${t}`}>
												{t.toUpperCase()}
											</Tag>
										))}
									</Col>
								</Row>
								<Row justify="center">
									<Col>
										<Markup key={`markup`} content={newsItem?.details}></Markup>
									</Col>
								</Row>
							</Space>
						</Card>
					</Row>
				</Content>
			</Col>
		</div>
	);
};

export default PageViewNewsItem;
