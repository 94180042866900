export interface Phone {
	[key: string]: any;
	id: number;
	phoneNumber: string;
	phoneNumberTypeId: number;
	professionalId: number;
	personId: number;
	isDeleted: boolean;
}

export const initialPhoneValue: Phone = {
	id: 0,
	phoneNumber: '',
	phoneNumberTypeId: 1,
	professionalId: 0,
	personId: 0,
	isDeleted: false,
};

export interface Email {
	[key: string]: any;
	id: number;
	url: string;
	emailTypeId: number;
	professionalId: number;
	personId: number;
	isDeleted: boolean;
}

export const initialEmailValue: Email = {
	id: 0,
	url: '',
	emailTypeId: 1,
	professionalId: 0,
	personId: 0,
	isDeleted: true,
};

export interface Address {
	[key: string]: any;
	id: number;
	street: string;
	number: string;
	other?: string;
	latitude: number;
	longitude: number;
	communeId: number;
	addressTypeId: number;
	countryId: number;
	ownerId?: number;
	personId: number;
	isDeleted: boolean;
}

export const initialAddressValue: Address = {
	id: 0,
	street: '',
	number: '',
	other: '',
	latitude: -33.416889,
	longitude: -70.606705,
	communeId: 1,
	addressTypeId: 1,
	countryId: 0,
	ownerId: 0,
	personId: 0,
	isDeleted: false,
};
