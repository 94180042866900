import { createContext, useContext, useState } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { User } from '../../store/types';

/**
 * Authorized routes alternatives
 */
export enum AuthRoutes {
	home = '/home',
	view = '/view',
	viewDetails = '/view/details',
	viewProfessionals = '/view/professionals',
	viewNews = '/view/news',
	viewNewsItem = '/view/newsItem',
	viewDocuments = '/view/documents',
	viewLinks = '/view/links',
	viewStatistics = '/view/statistics',
	management = '/management',
	activities = '/management/activities',
	cases = '/management/cases',
	persons = '/management/persons',
	tasks = '/management/tasks',
	loading = '/loading',
	professionals = '/professionals',
	physicians = '/professionals/physicians',
	nurses = '/professionals/nurses',
	kinesiologists = '/professionals/kinesiologists',
	psychologists = '/professionals/psychologists',
	dentists = '/professionals/dentists',
	nutritionists = '/professionals/nutritionists',
	medicalTechnologists = '/professionals/medicalTechnologists',
	centers = '/centers',
	services = '/services',
	news = '/news',
	documents = '/documents',
	configuration = '/configuration',
	events = '/events',
	links = '/links',
}

/**
 * Non authorized routes alternatives
 */
export enum NonAuthRoutes {
	root = '/',
	login = '/login',
	registration = '/registration',
	unauthorized = '/unauthorized',
	testing = '/testing',
	downloadApp = '/downloadApp',
	physiciansDirectory = '/medicos',
	dentistsDirectory = '/dentistas',
	professionalsPhysicians = '/professionalsPhysicians',
	professionalsDentists = '/professionalsDentists',
	documentsDirectory = '/documentos',
}

export interface AuthContextType {
	isAuthenticated: boolean;
	accessToken: string;
	user: User;
	signin: (user: User, accessToken: string, callback: VoidFunction) => void;
	signout: (callback: VoidFunction) => void;
}

const AuthContext = createContext<AuthContextType>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const [user, setUser] = useState<User>(null);
	const [accessToken, setAccessToken] = useState<string>('');
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

	const signin = (user: User, accessToken: string, callback: VoidFunction) => {
		setAccessToken(accessToken);
		globalThis.token = accessToken;
		globalThis.user = user;
		globalThis.isAuthenticated = true;
		setIsAuthenticated(true);
		setUser(user);
		callback();
	};

	const signout = (callback: VoidFunction) => {
		setUser(null);
		setIsAuthenticated(false);
		setAccessToken('');
		globalThis.token = '';
		globalThis.user = null;
		globalThis.isAuthenticated = false;
		callback();
	};

	const value = { isAuthenticated, accessToken, user, signin, signout };

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	return useContext(AuthContext);
};

export const AuthStatus = () => {
	const auth = useAuth();
	const navigate = useNavigate();

	if (!auth.user) {
		return <p>You are not logged in.</p>;
	}

	return (
		<p>
			Welcome
			<button
				onClick={() => {
					auth.signout(() => navigate('/'));
				}}
			>
				Sign out
			</button>
		</p>
	);
};

export const RequiredAuth = ({ children }: { children: JSX.Element }) => {
	const auth = useAuth();
	const location = useLocation();
	if (!auth.user) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}
	return children;
};
