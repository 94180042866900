export const pantone320c = 'rgb(0,156,166)';
export const pantone375c = 'rgb(167,194,75)';
export const pantoneCoolGray9C = 'rgb(117,116,116)';

export const colorsLightRedSalud = {
    brandColor1: pantone320c,
    brandColor2: pantone375c,
    brandColor3: pantoneCoolGray9C,
    backgroundColor: 'white',
    foregroundColor: 'black',
    blue: 'rgb(0,132,155)',
    green: 'rgb(48,209,88)',
    red: 'rgb(255,69,58)',
    defaultGray: 'rgb(142,142,147)',
    gray1: 'rgb(211,211,211)',
    gray2: 'rgb(192,192,192)',
    gray3: 'rgb(169,169,169)',
    gray4: 'rgb(160,160,160)',
    gray5: 'rgb(128,128,128)',
    gray6: 'rgb(105,105,105)',
}

export const colorsDarkRedSalud = {
    brandColor1: pantone320c,
    brandColor2: pantone375c,
    brandColor3: pantoneCoolGray9C,
    backgroundColor: 'black',
    foregroundColor: 'white',
    blue: 'rgb(0,132,155)',
    green: 'rgb(48,209,88)',
    red: 'rgb(255,69,58)',
    defaultGray: 'rgb(28,28,30)',
    gray1: 'rgb(211,211,211)',
    gray2: 'rgb(192,192,192)',
    gray3: 'rgb(169,169,169)',
    gray4: 'rgb(160,160,160)',
    gray5: 'rgb(128,128,128)',
    gray6: 'rgb(105,105,105)',
}
